import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/models/helpcenter/Article';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../../../../services/general.service';

declare let $: any;
@Component({
  selector: 'app-topic-details',
  templateUrl: './topic-details.component.html',
  styles: []
})
export class TopicDetailsComponent implements OnInit {

  currentRate = 2;

  packageId: string;
  Id: number;

  isloaded = false;

  article: Article = new Article();

  env = environment;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: GeneralService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.Id = Number(params.get('id'));
      this.getArticleDetails(this.Id);
    });
    this.GetFreePackageId();
    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus')) {
      $('body').addClass('contactUs-page');

    } else {
      $('body').removeClass('contactUs-page');

    }
  }

  GetFreePackageId() {
    this.service.GetFreePackageId().subscribe(
      data => {
        this.packageId = data as string;
      }, err => {
        console.error(err);
      });
  }

  getArticleDetails(id) {
    this.isloaded = true;
    this.service.getArticleDetails(id).subscribe(
      data => {
        this.isloaded = false;
        data.content = data.content.replace('[pakageUrl]', this.env.systemUrl + '/register?packageCode=' + this.packageId);
        this.article = data as Article;

      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

}
