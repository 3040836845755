<div class="popup-container">
  <div *ngIf='dataLoading' class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf='!dataLoading'>
    <div mat-dialog-title class="mat-modal__head">
        <div class="modal-header">
            <button type="button" class="close" mat-dialog-close aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <mat-dialog-content class="mat-modal__content">
        <div class="modal-body">
          <div class="body-content">
            <div class="image">
              <img [src]='data.record.logoPath'>
            </div>
            <div class="content">
              <h1>{{data.record.name | translate}}</h1>
              <p>{{data.record.description | translate}}</p>
            </div>
          </div>

        </div>
    </mat-dialog-content>
  </ng-container>

</div>
