import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {  } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TimeSlots } from 'src/app/models/DemoRequest/TimeSlots';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { MeetingInfo } from 'src/app/models/DemoRequest/MeetingInfo';
import { LookupsService } from 'src/app/services/lookups.service';
import { BookademoService } from 'src/app/services/bookademo.service';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
declare let $: any;
@Component({
  selector: 'app-book-demo',
  templateUrl: './book-demo.component.html',
  styles: [
  ]
})
export class BookDemoComponent implements OnInit {

  exampleHeader = ExampleHeader;

  selected: Date | null;
  activeIndex = -1;
  showConfirmForm = false;

  times: TimeSlots[] = new Array();
  isSlotsloaded = false;

  timeZones: Lookup[] = new Array();
  isZoneloaded = false;

  timeZoneValue = null;
  isRequired = false;

  showscheduled = false;

  meetingInfo: MeetingInfo = new MeetingInfo();

  holidayList: any[] = new Array();
  holidayLoading = false;

  holidayDateFilter = (date: Date): boolean => {
    // const day = date.getDay();
    // if(day == 5 || day == 6){
    //   /* Prevent friday and Saturday for select. */
    //   return day !== 5 && day !== 6;
    // }
    let d = moment(date);
    if (this.holidayList) {
      return !this.holidayList.find((x) => {
        return moment(x).isSame(d, 'day');
      })
    }
  }

  constructor(
    private router: Router,
    private translate: TranslateService,
    private lookupsService: LookupsService,
    private bookademoService: BookademoService,
    private service: GeneralService) { }

  ngOnInit(): void {
    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus')) {
      $('body').addClass('contactUs-page');

    } else {
      $('body').removeClass('contactUs-page');
    }
    this.GetTimeZones();
    this.GetHolidaysDaysDates();

  }

  GetTimeZones() {
    this.isZoneloaded = true;
    this.lookupsService.GetTimeZones().subscribe(
      data => {
        this.isZoneloaded = false;
        this.timeZones = data as Lookup[];
        // this.meetingInfo.gmt = this.timeZones[0].id;
      }, err => {
        this.isZoneloaded = false;
        console.error(err);
      });
  }

  GetHolidaysDaysDates() {
    this.holidayLoading = true;
    this.bookademoService.GetHolidaysDaysDates().subscribe(
      data => {
        this.holidayLoading = false;
        this.holidayList = data as any[];
      }, err => {
        this.holidayLoading = false;
        console.error(err);
      });
  }

  GetTimeSlots(date) {
    this.meetingInfo.date = date;
    this.isSlotsloaded = true;
    this.bookademoService.GetTimeSlots(date.toLocaleString()).subscribe(
      data => {
        this.isSlotsloaded = false;
        this.times = data.weekDayTimes;
        if(data.meetingDuration != null){
          this.bookademoService.meetingDuration.next(data.meetingDuration);
        }else{
          this.bookademoService.meetingDuration.next(null);
        }
      }, err => {
        this.isSlotsloaded = false;
        console.error(err);
      });
  }

  selectedChange(event) {
    this.GetTimeSlots(event.toLocaleString());
  }

  emitMeetingInfo(time){
    if(this.meetingInfo.gmt == null){
      this.isRequired = true;
      return;
    }
    this.showConfirmForm = !this.showConfirmForm;
    this.meetingInfo.weekDayTimeId = time.weekDayTimeId;
    this.meetingInfo.time = time.time;
    this.bookademoService.meetingInfo.next(this.meetingInfo);
  }

  backTohome(){
    this.showscheduled = false;
    this.showConfirmForm = false;
    this.selected = null;
    this.activeIndex = -1;
  }

}


/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  template: `
    <div class="example-header">
      <div class="custom-icons"  (click)="previousClicked('month')">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
          <path id="prev-arrow" d="M606.434,253.04l-6.406-5.6a.346.346,0,0,1-.022-.534l.022-.019,6.406-5.6a.477.477,0,0,1,.611-.019l.021.019.687.6a.346.346,0,0,1,.021.535l-.021.018-5.4,4.725,5.4,4.726a.346.346,0,0,1,.02.535l-.02.018-.687.6a.478.478,0,0,1-.612.017l-.02-.017Z" transform="translate(-599.891 -241.16)" fill="#7d4393"/>
        </svg>
      </div>
      <span class="example-header-label">{{periodLabel}}</span>
      <div class="custom-icons" (click)="nextClicked('month')">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
          <path id="next-arrow" d="M601.348,253.04l6.406-5.6a.346.346,0,0,0,.022-.534l-.022-.019-6.406-5.6a.477.477,0,0,0-.611-.019l-.021.019-.687.6a.346.346,0,0,0-.021.535l.021.018,5.4,4.725-5.4,4.726a.346.346,0,0,0-.02.535l.02.018.687.6a.478.478,0,0,0,.612.017l.02-.017Z" transform="translate(-599.891 -241.16)" fill="#7d4393"/>
        </svg>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}

