import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  record: any;
}

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styles: []
})
export class TestimonialsComponent implements OnInit {

  dataLoading = false;

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<TestimonialsComponent>,) { }

  ngOnInit() {
  }

}
