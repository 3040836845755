import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactUs } from '../../models/contactUs/ContactUs';
import { ContactUsService } from '../../services/contact-us.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Details } from '../../models/contactUs/details';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { GeneralService } from 'src/app/services/general.service';
declare let $: any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styles: []
})
export class ContactUsComponent implements OnInit {

  @ViewChild('form') form;

  loading = false;
  formGroup: FormGroup;
  ContactUsObject: ContactUs = new ContactUs();

  isloaded = false;
  inquiryPurpose: [] = [];

  detailsLoaded = false;
  details: Details = new Details();

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private service: ContactUsService,
              private translate: TranslateService,
              private generalService: GeneralService

              ) { }

  ngOnInit() {
    this.getContactUs();
    this.GetInquiryPurpose();
    this.generalService.isOpened.next(false);
    if (this.router.url.includes('contactus') ) {
      $("body").addClass("contactUs-page");

    }else{
      $("body").removeClass("contactUs-page");
    }
    this.buildForm();
  }


  getContactUs(){
    this.detailsLoaded = true;
    this.service.getContactUs('CONTACTUS').subscribe(
      data => {
        this.detailsLoaded = false;
        this.details = data[0] as Details;
      }, err => {
        console.error(err);
        this.detailsLoaded = false;
      })
  }

  buildForm(){
    this.formGroup = this.formBuilder.group({
      fullName: new FormControl('', [
        CustomValidator.noWhiteSpace
      ]),
      email: new FormControl('', [
        CustomValidator.noWhiteSpace,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      ]),
      msg: new FormControl('', [
        CustomValidator.noWhiteSpace
      ]),
      inquiryPurpose: new FormControl(null, [
        Validators.required
      ]),
    });
  }
  GetInquiryPurpose() {
    this.isloaded = true;
    this.service.GetInquiryPurpose().subscribe(
      data => {
        this.isloaded = false;
        this.inquiryPurpose = data as [];
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

  save() {
    if (this.formGroup.valid) {
      this.loading = true;
      this.ContactUsObject.fullName = this.formGroup.controls.fullName.value;
      this.ContactUsObject.email = this.formGroup.controls.email.value;
      this.ContactUsObject.inquiryPurposeId = this.formGroup.controls.inquiryPurpose.value;
      this.ContactUsObject.message = this.formGroup.controls.msg.value;
      this.service.save(this.ContactUsObject).subscribe(
        data => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('general.success'),
            text: this.translate.instant('general.thanksSendsuccessfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('general.ok')
          }).then(() => {
            this.form.resetForm();
          });
        }, err => {
          this.loading = false;
          console.error(err);
          Swal.fire({
            title: this.translate.instant('general.Error'),
            text: this.translate.instant('general.tryAgain'),
            icon: 'error',
            confirmButtonText:  this.translate.instant('general.ok')
          });
        });
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

}
