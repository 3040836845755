import { CompareResult } from './CompareResult';

export class ComparePackages {
  result: CompareResult = new CompareResult();
  id: number;
  exception: null;
  status: number;
  isCanceled: boolean;
  isCompleted: boolean;
  isCompletedSuccessfully: boolean;
  creationOptions: number;
  asyncState: null;
  isFaulted: boolean;
}
