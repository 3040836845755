// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiUrl: 'https://connectsiteapi.expertapps.com.sa/api', // TEST API front
  // systemUrl: 'https://connecttest.expertapps.com.sa',

  //////////// https://connectsitereg.expertapps.com.sa // reg API front
  apiUrl: 'https://connectsiteregapi.expertapps.com.sa/api',
  systemUrl: 'https://connectreg.expertapps.com.sa',

  googleApiKey: 'AIzaSyBdScej8zNyAjz4YQuoWeo0jTfYv56pFc4',
  googleSiteKey: '6Lcw-vkeAAAAAJ9a5aMnhFuaO6GqHGsgaJn18xWJ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
