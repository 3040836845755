import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-admin-sidbar',
  templateUrl: './admin-sidbar.component.html',
  styles: [
  ]
})
export class AdminSidbarComponent implements OnInit {

  @Input() toggleSide;
  @Output() sidebarTrigger = new EventEmitter();

  activeMenu: string;
  language = localStorage.getItem('language') || 'en';
  currentUser: User = {
    name: '',
  } as User;

  isArabic = false;

  constructor( private router: Router,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,) {

      this.currentUser = this.authenticationService.currentUserValue;
      console.log(this.currentUser );
      translate.addLangs(['en', 'ar']);
      translate.setDefaultLang('en');
      translate.use(this.language);

      if (this.language === 'en') { this.isArabic = false; }
  }

  ngOnInit() {
  }

  isActiveMenu(item) {
    return location.href.includes(item);
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }


  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
