import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { User } from '../models/user/user';
import { Config } from '../models/config';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public authUser: any;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  login(username, password, rememberMe) {
    return this.http.post<any>(`${Config.loginUrl}`, { username: username, password: password })
      .pipe(map(user => {
        user.rememberMe = rememberMe;
        user.access_token = user.Token;
        this.currentUserSubject.next(user);
        this.authUser = user;
        this.getUserProfile();
        return user;
      }));
  }

  getUserProfile() {
    const user = this.currentUserValue;
    this.http.get(`${Config.bookdemoURL}/Account/GetUserProfile`).pipe(first())
      .subscribe((data: any) => {
        user.email = data.email;
        user.username = data.userName;
        user.name = data.FullName;
        user.userImage = data.userImage;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      },
        error => {
        });
  }

  forgetPassword(UserName) {
    return this.http.get(`${Config.bookdemoURL}/Authenticate/ForgetPassword?UserName=` + UserName);
  }

  checkResetPasswordLink(userId, code) {
    return this.http.post(`${Config.bookdemoURL}/accounts/VerifyToken`, { userId, token: code });
}

  resetPassword(newPassword, userId, code) {
    return this.http.post(`${Config.bookdemoURL}/Authenticate/ResetPassword`, { newPassword: newPassword, userID: userId, token: code });
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('menu');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }


}
