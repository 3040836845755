import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MeetingsService } from 'src/app/services/meetings.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { BookademoService } from 'src/app/services/bookademo.service';
import { MeetingInfo } from 'src/app/models/DemoRequest/MeetingInfo';
import { TimeSlots } from 'src/app/models/DemoRequest/TimeSlots';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { LookupsService } from 'src/app/services/lookups.service';
export interface DialogData {
  id: number;
}


@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styles: [
  ]
})
export class RescheduleComponent implements OnInit {

  exampleHeader = ExampleHeader;

  holidayLoading = false;
  holidayList: any[] = new Array();
  holidayDateFilter = (date: Date): boolean => {
    // const day = date.getDay();
    // if(day == 5 || day == 6){
    //   /* Prevent friday and Saturday for select. */
    //   return day !== 5 && day !== 6;
    // }
    let d = moment(date);
    if (this.holidayList) {
      return !this.holidayList.find((x) => {
        return moment(x).isSame(d, 'day');
      })
    }
  };

  selected: Date | null;
  isSlotsloaded = false;
  times: TimeSlots[] = new Array();

  activeIndex = -1;

  isZoneloaded = false;
  timeZones: Lookup[] = new Array();

  gmt: any = null;

  isRequired = false;

  rescheduleObj = {
    demoRequestId: null,
    meetingDate: null,
    WeekDayTimeId: null
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<RescheduleComponent>,
    private translate: TranslateService,
    private meetingsService: MeetingsService,
    private bookademoService: BookademoService,
    private lookupsService: LookupsService
  ) { }

  ngOnInit(): void {
    // this.RescheduleDemoRequest(this.data.id);
    if (this.data && this.data.id != null) {
      this.rescheduleObj.demoRequestId = this.data.id;
    }
    this.GetTimeZones();
    this.GetHolidaysDaysDates();
  }


  GetTimeZones() {
    this.isZoneloaded = true;
    this.lookupsService.GetTimeZones().subscribe(
      data => {
        this.isZoneloaded = false;
        this.timeZones = data as Lookup[];
      }, err => {
        this.isZoneloaded = false;
        console.error(err);
      });
  }


  GetHolidaysDaysDates() {
    this.holidayLoading = true;
    this.bookademoService.GetHolidaysDaysDates().subscribe(
      data => {
        this.holidayLoading = false;
        this.holidayList = data as any[];
      }, err => {
        this.holidayLoading = false;
        console.error(err);
      });
  }

  GetTimeSlots(date) {
    this.rescheduleObj.meetingDate = new Date(`${date} UTC`) ;
    this.isSlotsloaded = true;
    this.bookademoService.GetTimeSlots(date.toLocaleString()).subscribe(
      data => {
        this.isSlotsloaded = false;
        this.times = data.weekDayTimes;
      }, err => {
        this.isSlotsloaded = false;
        console.error(err);
      });
  }

  selectedChange(event) {
    this.GetTimeSlots(event.toLocaleString());
  }

  emitMeetingInfo(time) {
    // if (this.gmt == null) {
    //   this.isRequired = true;
    //   return;
    // }
    this.rescheduleObj.WeekDayTimeId = time.weekDayTimeId;
    this.RescheduleDemoRequest(this.rescheduleObj);
  }

  RescheduleDemoRequest(rescheduleObj) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.no')
    }).then((result) => {
      if (result.value) {
        this.meetingsService.RescheduleDemoRequest(rescheduleObj).subscribe(
          data => {
            if (data) {
              Swal.fire({
                title: this.translate.instant('swalMsg.success'),
                text: '',
                icon: 'success',
                confirmButtonText: this.translate.instant('swalMsg.ok')
              }).then(() => {
                this.dialogRef.close(true);
              });
            }
          },
          error => {
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text: error.title,
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok'),
            });
          });
      }
    });
  }

}


/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  template: `
    <div class="example-header">
      <div class="custom-icons"  (click)="previousClicked('month')">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
          <path id="prev-arrow" d="M606.434,253.04l-6.406-5.6a.346.346,0,0,1-.022-.534l.022-.019,6.406-5.6a.477.477,0,0,1,.611-.019l.021.019.687.6a.346.346,0,0,1,.021.535l-.021.018-5.4,4.725,5.4,4.726a.346.346,0,0,1,.02.535l-.02.018-.687.6a.478.478,0,0,1-.612.017l-.02-.017Z" transform="translate(-599.891 -241.16)" fill="#7d4393"/>
        </svg>
      </div>
      <span class="example-header-label">{{periodLabel}}</span>
      <div class="custom-icons" (click)="nextClicked('month')">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
          <path id="next-arrow" d="M601.348,253.04l6.406-5.6a.346.346,0,0,0,.022-.534l-.022-.019-6.406-5.6a.477.477,0,0,0-.611-.019l-.021.019-.687.6a.346.346,0,0,0-.021.535l.021.018,5.4,4.725-5.4,4.726a.346.346,0,0,0-.02.535l.02.018.687.6a.478.478,0,0,0,.612.017l.02-.017Z" transform="translate(-599.891 -241.16)" fill="#7d4393"/>
        </svg>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
