import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

declare let $: any;
@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html'
})
export class PricingPageComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private service: GeneralService,
    private activeRoute: ActivatedRoute
  ) { }

  ngAfterViewInit(): void {

    // this.activeRoute.params.subscribe(param => {
    //   if (param.pageSec) {
    //     setTimeout(() => {
    //       const elementPosition = document.getElementById("section2").offsetTop;
    //       const offsetPosition = elementPosition;
    //       console.log(offsetPosition);
    //       window.scrollTo({
    //         top: offsetPosition,
    //         behavior: 'smooth',
    //       });
    //     }, 1000);
    //   }
    // })

  }

  ngOnInit() {


    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus')) {
      $("body").addClass("contactUs-page");

    } else {
      $("body").removeClass("contactUs-page");

    }
  }
}
