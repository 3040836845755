import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MeetingsService } from 'src/app/services/meetings.service';

import Swal from 'sweetalert2';
import { RescheduleComponent } from './reschedule/reschedule.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-meeting-details',
  templateUrl: './meeting-details.component.html',
  styles: [
  ]
})
export class MeetingDetailsComponent implements OnInit {

  @Output() closeDetails = new EventEmitter<boolean>();
  @Input() detailsdemoRequestId: any = null;

  emails = [];
  dataLoading = false;
  details: any;



  constructor(  public translate: TranslateService,
    private meetingsService: MeetingsService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    if(this.detailsdemoRequestId){
      this.getDetails();
    }
  }

  getDetails(){
    this.dataLoading = true;
    this.meetingsService.GetDemoRequestDetailsById(this.detailsdemoRequestId).subscribe(
      result => {
       this.dataLoading = false;
       this.details = result;
       if(this.details && this.details.guestEmails){
           this.emails = this.details.guestEmails.split(",").map(e => e.trim());
       }
       if(this.details && this.details.meetingDate){
        var temp = new Date(this.details.meetingDate);
        temp.setDate(temp.getDate()-1);
        this.details.changedDate = temp;
       }

      }, err => {
        this.dataLoading = false;
        console.error(err);
      });
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }


  ConfirmDemoRequest(id){
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.no')
    }).then((result) => {
      if (result.value) {
       this.meetingsService.ConfirmDemoRequest(id).subscribe(
         data => {
           if (data) {
             Swal.fire({
               title: this.translate.instant('swalMsg.success'),
               text: '',
               icon: 'success',
               confirmButtonText: this.translate.instant('swalMsg.ok')
             }).then(() => {
               this.getDetails();
             });
           }
         },
         error => {
           Swal.fire({
             title: this.translate.instant('swalMsg.Failed'),
             text: error.title,
             icon: 'error',
             confirmButtonText: this.translate.instant('swalMsg.ok'),
           });
       });
      }
    });
  }

  DeclineDemoRequest(id){
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.no')
    }).then((result) => {
      if (result.value) {
       this.meetingsService.DeclineDemoRequest(id).subscribe(
         data => {
           if (data) {
             Swal.fire({
               title: this.translate.instant('swalMsg.success'),
               text: '',
               icon: 'success',
               confirmButtonText: this.translate.instant('swalMsg.ok')
             }).then(() => {
               this.getDetails();
             });
           }
         },
         error => {
           Swal.fire({
             title: this.translate.instant('swalMsg.Failed'),
             text: error.title,
             icon: 'error',
             confirmButtonText: this.translate.instant('swalMsg.ok'),
           });
       });
      }
    });
  }

  ChangeDecision(id){
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.no')
    }).then((result) => {
      if (result.value) {
       this.meetingsService.ChangeDecision(id).subscribe(
         data => {
           if (data) {
             Swal.fire({
               title: this.translate.instant('swalMsg.success'),
               text: '',
               icon: 'success',
               confirmButtonText: this.translate.instant('swalMsg.ok')
             }).then(() => {
               this.getDetails();
             });
           }
         },
         error => {
           Swal.fire({
             title: this.translate.instant('swalMsg.Failed'),
             text: error.title,
             icon: 'error',
             confirmButtonText: this.translate.instant('swalMsg.ok'),
           });
       });
      }
    });
  }



  openDialog(id) {

    const dialogRef = this.dialog.open(RescheduleComponent,{
      width: '600px',
      data: {
        id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
      }
    });
  }

}
