import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  packageId = new BehaviorSubject<number>(null);
  isOpened = new BehaviorSubject<boolean>(false);
  getRoute = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  getListTopics(): any {
    return this.http.get(`${environment.apiUrl}/HelpCenter/ListTopics`);
  }

  getClient(): any {
    return this.http.get(`${environment.apiUrl}/Client/Get`);
  }

  getTrustedBy(): any {
    return this.http.get(`${environment.apiUrl}/TrustedBy/List`);
  }

  getPackages(filterobj): any{
    return this.http.post(`${environment.apiUrl}/package/List`, filterobj);
  }

  GetFreePackageId(): any{
    return this.http.get(`${environment.apiUrl}/package/GetFreePackageId`, { responseType: 'text' });
  }

  getFeatures(): any{
    return this.http.get(`${environment.apiUrl}/Feature/List`);
  }

  getDetails(id): any{
    return this.http.get(`${environment.apiUrl}/Feature/Details/${id}`);
  }

  GetMenu(): any{
    return this.http.get(`${environment.apiUrl}/Lookup/GetMenu`);
  }

  GetSocialMedia(): any{
    return this.http.get(`${environment.apiUrl}/SocialMedia/Get`);
  }


  getListArticles(id): any{
    return this.http.get(`${environment.apiUrl}/HelpCenter/ListArticles/${id}`);
  }

  getArticleDetails(id): any{
    return this.http.get(`${environment.apiUrl}/HelpCenter/ArticleDetails/${id}`);
  }

  getCategoriesWithFeatures(): any{
    return this.http.get(`${environment.apiUrl}/Lookup/GetCategoriesWithFeatures`);
  }

  getComparePackages(filterobj): any{
    return this.http.post(`${environment.apiUrl}/package/ComparePackages`, filterobj);
  }
}
