import { Component, OnInit } from '@angular/core';
import { MeetingInfo } from 'src/app/models/DemoRequest/MeetingInfo';
import { BookademoService } from 'src/app/services/bookademo.service';
@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styles: [
  ]
})
export class ScheduledComponent implements OnInit {


  meetingInfo: MeetingInfo = new MeetingInfo();

  meetingDuration: any = null;

  constructor( private bookademoService: BookademoService) { }

  ngOnInit(): void {
    this.bookademoService.meetingInfo.subscribe(data => {
      this.meetingInfo = data;
    });

    this.bookademoService.meetingDuration.subscribe(data => {
      this.meetingDuration = data;
    });
  }

}
