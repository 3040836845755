<div class="page-content contactus-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12" >
          <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='detailsLoaded'>
              <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div>
          <ng-container *ngIf="!detailsLoaded">
            <div  [innerHTML]="details.content | sanitizeHtml" *ngIf="details && details.content">
            </div>
          </ng-container>

        </div>
        <div class="col-lg-6 col-12">
          <div class="contact-form-container">
            <div class="contact-form">
              <div class="form">
                <form [formGroup]="formGroup" (ngSubmit)='save()' #form="ngForm">
                  <mat-form-field appearance="outline" class="form-group">
                      <mat-label>{{ 'contactus.Fullname' | translate }}</mat-label>
                      <input matInput formControlName="fullName" maxlength="250" >
                      <mat-error *ngIf="hasError('fullName', 'required')"> {{ 'contactus.Required' | translate }}</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline"  class="form-group">
                    <mat-label> {{ 'contactus.Email' | translate }}</mat-label>
                    <input matInput formControlName="email" placeholder="pat@example.com" maxlength="50">
                    <mat-error *ngIf="hasError('email', 'required')"> {{ 'contactus.Required' | translate }}</mat-error>
                    <mat-error *ngIf="hasError('email', 'pattern') && this.formGroup.controls.email.value.trim()">{{ 'contactus.InvalidEmail' | translate }}</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="form-group"  [class.loading-field]="isloaded">
                    <mat-label>  {{ 'contactus.Purposeyourinquiry' | translate }}</mat-label>
                      <mat-select formControlName="inquiryPurpose" [disabled]="isloaded">
                          <mat-option [value]="item.id" *ngFor="let item of inquiryPurpose">
                              <span>{{item.text}}</span>
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="hasError('inquiryPurpose', 'required')">{{ 'contactus.Required' | translate }}</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>  {{ 'contactus.Typeyourmessagehere' | translate }}</mat-label>
                      <textarea matInput formControlName="msg" maxlength="500"></textarea>
                      <mat-error *ngIf="hasError('msg', 'required')">
                        {{ 'contactus.Required' | translate }}
                      </mat-error>
                  </mat-form-field>

                  <div class="btn-container">
                    <button mat-raised-button
                            [class.loading]='loading'
                            [disabled]='loading'> {{ 'general.Send' | translate }}</button>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
