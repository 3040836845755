<div class="page-content inside-category">
  <div class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-xs-4 d-md-block d-xs-block d-none text-right">
            <div class="image">
              <img src="./assets/images/getting-started-illustration.png">
            </div>
        </div>
        <div class="col-sm-7 col-12 d-flex flex-column justify-content-center" >
            <app-breadcrumb></app-breadcrumb>
            <h1>{{ 'GettingStarted.GettingStarted' | translate }}</h1>
            <p>{{ 'GettingStarted.knowgetstarted' | translate }} </p>
            <div class="search">
              <input type="search" name="search" [placeholder]="'helpcenter.Searchtopics' | translate"
              [(ngModel)]='searchText' (keydown.enter)="getSearch(searchText)" hidden>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="started-list">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
              <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div>
          <ng-container *ngIf="!isloaded">
            <ul class="category-listing" *ngIf="listArticles && listArticles.length > 0">
                <li *ngFor="let article of listArticles">
                  <div>
                    <a  [routerLink]="['/helpcenter/article', this.topicId, article.id]" >
                      <h3>{{article.title}}</h3>
                      <p>{{ article.berif }} </p>
                    </a>
                  </div>

                </li>
            </ul>
          </ng-container>


        </div>
      </div>
    </div>
  </div>


  <app-having-trouble></app-having-trouble>

</div>
