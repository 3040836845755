import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LookupsService } from 'src/app/services/lookups.service';
import { MeetingsService } from 'src/app/services/meetings.service';
import { AddHolidayComponent } from './add-holiday/add-holiday.component';
import { MatDialog } from '@angular/material/dialog';
import { HolidaysService } from 'src/app/services/holidays.service';
import { ListData } from 'src/app/models/demoSetting/holiday/ListData';
import { ListDataFilter } from 'src/app/models/demoSetting/holiday/ListDataFilter';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styles: [
  ]
})
export class HolidaysComponent implements OnInit {
  dataLoading = false;
  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: ListData = new ListData();

  filterModel: ListDataFilter = new ListDataFilter();

  constructor(
    private meetingsService: MeetingsService,
    private translate: TranslateService,
    private service: HolidaysService,
    private LookupsService: LookupsService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.filterModel.pageSize = 10;
    this.getAll(null);
  }


  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
      this.filterModel.pageSize = event.pageSize;
    } else {
      this.filterModel.pageNumber = 0;
      this.filterModel.pageSize = 10;
    }

    this.service.HolidaysPagedList(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }


  open(isEditMood = false, id = null) {
    const dialogRef = this.dialog.open(AddHolidayComponent, {
      width: '500px',
      data: {
        isEditMood,
        id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll(null);
      }
    });
  }

  delete(id){
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
       this.service.delete(id).subscribe(
         data => {
           if (data) {
             Swal.fire({
               title: this.translate.instant('swalMsg.success'),
               text: '',
               icon: 'success',
               confirmButtonText: this.translate.instant('swalMsg.ok')
             }).then(() => {
               this.getAll(null);
             });
           }
         },
         error => {
           console.log(error);
           Swal.fire({
             title: this.translate.instant('swalMsg.Failed'),
             text: (error.errorDTO.length)? error.errorDTO[0].error : '',
             icon: 'error',
             confirmButtonText: this.translate.instant('swalMsg.ok'),
           });
       });
      }
    });
  }

}
