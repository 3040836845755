<div class="page-content terms-container">
  <div class="page-header">
    <h1> {{ 'footer.Privacypolicy' | translate }}</h1>
  </div>
  <div class="privaxy-content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="item">
            <h2>{{ 'Privacy.Privacy Policy of Connect' | translate }}</h2>
            <p>{{ 'Privacy.This Application collects some Personal Data from its Users.' | translate }}</p>
            <p>{{ 'Privacy.This document can be printed for reference by using the print command in the settings of any browser.' | translate }}</p>
        </div>
        <div class="item">
          <h2>{{ 'Privacy.Policy summary' | translate }}</h2>
          <p>{{ 'Privacy.Personal Data collected for the following purposes and using the following services:' | translate }}</p>
          <ul>
            <li>{{ 'Privacy.Analytics' | translate }}</li>
            <li>{{ 'Privacy.Firebase Analytics' | translate }}</li>
            <li>{{ 'Privacy.Personal Data: Tracker; Usage Data' | translate }}</li>
            <li>{{ 'Privacy.Device permissions for Personal Data access' | translate }}</li>
            <li>{{ 'Privacy.Personal Data: Call permission; Phone permission; Storage permission' | translate }}</li>
            <li>{{ 'Privacy.Infrastructure monitoring' | translate }}</li>
            <li>{{ 'Privacy.Flurry [ in Android ]' | translate }}</li>
            <li>{{ 'Privacy.Personal Data: crash data; device information; Universally unique identifier (UUID)' | translate }}</li>
            <li>{{ 'Privacy.Registration and authentication provided directly by this Application' | translate }}</li>
            <li>{{ 'Privacy.Direct registration as Expert' | translate }}</li>
            <li>{{ 'Privacy.Personal Data: email address; full name; mobile number; country of residence; gender' | translate }}</li>
            <li>{{ 'Privacy.Direct registration as An Admin' | translate }}</li>
            <li>{{ 'Privacy.Personal Data: applicant email address; applicant full name; applicant position; contact person name' | translate }}</li>
          </ul>
        </div>

        <!-- <div class="item">
            <h2>1 {{ 'Privacy.collect' | translate }}</h2>
            <p>{{ 'Privacy.weCollect' | translate }}</p>
            <p>{{ 'Privacy.asresult' | translate }}</p>
            <ul>
              <li>
                {{ 'Privacy.statistics' | translate }}
              </li>
              <li>
                {{ 'Privacy.information' | translate }}
              </li>
            </ul>
            <p> {{ 'Privacy.Wedo' | translate }}</p>
          </div>
          <div class="item">
              <h2>{{ 'Privacy.Aggregated' | translate }}</h2>
              <p>
                {{ 'Privacy.WhenYouvisit' | translate }}
              </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
