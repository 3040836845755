import { ContactUsComponent } from './innerPages/contact-us/contact-us.component';
import { HelpCenterComponent } from './innerPages/help-center/help-center.component';
import { PricingPageComponent } from './innerPages/pricing-page/pricing-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { InsideCategoryComponent } from './innerPages/help-center/inside-category/inside-category.component';
import { SearchResultComponent } from './innerPages/search-result/search-result.component';
import { TermandconditionComponent } from './innerPages/termandcondition/termandcondition.component';
import { TopicDetailsComponent } from './innerPages/help-center/inside-category/topic-details/topic-details.component';
import { PrivacyComponent } from './innerPages/privacy/privacy.component';
import { DetailsComponent } from './home-page/features/details/details.component';
import { BookDemoComponent } from './innerPages/book-demo/book-demo.component';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { DemoSettingComponent } from './demo-setting/demo-setting.component';
import { ConfigTimeSlotsComponent } from './demo-setting/config-time-slots/config-time-slots.component';
import { HolidaysComponent } from './demo-setting/holidays/holidays.component';
import { AuthGuard } from './helpers/auth.guard';
import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';

const routes: Routes = [
  {
    path: 'login',
    component: EmptyLayoutComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LoginComponent },
      { path: 'forgetpassword', component: ForgetPasswordComponent, data: { breadcrumb: 'Forget password' } },
      { path: 'resetpassword/:userid/:code', component: ChangePasswordComponent, data: { breadcrumb: 'Reset password' } },
    ]
  },
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      { path: '', component: HomePageComponent },
      { path: 'pricing', component: PricingPageComponent},
      { path: 'messaging',
        children: [
          { path: '', component: DetailsComponent },
          { path: ':id', component: DetailsComponent },
        ]
      },
      { path: 'tasks',
        children: [
          { path: '', component: DetailsComponent },
          { path: ':id', component: DetailsComponent },
        ]
      },
      { path: 'meetings',
        children: [
          { path: '', component: DetailsComponent },
          { path: ':id', component: DetailsComponent },
        ]
      },
      {
        path: 'helpcenter',
        data: { breadcrumb: 'HelpCenter' },
        children: [
          { path: '', component: HelpCenterComponent,   data: { breadcrumb: '' } },
          { path: 'article',
            data: { breadcrumb: '' },
            children: [
              {
                path: ':id',
                data: { breadcrumb: 'Article' },
                children: [
                  { path: '', component: InsideCategoryComponent,  data: { breadcrumb: '' } },
                  { path: ':id', component: TopicDetailsComponent,  data: { breadcrumb: '' } },
                ]
            },
            ]
          },
          // { path: 'messaging', component: InsideCategoryComponent, data: { breadcrumb: 'Messaging' } },
          // { path: 'meetings', component: InsideCategoryComponent, data: { breadcrumb: 'Meetings' } },
          // { path: 'tasks', component: InsideCategoryComponent, data: { breadcrumb: 'Tasks' } },
          // { path: 'pricing', component: InsideCategoryComponent, data: { breadcrumb: 'Pricing' } },

        ]
      },
      { path: 'termnsandcondition', component: TermandconditionComponent },
      { path: 'contactus', component: ContactUsComponent },
      { path: 'bookdemo', component: BookDemoComponent },
      { path: 'privacy', component: PrivacyComponent },
       { path: 'searchresult', component: SearchResultComponent },

    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,

    canActivate: [AuthGuard],
    data: { breadcrumb: 'Home' },
    children:[
      { path: '', component: DemoSettingComponent,  data: { breadcrumb: 'Demo Meetings' } },
      { path: 'meetings', component: DemoSettingComponent,  data: { breadcrumb: 'Demo Meetings' } },
      { path: 'configtimeslots', component: ConfigTimeSlotsComponent,  data: { breadcrumb: 'Configure Time Slots' } },
      { path: 'holidays', component: HolidaysComponent,  data: { breadcrumb: 'Set Holidays' } },
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
