import { Component, OnInit, ViewChild,  Output, EventEmitter} from '@angular/core';
import { Validators, ValidatorFn, AbstractControl, FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LookupsService } from 'src/app/services/lookups.service';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { MeetingInfo } from 'src/app/models/DemoRequest/MeetingInfo';
import { BookademoService } from 'src/app/services/bookademo.service';
import { DemoRequestObj } from 'src/app/models/DemoRequest/DemoRequestObj';
declare let $: any;

@Component({
  selector: 'app-confirm-form',
  templateUrl: './confirm-form.component.html',
  styles: [
  ]
})
export class ConfirmFormComponent implements OnInit {

  @ViewChild('form', { static: false }) form;

  @Output() showscheduled = new EventEmitter<boolean>();
  isShow = false;

  formGroup: FormGroup;
  loading = false;

  isCountriesloaded = false;
  countries: Lookup[] = new Array();
  countryKeys: Lookup[] = new Array();

  isCompanySizesloaded = false;
  companySizes: Lookup[] = new Array();


  validEmail = false;
  invalidLength = false;
  emails = [];

  meetingInfo: MeetingInfo = new MeetingInfo();

  demoRequestObj: DemoRequestObj = new DemoRequestObj();

  selected = null;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private lookupsService: LookupsService,
    private bookademoService: BookademoService
    ) { }


  ngOnInit(): void {
    this.getCountries();
    this.GetCountryKeys();
    this.GeCompanySizes();

    this.buildForm();

    this.bookademoService.meetingInfo.subscribe(data => {
      this.meetingInfo = data;
      if(this.meetingInfo){
        this.demoRequestObj.meetingDate = (this.meetingInfo.date) ? new Date(`${this.meetingInfo.date} UTC`) : null;
        this.demoRequestObj.weekDayTimeId = this.meetingInfo.weekDayTimeId;
        this.demoRequestObj.timeZoneLookupId = this.meetingInfo.gmt.id;
      }
    });
  }


  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as [];
      }, err => {
        console.error(err);
      });
  }

  GetCountryKeys() {
    this.lookupsService.GetCountryKeys().subscribe(
      data => {
        this.countryKeys = data as [];
        this.formGroup.controls.countryKey.setValue(this.countryKeys[0].id);
        this.selected = this.countryKeys[0];
        this.getPhoneExtentions(this.countryKeys[0].id);
      }, err => {
        console.error(err);
      });
  }

  getPhoneExtentions(id) {
    this.formGroup.controls.phoneExtension.setValue(null);
    const found = this.countryKeys.find(item => item.id === id);
    if(found){
      this.selected = found;
      this.formGroup.controls.phoneExtension.setValue(found.id);
    }
  }

  GeCompanySizes() {
    this.lookupsService.GeCompanySizes().subscribe(
      data => {
        this.companySizes = data as [];
      }, err => {
        console.error(err);
      });
  }

  buildForm(){
    this.formGroup = this.formBuilder.group({
      fullName: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      ]),
      countryKey: new FormControl('', [
        Validators.required
      ]),
      phoneExtension: new FormControl('', [
        Validators.required
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{5,20}$')
      ]),
      // guestEmails: ["", [Validators.required, this.commaSepEmail]],
      guestEmails: [null],
      Country: new FormControl(null, [
        Validators.required
      ]),
      CompanyName:  new FormControl('', [
        Validators.required
      ]),
      CompanySize: new FormControl(null, [
        Validators.required
      ]),
    });

  }


  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(",").map(e => e.trim());
    const forbidden = emails.some(email =>
      Validators.email(new FormControl(email))
    );
    return forbidden ? { guestEmails: { value: control.value } } : null;
  };


  validEmali(){
    const emailsstr = this.formGroup.controls.guestEmails.value;
    if(emailsstr){
      this.emails = emailsstr.split(",").map(e => e.trim());
      if(this.emails.length > 5){
        this.invalidLength = true;
        this.formGroup.controls.guestEmails.setErrors({maxlength: true});
        return;
      }else{
        this.invalidLength = false;
        this.emails.forEach(element => {
          if(this.validateEmail(element)){
            this.validEmail = true;
          }else{
            this.formGroup.controls.guestEmails.setErrors({notValid: true});
            this.validEmail = false;
          }
        });
      }
    }


  }

   validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;;
    return re.test(String(email).toLowerCase());
  }
  save() {
    if(this.formGroup.controls.guestEmails.value && ( this.invalidLength || this.hasError('guestEmails', 'notValid'))) {
      return;
    }

    if (this.formGroup.valid) {
      this.loading = true;

      this.demoRequestObj.fullName = this.formGroup.controls.fullName.value;
      this.demoRequestObj.email = this.formGroup.controls.email.value;
      this.demoRequestObj.countryKeyLookupId = this.formGroup.controls.countryKey.value;
      this.demoRequestObj.phoneNumber = this.formGroup.controls.phoneNumber.value;
      this.demoRequestObj.guestEmails = (this.formGroup.controls.guestEmails.value)? this.formGroup.controls.guestEmails.value.replace(/\s*,\s*/g, ",") : '';
      this.demoRequestObj.guestEmails = this.demoRequestObj.guestEmails.replace(/\s/g, "");
      this.demoRequestObj.countryLookupId = this.formGroup.controls.Country.value;
      this.demoRequestObj.companyName = this.formGroup.controls.CompanyName.value;
      this.demoRequestObj.companySizeLookupId = this.formGroup.controls.CompanySize.value;

      this.bookademoService.SendDemoRequest(this.demoRequestObj).subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.success'),
          text: this.translate.instant('swalMsg.savedsuccessfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.ok'),
        }).then((result) => {
          this.restForm();
          this.isShow = true;
          this.showscheduled.emit(this.isShow);
        });
      }, err => {
        console.log(err);
        this.loading = false;
        if(err.status == 406){
          Swal.fire({
            title: this.translate.instant('swalMsg.Failed'),
            text: (err && err.detail) ? err.detail : '',
            icon: 'error',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          });
        }else{
          Swal.fire({
            title: this.translate.instant('swalMsg.Failed'),
            text: (err && err.detail) ? err.detail : '',
            icon: 'error',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          });
        }

      });

    }
  }

  restForm(){
    this.formGroup.reset();
    this.form.resetForm();
    this.formGroup.markAsUntouched();
    this.GetCountryKeys();
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

}
