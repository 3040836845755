import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';

@Injectable({ providedIn: 'root' })
export class LookupsService {

  constructor(private http: HttpClient) {
  }
  getCountries() {
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetCountries`);
  }

  GetCountryKeys(){
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetCountryKeys`);
  }

  GeCompanySizes(){
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GeCompanySizes`);
  }

  GetMonths(){
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetMonths`);
  }

  GetWeekDays(){
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetWeekDays`);
  }

  GetTimeZones(){
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetTimeZones`);
  }

  GetTimeSlots(){
    return this.http.get(`${Config.bookdemoURL}/TimeSlotsConfig/GetTimeSlots`);
  }

  GetHolidaysConsistencies() {
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetHolidaysConsistencies`);
  }
}
