<div class="page-content features-page">
  <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf="!isloaded">
    <div class="page-header" [innerHTML]="detalis.content | sanitizeHtml" *ngIf="detalis && detalis.content">
    </div>
  </ng-container>
  <!-- ready to improve -->
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-improve></app-improve>
      </div>
    </div>
  </div>

</div>
