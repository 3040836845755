import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Clients } from 'src/app/models/homepage/Clients';
import { GeneralService } from 'src/app/services/general.service';
import { TestimonialsComponent } from './testimonials/testimonials.component';
declare let $: any;
@Component({
  selector: 'app-clients-say',
  templateUrl: './clients-say.component.html',
  styles: []
})
export class ClientsSayComponent implements OnInit {

  isloaded = false;
  clientsCount = null;
  activeIndex = -1;
  SlideOptions = {
    // loop: true,
    rtl: (document.documentElement.lang === 'ar') ? true : false,
    center: false,
    dots: false,
    margin: 30,
    nav: true,
    navContainer: '.custom-nav.owl-nav',
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
      },
      1200: {
        stagePadding: 50,
        items: 2,
      },
      1400: {
        stagePadding: 50,
        items: 3,
      },
      2560: {
        stagePadding: 100,
        items: 4,
      }
    }
  };
  // 0: {
  //   items: 1,
  //   stagePadding: 0,
  // },
  // 600: {
  //   stagePadding: 0,
  //   items: 1,
  // },
  // 992: {
  //   stagePadding: 50,
  //   items: 2,
  // },
  // 1300: {
  //   stagePadding: 50,
  //   items: 3,
  // },
  // 2560: {
  //   stagePadding: 100,
  //   items: 4,
  // }
  CarouselOptions = {
    rtl: (document.documentElement.lang === 'ar') ? true : false,
    loop: false,
    autoplay: false,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    margin: 30,

    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
      },
      1200: {
        stagePadding: 50,
        items: 2,
      },
      1400: {
        stagePadding: 50,
        items: 3,
      },
      2560: {
        stagePadding: 100,
        items: 4,
      }
    }
  };

  clients = [];

  constructor(public dialog: MatDialog,
    private service: GeneralService ) {}

  ngOnInit() {
    $(window).on("orientationchange",function(){
      location.reload();
    });

    this.clients = [
      {
          "id": 1,
          "name": "Test",
          "logoPath": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "description": "fkdsfjsdflkj",
          "redirectUrl": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "display": "Castle.Proxies.DisplayValueObjectProxy"
      },
      {
          "id": 2,
          "name": "Test1",
          "logoPath": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "description": "fkdsfjsdflkj",
          "redirectUrl": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "display": "Castle.Proxies.DisplayValueObjectProxy"
      },
      {
          "id": 3,
          "name": "Test2",
          "logoPath": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "description": "fkdsfjsdflkj",
          "redirectUrl": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "display": "Castle.Proxies.DisplayValueObjectProxy"
      },
      {
          "id": 4,
          "name": "Test3",
          "logoPath": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "description": "fkdsfjsdflkj",
          "redirectUrl": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
          "display": "Castle.Proxies.DisplayValueObjectProxy"
      }
  ];
  this.getClient();
  }


  getClient(){
    this.isloaded = true;
    this.service.getClient().subscribe(
      data => {
        this.isloaded = false;
        this.clients = data as Clients[];
        this.clientsCount = this.clients.length;
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

  openDetails(record = null) {
    const dialogRef = this.dialog.open(TestimonialsComponent, {
      width: '600px',
      data: {
        record
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.activeIndex = -1;
    });
  }


}
