import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComparePackages } from 'src/app/models/comparePackages/ComparePackages';
import { CompareResult } from 'src/app/models/comparePackages/CompareResult';
import { FilterObj } from 'src/app/models/comparePackages/FilterObj';
import { Categories } from 'src/app/models/lookups/Categories';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-compareplans',
  templateUrl: './compareplans.component.html'
})
export class CompareplansComponent implements OnInit {

  billingTypeId = 0;
  ActiveIndex = 0;
  isloadedCategories = false;
  isloadedPackages = false;
  isComapre = false;
  categories: Categories[] = new Array();

  filterObj: FilterObj = new FilterObj();
  ComparePackages: ComparePackages = new ComparePackages();
  CompareData: CompareResult = new CompareResult();

  constructor(private service: GeneralService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(param => {
      if (param.pageSec) {
        this.isComapre = true;
      } else {
        this.isComapre = false;
      }
    })
    this.getComparePackages();
  }

  getComparePackages() {
    this.filterObj.pageSize = 4;
    this.filterObj.pageNumber = 0;

    this.isloadedPackages = true;
    this.service.getComparePackages(this.filterObj).subscribe(
      data => {
        this.isloadedPackages = false;
        this.ComparePackages = data as ComparePackages;
        if(this.ComparePackages && this.ComparePackages.id){
          this.getCategoriesWithFeatures();
        }
      }, err => {
        console.error(err);
        this.isloadedPackages = false;
      });
  }

  getCategoriesWithFeatures() {
    this.isloadedCategories = true;
    this.service.getCategoriesWithFeatures().subscribe(
      data => {

        this.categories = data as Categories[];

        this.categories.map(category => {
          category.features.map(f => {
            f.value = [];
            if (this.ComparePackages.result.pageData) {
              this.ComparePackages.result.pageData.map(p => {
                const feature = p.features.find(item => item.packageFeatureEnum === f.packageFeatureEnum);
                if (feature) {
                  if (parseInt(feature.value)) {
                    f.value.push(feature.value);
                  } else {
                    f.value.push('string');
                  }
                } else {
                  f.value.push('');
                }
              });
            }

          })
        });

        this.isloadedCategories = false;
        if (this.isComapre && this.categories && this.categories.length) {
               setTimeout(() => {
                  const elementPosition = document.getElementById("section2").offsetTop;
                  const offsetPosition = elementPosition;
                  console.log(offsetPosition);
                  window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                  });
                }, 100);
        }


      }, err => {
        console.error(err);
        this.isloadedCategories = false;
      });
  }


}
