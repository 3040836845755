import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Config } from '../models/config';
@Injectable({
  providedIn: 'root'
})
export class MeetingsService {

  tabClicked = new BehaviorSubject<any>(null);
  timeSlots = new BehaviorSubject<any>(null);
  weekDayTimes  = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }


  DemoRequestsPagedList(filterObj) : any{
    return this.http.post(`${Config.bookdemoURL}/DemoRequest/DemoRequestsPagedList`, filterObj);
  }
  getDemoRequestsWithDate(timingStatus = null): any{
    return this.http.get(`${Config.bookdemoURL}/DemoRequest/DemoRequestsWithDate?timingStatus=` + timingStatus);
  }

  GetDemoRequestDetailsById(demoRequestId): any{
    return this.http.get(`${Config.bookdemoURL}/DemoRequest/GetDemoRequestDetailsById?demoRequestId=` + demoRequestId);
  }

  DemoRequestsTimingCounts(): any{
    return this.http.get(`${Config.bookdemoURL}/DemoRequest/DemoRequestsTimingCounts`);
  }

  ConfirmDemoRequest(demoRequestId) {
    return this.http.put(`${Config.bookdemoURL}/DemoRequest/ConfirmDemoRequest?demoRequestId=` + demoRequestId, '');
  }

  DeclineDemoRequest(demoRequestId) {
    const prams = {
      demoRequestId: demoRequestId
    }
    return this.http.put(`${Config.bookdemoURL}/DemoRequest/DeclineDemoRequest?demoRequestId=` + demoRequestId, '');
  }

  RescheduleDemoRequest(obj) {
    return this.http.put(`${Config.bookdemoURL}/DemoRequest/RescheduleDemoRequest`, obj);
  }

  ChangeDecision(demoRequestId) {
    return this.http.put(`${Config.bookdemoURL}/DemoRequest/ChangeDecision?demoRequestId=` + demoRequestId, '');
  }

  AddTimeSlotsConfig(obj): any{
    return this.http.post(`${Config.bookdemoURL}/TimeSlotsConfig/AddTimeSlotsConfig`, obj);
  }

  confirmChange(){
  }

}
