<div class="page-content help-center">
  <div class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-12">
            <div class="content">
              <h1>
                {{ 'helpcenter.canhelp' | translate }}
              </h1>
              <p>{{ 'helpcenter.occasion' | translate }}</p>
            </div>
          </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-8 col-12">
          <div class="search">
            <input type="search" name="search" [placeholder]="'helpcenter.Searchtopics' | translate"
                   [(ngModel)]='searchText' (keydown.enter)="getSearch(searchText)" hidden>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="centers-list">
    <div class="container">
      <div class="dataLoading h-100 d-flex" *ngIf='isloaded'>
          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
      </div>
      <ng-container *ngIf="!isloaded">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12" *ngFor="let topic of listTopics;" >
            <a class="item" [routerLink]="['/helpcenter/article', topic.id]"  *ngIf="topic" (click)="getRoute(topic)">
              <div class="image">
                <img [src]="topic.logoPath">
              </div>
              <div class="content">
                <h2>{{topic.title}}</h2>
                <p>{{ topic.berif }}</p>
              </div>
            </a>
          </div>
        </div>
      </ng-container>

    </div>
  </div>

  <app-having-trouble></app-having-trouble>

</div>
