import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styles: []
})
export class HeaderContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
