<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>
<ng-container *ngIf="!dataLoading">
  <div class="page">
    <div class="meeting-section">
      <div class="row">
        <div class="col-12 text-center">
          <ul class="tabs-item listing-items">
            <li *ngFor="let item of tabsData; let i = index">
              <div [ngClass]="{activeTab: currentType == i+1}" (click)="getSelectedMeetings(item)">
                <span class="text" *ngIf="item.status == 1"> {{ 'meetings.All Meetings' | translate }}</span>
                <span class="text" *ngIf="item.status == 2"> {{ 'meetings.New Requests' | translate }} </span>
                <span class="text" *ngIf="item.status == 3"> {{ 'meetings.Upcoming' | translate }}</span>
                <span class="text" *ngIf="item.status == 4"> {{ 'meetings.Past' | translate }}</span>
                <span class="count-number"> {{item.count}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="page-filters">
        <div class="first-part d-flex align-items-center">
          <span class="text">
            {{currentCount}}
            {{ 'meetings.Demo Meetings' | translate }}
          </span>
          <div class="sort-item" *ngIf="!showCalenderView">
            <label>{{ 'general.Sortedby' | translate }}:</label>
            <select class="form-control" (change)="sortRecords($event)" [(ngModel)]="sortValue">
              <option [value]="-1">{{ 'general.Status' | translate }}</option>
              <option *ngFor="let sortItem of sortItems" [value]="sortItem.value">{{sortItem.name}}</option>
            </select>
          </div>
        </div>

        <div class="filters-container">
          <div>
            <form (submit)="makeSearch()" class="search-input" *ngIf="!showCalenderView">
              <mat-form-field appearance="legacy" *ngIf="showSearch">
                <input matInput autocomplete="off" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="filterModel.searchText" (ngModelChange)="resetAfterClear()">
                <mat-icon (click)="resetSearch(); showSearch = false;" matSuffix>clear</mat-icon>
              </mat-form-field>
              <button mat-icon-button (click)="makeSearch()">
                <mat-icon>search</mat-icon>
              </button>
            </form>
          </div>
          <div class="d-flex align-items-center">
            <button (click)="showFilter = !showFilter" [ngClass]='{"active": showFilter}'
              class="btn dropdown-custom dropdown-toggle" *ngIf="!showCalenderView">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="filter-icon" d="M17.223,0H.774A.775.775,0,0,0,.085.409a.749.749,0,0,0,.061.8L6.173,9.487l.006.008a1.652,1.652,0,0,1,.338,1v6.752a.742.742,0,0,0,.224.534A.78.78,0,0,0,7.286,18a.811.811,0,0,0,.3-.059l3.386-1.26a.717.717,0,0,0,.5-.706V10.492a1.653,1.653,0,0,1,.338-1l.006-.008L17.851,1.2a.748.748,0,0,0,.061-.8A.775.775,0,0,0,17.223,0Zm0,0" transform="translate(0.001 0)" fill="#999"/>
              </svg>
              <span>{{ 'general.Filter' | translate }}</span>
              <svg width="8" height="5.001" viewBox="0 0 8 5.001">
                <path id="menu-icon" d="M-3.562-9.316-.5-6.215l3.062-3.1.938.949-4,4.051-4-4.051Z"
                  transform="translate(4.5 9.316)" fill="#6a6a6a" opacity="0.8" />
              </svg>
            </button>
            <!-- list view -->
            <div class="list-view viewing-feature" [ngClass]="{active: activeTab}"
              (click)="showCalenderView= false; activeTab= true; getData();">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
                <path id="list-view-icon"
                  d="M-2029.166,17859h-17.794a2.089,2.089,0,0,1-2.039-2.139,2.1,2.1,0,0,1,2.165-2.016h17.794a2.087,2.087,0,0,1,2.039,2.137,2.092,2.092,0,0,1-2.106,2.018Zm-17.874-3.143a1.072,1.072,0,0,0-.973,1.17,1.08,1.08,0,0,0,1.179.959,1.211,1.211,0,0,0,.206,0,1.07,1.07,0,0,0,.973-1.164,1.082,1.082,0,0,0-1.179-.965c-.036,0-.071,0-.1,0S-2047.007,17855.857-2047.041,17855.859Zm17.874-3.783h-17.794a2.087,2.087,0,0,1-2.039-2.137,2.091,2.091,0,0,1,2.165-2.014h17.668a.673.673,0,0,1,.125,0,2.085,2.085,0,0,1,2.039,2.135,2.093,2.093,0,0,1-2.106,2.018C-2029.127,17852.078-2029.147,17852.078-2029.166,17852.076Zm-17.874-3.137a1.071,1.071,0,0,0-.973,1.166,1.077,1.077,0,0,0,1.179.959,1.815,1.815,0,0,0,.206,0,1.071,1.071,0,0,0,.973-1.166,1.073,1.073,0,0,0-1.179-.959.967.967,0,0,0-.1-.006A.873.873,0,0,0-2047.041,17848.939Zm.081-3.787a2.081,2.081,0,0,1-2.039-2.133,2.1,2.1,0,0,1,2.165-2.018h17.794a2.085,2.085,0,0,1,2.039,2.139,2.087,2.087,0,0,1-2.165,2.012h-17.668c-.02,0-.04,0-.061,0S-2046.937,17845.154-2046.96,17845.152Zm.332-1.014a1.067,1.067,0,0,0,.973-1.164,1.076,1.076,0,0,0-1.179-.959.729.729,0,0,0-.206,0,1.069,1.069,0,0,0-.973,1.164,1.073,1.073,0,0,0,1.179.959A.729.729,0,0,0-2046.628,17844.139Z"
                  transform="translate(2049 -17841.002)" fill="#fff" />
              </svg>
            </div>

            <!-- calnder view -->
            <div class="calnder-view viewing-feature" [ngClass]="{active: !activeTab}"
              (click)="showCalenderView = true; activeTab= false; getData();">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <path id="date-picker-icon"
                  d="M428.113,135.868a2.027,2.027,0,0,1-2.02-2.02V119.782a2.027,2.027,0,0,1,2.02-2.02h2.172v-.881a1.011,1.011,0,0,1,2.019-.062.572.572,0,0,1,0,.062v.881h5.6v-.881a1.011,1.011,0,0,1,2.02-.062.557.557,0,0,1,0,.062v.881H442.1a1.988,1.988,0,0,1,1.995,1.981v14.1a2.028,2.028,0,0,1-2.021,2.02Zm0-2.044h13.965v-9.579H428.113Zm1.691-4.843v-3.332h3.71v3.332Z"
                  transform="translate(-426.094 -115.868)" fill="#555" />
              </svg>
            </div>
          </div>

        </div>

        <div class="filter-section" [hidden]="!showFilter">
          <h4 class="title">{{ 'general.Filterby' | translate }}</h4>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <mat-form-field appearance="outline" class="form-group no-validation">
                <mat-label>{{ 'meetings.All' | translate }}</mat-label>
                <mat-select (selectionChange)="makeFilter()" [(ngModel)]="filterModel.filter.countryId">
                  <mat-option [value]=0 selected>
                    <span>{{ 'meetings.All' | translate }}</span>
                  </mat-option>
                  <mat-option [value]="country.id" *ngFor="let country of countries">
                    <span>{{country.name}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-6">
              <mat-form-field appearance="outline" class="form-group no-validation">
                <mat-label>{{ 'meetings.meeting Date From' | translate }}</mat-label>
                <input matInput [matDatepicker]="dateFrom" [(ngModel)]="filterModel.filter.meetingDateFrom"
                  (ngModelChange)="makeFilter()" readonly>
                <button mat-icon-button class="clear-date" *ngIf="filterModel.filter.meetingDateFrom"
                  (click)="filterModel.filter.meetingDateFrom = undefined; getAll(null);">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateFrom></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-6">
              <mat-form-field class="no-validation">
                <mat-label>{{ 'meetings.meeting Date To' | translate }}</mat-label>
                <input matInput [matDatepicker]="dateTo" [(ngModel)]="filterModel.filter.meetingDateTo"
                  (ngModelChange)="makeFilter()" readonly>
                <button mat-icon-button class="clear-date" *ngIf="filterModel.filter.meetingDateTo"
                  (click)="filterModel.filter.meetingDateTo = undefined; getAll(null);">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                <mat-datepicker #dateTo></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-6">
              <mat-form-field appearance="outline" class="form-group no-validation">
                <mat-label>{{ 'meetings.request Date From' | translate }}</mat-label>
                <input matInput [matDatepicker]="requestDateFrom" [(ngModel)]="filterModel.filter.requestDateFrom"
                  (ngModelChange)="makeFilter()" readonly>
                <button mat-icon-button class="clear-date" *ngIf="filterModel.filter.requestDateFrom"
                  (click)="filterModel.filter.requestDateFrom = undefined; getAll(null);">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="requestDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #requestDateFrom></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-6">
              <mat-form-field appearance="outline" class="form-group no-validation">
                <mat-label>{{ 'meetings.request Date To' | translate }}</mat-label>
                <input matInput [matDatepicker]="requestDateTo" [(ngModel)]="filterModel.filter.requestDateTo"
                  (ngModelChange)="makeFilter()" readonly>
                <button mat-icon-button class="clear-date" *ngIf="filterModel.filter.requestDateTo"
                  (click)="filterModel.filter.requestDateTo = undefined; getAll(null);">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="requestDateTo"></mat-datepicker-toggle>
                <mat-datepicker #requestDateTo></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- list view -->
      <div *ngIf="!showCalenderView">
        <div *ngIf='listLoading' class="dataLoading">
          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        <ng-container *ngIf='!listLoading'>
          <div *ngIf="recordsData.pageDate && recordsData.pageDate.length; else dataEmpty">
            <div class="table-responsive" *ngIf="recordsData.pageDate.length; else dataEmpty">
              <table class="table table-primary">
                <thead>
                  <tr>
                    <th width="45%">{{ 'meetings.Company' | translate }}</th>
                    <th width="20%">{{ 'meetings.demo Meeting Date & Time' | translate }}</th>
                    <th width="15%">{{ 'meetings.Status' | translate }}</th>
                    <th width="20%">{{ 'meetings.Requested On' | translate }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let record of recordsData.pageDate; index as i">

                    <td>
                      <div class="name-with-image">
                        <div class="name">
                          <h6>{{record.companyName}}</h6>
                        </div>
                      </div>
                      <div class="one-item d-flex align-items-center" *ngIf="record.countryName">
                        <div class="name-with-image">
                          <div class="image-circle mr-3">
                            <div class="image m-0">
                              <img *ngIf="record.flagSVG" [src]="record.flagSVG" (error)="record.flagSVG = ''" />
                              <span *ngIf="!record.flagSVG">{{ initName(record.countryName) | uppercase }}</span>
                            </div>
                          </div>
                          <div class="name">
                            <h5> {{record.countryName}}</h5>
                          </div>
                        </div>
                        <div class="employ-count">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" viewBox="0 0 30 16">
                            <path id="employees-icon"
                              d="M40.678,25.973v-.941a5.182,5.182,0,0,0-2.53-4.452,3.574,3.574,0,1,0-5.253,0,5.186,5.186,0,0,0-1.022.8,7.247,7.247,0,0,0-3.061-2.838,4.844,4.844,0,1,0-6.268,0,7.248,7.248,0,0,0-3.061,2.838,5.187,5.187,0,0,0-1.022-.8,3.574,3.574,0,1,0-5.253,0,5.182,5.182,0,0,0-2.53,4.452v.941Zm-6.855-7.824a1.7,1.7,0,1,1,1.7,1.706A1.7,1.7,0,0,1,33.823,18.149Zm-1.145,5.24a3.276,3.276,0,0,1,5.989.7H32.8C32.775,23.862,32.729,23.609,32.678,23.389Zm-9.969-8.554a2.969,2.969,0,1,1,2.969,2.98A2.978,2.978,0,0,1,22.709,14.836ZM25.678,19.7a5.328,5.328,0,0,1,5.23,4.392H20.448A5.328,5.328,0,0,1,25.678,19.7ZM14.135,18.149a1.7,1.7,0,1,1,1.7,1.706A1.7,1.7,0,0,1,14.135,18.149Zm-1.446,5.941a3.276,3.276,0,0,1,5.989-.7c-.051.22-.1.472-.127.7Z"
                              transform="translate(-10.678 -9.973)" fill="#b3bcc5" />
                          </svg>
                          <span>{{record.numberOfEmployees}} {{ 'meetings.Employees' | translate }}</span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span>      {{record.meetingDate | date: 'fullDate'}}</span>

                      <br>
                      <span *ngIf=" record.meetingTime"> {{'0000-00-00T' + record.meetingTime | date:'h:mm aa'}}</span>

                      <span>({{record.timeZone}})</span>
                    </td>

                    <td>
                      <div class="d-flex align-items-center status-waiting" *ngIf="record.listStatus == 1">
                        <svg width="12" height="12" viewBox="0 0 12 12">
                          <circle id="circle" cx="6" cy="6" r="6" />
                        </svg>
                        <span class="text-custom p-0 pl-2">
                          {{ 'meetings.Pending Confirmation' | translate }}
                        </span>
                      </div>
                      <div class="d-flex align-items-center status-active" *ngIf="record.listStatus == 2">
                        <svg width="12" height="12" viewBox="0 0 12 12">
                          <circle id="circle" cx="6" cy="6" r="6" />
                        </svg>
                        <span class="text-custom p-0 pl-2">
                          {{ 'meetings.Upcoming' | translate }}
                        </span>
                      </div>
                      <div class="d-flex align-items-center status-Eligible" *ngIf="record.listStatus == 3 || record.status == 4">
                        <svg width="12" height="12" viewBox="0 0 12 12">
                          <circle id="circle" cx="6" cy="6" r="6" />
                        </svg>
                        <span class="text-custom p-0 pl-2">
                          {{ 'meetings.PastAndCompleted' | translate }}
                        </span>
                      </div>
                    </td>
                    <td>

                      {{record.requestedOn | date: 'mediumDate'}}
                      <br>
                      {{record.requestedOn | date:'h:mm aa'}}
                    </td>
                    <td>
                      <div class="btn-actions">
                        <a mat-icon-button [matTooltip]="'general.View Details' | translate"
                          (click)="showDetails = true; detailsdemoRequestId = record.id">
                          <mat-icon>visibility</mat-icon>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination-container">
              <div class="page-filter" *ngIf="pageCount > 1">
                <span>{{ 'general.page' | translate }}</span><input type="text" (change)="updatePage($event)"
                  [value]="manualPage" />/{{ pageCount }}
              </div>
              <mat-paginator #paginator *ngIf="pageCount > 1" [length]="recordsData.totalCount"
                [pageSize]="filterModel.pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="getAll($event)"
                [pageIndex]="filterModel.pageNumber" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </ng-container>

        <ng-template #dataEmpty>
          <div class="data-empty">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="110"
                height="99.999" viewBox="0 0 110 99.999">
                <defs>
                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                        gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#0f8ccf" />
                        <stop offset="1" stop-color="#0b7ebc" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1"
                        gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#f87f0f" />
                        <stop offset="1" stop-color="#dc6a01" />
                    </linearGradient>
                </defs>
                <g id="no-members-illustration" transform="translate(-823 -642)">
                    <path id="Path_2516" data-name="Path 2516"
                        d="M99.268,21.663c0,22.014,11.315,37.132,11.315,47.74s-6.051,32.886-54.471,32.886S.583,72.318.583,60.65c0-54.637,98.685-77.975,98.685-38.987Z"
                        transform="translate(822.417 639.71)" fill="#efefef" opacity="0.6" />
                    <g id="Group_764" data-name="Group 764" transform="translate(839.484 647.141)">
                        <path id="Path_2517" data-name="Path 2517" d="M0,0H6.365V3.183H0Z"
                            transform="translate(12.941 4.508) rotate(-45)" fill="#9fbfcf" />
                        <path id="Path_2518" data-name="Path 2518" d="M0,0H6.365V3.183H0Z"
                            transform="translate(0 17.443) rotate(-45)" fill="#9fbfcf" />
                        <path id="Path_2519" data-name="Path 2519" d="M0,0H3.183V6.365H0Z"
                            transform="translate(0.562 2.25) rotate(-45)" fill="#9fbfcf" />
                    </g>
                    <path id="shadow"
                        d="M37.154,11.75H29A7.247,7.247,0,0,0,21.75,19v4.531H29.9V19A7.247,7.247,0,0,1,37.154,11.75Z"
                        transform="translate(870.018 657.366)" fill="#d5dbe1" />
                    <path id="Union_1" data-name="Union 1"
                        d="M-431.043,7160.122v-3.544a5.338,5.338,0,0,0-5.347-5.315h-10.7a5.338,5.338,0,0,0-5.347,5.315v3.544a1.778,1.778,0,0,1-1.783,1.771,1.777,1.777,0,0,1-1.783-1.771v-3.544a8.9,8.9,0,0,1,8.913-8.859h10.7a8.9,8.9,0,0,1,8.911,8.859v3.544a1.776,1.776,0,0,1-1.781,1.771A1.777,1.777,0,0,1-431.043,7160.122Zm-17.827-23.035a7.116,7.116,0,0,1,7.13-7.087,7.116,7.116,0,0,1,7.13,7.087,7.117,7.117,0,0,1-7.13,7.089A7.117,7.117,0,0,1-448.87,7137.087Zm3.566,0a3.557,3.557,0,0,0,3.564,3.544,3.559,3.559,0,0,0,3.566-3.544,3.558,3.558,0,0,0-3.566-3.543A3.557,3.557,0,0,0-445.3,7137.087Z"
                        transform="translate(1346.478 -6480.998)" fill="url(#linear-gradient)" />
                    <path id="shadow-2" data-name="shadow"
                        d="M14.909,36.413A22.658,22.658,0,0,1,33.492,14.134a22.656,22.656,0,1,0-4.079,44.941,22.879,22.879,0,0,0,4.079-.384A22.658,22.658,0,0,1,14.909,36.413Z"
                        transform="translate(831.25 662.25)" fill="#d5dbe1" />
                    <path id="Union_2" data-name="Union 2"
                        d="M-398.49,7189.673l-16.121-16.021a24.937,24.937,0,0,1-16.432,6.152A24.911,24.911,0,0,1-456,7155a24.911,24.911,0,0,1,24.958-24.8,24.911,24.911,0,0,1,24.956,24.8,24.615,24.615,0,0,1-6.017,16.137l16.135,16.032a1.768,1.768,0,0,1,0,2.506,1.781,1.781,0,0,1-1.26.518A1.792,1.792,0,0,1-398.49,7189.673ZM-452.435,7155a21.349,21.349,0,0,0,21.392,21.263A21.348,21.348,0,0,0-409.652,7155a21.347,21.347,0,0,0-21.391-21.26A21.348,21.348,0,0,0-452.435,7155Zm32.088,12.211v-3.544a5.338,5.338,0,0,0-5.349-5.314h-10.7a5.338,5.338,0,0,0-5.349,5.314v3.544a1.777,1.777,0,0,1-1.781,1.772,1.777,1.777,0,0,1-1.783-1.772v-3.544a8.9,8.9,0,0,1,8.912-8.858h10.7a8.9,8.9,0,0,1,8.912,8.858v3.544a1.777,1.777,0,0,1-1.781,1.772A1.777,1.777,0,0,1-420.348,7167.209Zm-17.827-23.033a7.116,7.116,0,0,1,7.131-7.088,7.115,7.115,0,0,1,7.13,7.088,7.115,7.115,0,0,1-7.13,7.086A7.115,7.115,0,0,1-438.174,7144.176Zm3.566,0a3.557,3.557,0,0,0,3.566,3.542,3.556,3.556,0,0,0,3.564-3.542,3.557,3.557,0,0,0-3.564-3.544A3.557,3.557,0,0,0-434.609,7144.176Z"
                        transform="translate(1293 -6456.192)" fill="url(#linear-gradient-2)" />
                </g>
            </svg>
            <p>{{ 'general.NoFound' | translate }}</p>
        </div>
        </ng-template>
      </div>

      <!-- calnder view -->
      <div *ngIf="showCalenderView">
        <app-calnder-view></app-calnder-view>
      </div>
    </div>
  </div>

</ng-container>
<app-meeting-details *ngIf="showDetails && detailsdemoRequestId != null" [detailsdemoRequestId]='detailsdemoRequestId'
  (closeDetails)='showDetails = false;getAll(null);'></app-meeting-details>
