import { Counts } from './Counts';
import { SearchData } from './SearchData';
export class SearchResult {
  counts: Counts[] = new Array();
  pageSize: number;
  pageIndex: number;
  totalCount: number;
  categoriesCount: number;
  pageData: SearchData[] = new Array();
}
