import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
declare let $: any;
@Component({
  selector: 'app-termandcondition',
  templateUrl: './termandcondition.component.html',
  styles: []
})
export class TermandconditionComponent implements OnInit {

  isClicked = false;
  currentRate = 0;
  constructor(
    private router: Router,
    private service: GeneralService
    ) { }

  ngOnInit() {
    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus') ) {
      $('body').addClass('contactUs-page');

    } else{
      $('body').removeClass('contactUs-page');

    }
  }

  scroll(el: HTMLElement) {
    setTimeout(() => {
      const headerOffset = document.querySelector('.header').clientHeight;
      const pageHeader = document.querySelector('.page-header').clientHeight;
      const elementPosition = el.offsetTop;
      const offsetPosition = elementPosition +  headerOffset + pageHeader;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }, 100);

    // el.scrollIntoView({ behavior: "smooth", block: "center" });
  }

}
