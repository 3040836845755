<div class="improve-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
          <h2>{{ 'improve.improveyour' | translate }}</h2>
          <h3>{{ 'improve.digitaladoption' | translate }}</h3>
          <ul  class="btns-group d-flex justify-content-center">
            <li class="primary-btn">
              <a  [routerLink]="['/helpcenter/article', 2]">{{ 'general.GetStarted' | translate }}</a>
            </li>
          </ul>
      </div>
    </div>
  </div>
</div>
