import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html'
})
export class AdminHeaderComponent implements OnInit {


  language = localStorage.getItem('language') || 'en';

  @Output() sidebarTrigger = new EventEmitter();

  userMenu = false;
  shouldShow = false;

  notifLoading = false;
  pageNumber = 0;
  pageSize = 10;
  pageCount: number;
  notificationMenu = false;
  notificationsResult: any = {
    unSeenNotificationsCount: 0,
    totalCount: 0,
  };
  notificationsList: Array<any> = [];
  NotificationsObject: any;

  currentUser: User = {
    name: '',
  } as User;

  isArabic = false;
  constructor(
    private router: Router,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    console.log(this.currentUser );
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(this.language);

    if (this.language === 'en') { this.isArabic = false; }

  }

  ngOnInit() {

  }

  // Actions
  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  // Notifications
  openNotificationPanel() {
    this.notificationMenu = !this.notificationMenu;
    this.userMenu = false;
  }
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeOpenedDrop();
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.target === document.getElementById('popOverlay')) {
      this.closeOpenedDrop();
    }
  }
  closeOpenedDrop() {
    this.userMenu = false;
    this.shouldShow = false;
    this.notificationMenu = false;
  }



  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.getNotifications(false);
    }
  }
  getNotifications(update) {
    if (update) {
      this.pageNumber = 0;
      this.notificationsList = [];
    }
    this.notifLoading = true;
    this.NotificationsObject.pageSize = this.pageSize;
    this.NotificationsObject.pageNumber = this.pageNumber;
    // this.notificationsService.GetNotifications(this.NotificationsObject).subscribe(
    //   result => {
    //     this.notifLoading = false;
    //     this.notificationsResult = result as any;
    //     this.pageCount = Math.ceil(this.notificationsResult.totalCount / this.pageSize);
    //     this.notificationsList = this.notificationsList.concat(this.notificationsResult.pageData);
    //   }, err => {
    //     this.notifLoading = false;
    //     console.error(err);
    //   });
  }

  refreshAllDetails(){}
  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

}
