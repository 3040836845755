<div class="configuration">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center">
      <div class="col-xl-12col-lg-10 col-12">

        <div class="per-configuration">
          <div class="msg">
            <h2>
              {{ 'config.Booking demo meetings are available as per configuration below' | translate }}
            </h2>
            <p> {{ 'config.You can still temporarily pause bookings by switching this button off' | translate }}</p>
          </div>
          <div class="toggle">
            <!-- checked -->
            <mat-slide-toggle (change)="confirmChange()" [(ngModel)]="isConfirm">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="configuration-form">
          <form [formGroup]="formGroup" #form="ngForm">
            <div class="btns">
              <button class="primary-btn transparent-btn" mat-raised-button color="primary" (click)="resetForm();$event.preventDefault();"
                [disabled]="loading"> {{ 'meetings.cancel' | translate
                }}
              </button>
              <button class="primary-btn" mat-raised-button color="primary" [class.loading]="loading"
                [disabled]="loading" (click)="save()"> {{ 'config.Save & Close' | translate }}
              </button>
            </div>
            <div class="form-elements">
              <div class="title"> {{ 'config.Set your time slots for booking demo meetings here' | translate }}</div>
              <div class="elements">
                <div class="titles">
                  <h3> {{ 'config.Create your own time slots' | translate }}</h3>
                  <p> {{ 'config.Time slots will be calculated based on the following inputs' | translate }}</p>
                </div>

                <div class="form-element pb-3">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                      <p class="zone"> {{ 'config.Time Zone' | translate }}</p>
                      <div class="zones-item">
                        <mat-form-field appearance="none" class="form-control form-group pl-0"
                          [class.loading-field]="isZoneloaded" class="custom-loading">
                          <mat-label> {{ 'bookademo.Select Time Zone' | translate }}</mat-label>
                          <mat-select formControlName="timeZone" [disabled]="isZoneloaded" required>
                            <mat-option [value]="timeZone.id" *ngFor="let timeZone of timeZones">
                              <span>{{timeZone.name}}</span>
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="hasError(formGroup, 'timeZone', 'required')">{{ 'bookademo.Required' |
                            translate
                            }}</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="form-element">
                  <p> {{ 'config.Define Availability Hours Meetings will be held within' | translate }}</p>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                      <mat-form-field  appearance="standard" class="form-group mb-4 custom-loading"
                        [class.loading-field]="ismonthsloaded">
                        <mat-label>{{ 'config.Months' | translate }}</mat-label>
                        <mat-select formControlName="months" [disabled]="ismonthsloaded" required>
                          <mat-option [value]="month.id" *ngFor="let month of Months">
                            <span>{{month.name}}</span>
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError(formGroup, 'months', 'required')">{{ 'bookademo.Required' |
                          translate }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <!-- [min]="time" -->
                    <div class="col-lg-4 col-md-6 col-12">
                      <mat-form-field class="form-group matTimePicker"  appearance="standard">
                        <mat-label> {{ 'config.Start Time' | translate }}</mat-label>
                        <input matInput [ngxTimepicker]="startTimePicker" [format]="24" formControlName="startTime"
                          readonly [disableClick]="true" (ngModelChange)='calculateWorkDayHours()'>
                        <mat-icon *ngIf="formGroup.controls.startTime.value"
                          (click)="formGroup.controls.startTime.setValue('')" class="clear-icon">clear
                        </mat-icon>
                        <ngx-material-timepicker-toggle [for]="startTimePicker">
                        </ngx-material-timepicker-toggle>
                        <ngx-material-timepicker #startTimePicker>
                        </ngx-material-timepicker>
                        <mat-error *ngIf="hasError(formGroup, 'startTime', 'required')">
                          {{ 'bookademo.Required' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                      <!-- [min]="formGroup.controls.startTime.value" -->
                      <mat-form-field class="form-group matTimePicker"  appearance="standard">
                        <mat-label> {{ 'config.End Time' | translate }}</mat-label>
                        <input matInput [ngxTimepicker]="endTimePicker" [format]="24" formControlName="endTime" readonly
                          [disableClick]="true" (ngModelChange)='calculateWorkDayHours()'>
                        <mat-icon *ngIf="formGroup.controls.endTime.value"
                          (click)="formGroup.controls.endTime.setValue('')" class="clear-icon">clear
                        </mat-icon>
                        <ngx-material-timepicker-toggle [for]="endTimePicker"
                          [disabled]="!formGroup.controls.startTime.value">
                        </ngx-material-timepicker-toggle>
                        <ngx-material-timepicker #endTimePicker>
                        </ngx-material-timepicker>
                        <mat-error *ngIf="hasError(formGroup, 'endTime', 'required')">
                          {{ 'bookademo.Required' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 has-error"
                      *ngIf="!validWorkDay && formGroup.controls.startTime.value && formGroup.controls.endTime.value"
                      style="margin-top: -37px;">
                      {{'config.please Enter End time greater than start time' | translate}}
                    </div>
                  </div>
                </div>

                <div class="form-element">
                  <p> {{ 'bookademo.Meeting duration' | translate }}</p>
                  <div class="listing-container d-flex align-items-center">
                    <ul class="list-time">
                      <li *ngFor="let meetingDuration of meetingDurations;let i = index"
                        [ngClass]="{'active': activeDuration == meetingDuration.id}"
                        (click)="activeDuration = meetingDuration.id;setMeetingDuration(meetingDuration, i, false)">
                        <span *ngIf="i != 4">{{meetingDuration.value}} </span>
                        <span *ngIf="i == 4"> {{'config.custom' | translate}} </span>
                        <span *ngIf="i != 4" class="pl-1"> {{'config.Min' | translate}}</span>
                      </li>
                    </ul>
                    <div *ngIf="showCustomDuration" class="custom">
                      <mat-form-field  appearance="standard">
                        <mat-label>{{'config.Enter Time' | translate}}</mat-label>
                        <input matInput autocomplete="off" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="customDurationMin" (keydown.enter)="$event.preventDefault();"
                          (ngModelChange)="setMeetingDuration(null, null, true)" appOnlynumber>
                      </mat-form-field>
                      <div class="min-lable">
                        {{'config.Min' | translate}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-element">
                  <p> {{ 'config.Time between Meetings (break)' | translate }}</p>
                  <div class="listing-container d-flex align-items-center" [ngClass]="{'mb-0': !timeSlots.length}">
                    <ul class="list-time">
                      <li *ngFor="let breakTime of MeetingBreak;let i = index"
                        [ngClass]="{'active': activeBreak == breakTime.id}"
                        (click)="activeBreak =  breakTime.id;setMeetingBreak(breakTime, i, false)">
                        <span *ngIf="i == 0">{{'config.none' | translate}}</span>
                        <span *ngIf="i != 0 && i != 4">{{breakTime.value}} </span>
                        <span *ngIf="i == 4">{{'config.custom' | translate}} </span>
                        <span *ngIf="i != 0 && i != 4" class="pl-1"> {{'config.Min' | translate}}</span>
                      </li>
                    </ul>
                    <div *ngIf="showCustomBreak" class="custom">
                      <mat-form-field  appearance="standard">
                        <mat-label>{{'config.Enter Time' | translate}}</mat-label>
                        <input matInput autocomplete="off" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="customBreakMin" (keydown.enter)="$event.preventDefault();"
                          (ngModelChange)="setMeetingBreak(null, null, true)" appOnlynumber>
                      </mat-form-field>
                      <div class="min-lable">
                        {{'config.Min' | translate}}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="form-element" *ngIf="timeSlots.length">
                  <p> {{ 'config.Time slots are generated using the times you specified above' | translate }}</p>
                  <ul class="time-slots">
                    <li *ngFor="let time of timeSlots">
                      <span>{{time.timeValue}}</span>
                    </li>
                  </ul>
                </div>

                <div class="v-line" *ngIf="timeSlots.length"></div>

                <div class="form-element" *ngIf="timeSlots.length">
                  <div class="Setup-slots">
                    <h4>
                      {{ 'config.Set up time slots for weekdays' | translate }}
                    </h4>
                    <p> {{ 'config.Organize your weekly meetings using the time slots youve set' | translate }}</p>
                  </div>
                  <div class="slots">
                    <app-slots-config> </app-slots-config>
                    <div class="has-error" *ngIf="!validSlots && isSubmitted">
                      {{ 'config.please Set up time slots for weekdays' | translate }}
                    </div>
                  </div>
                </div>

                <ul class="hints" *ngIf="timeSlots.length">
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
                      <path id="info-icon"
                        d="M8,15a.518.518,0,0,1,0-1.033l.455-.77a3.77,3.77,0,0,1,3.24-1.818h.571A2.631,2.631,0,0,0,14.93,8.795V3.622a2.63,2.63,0,0,0-2.665-2.584H3.735a2.631,2.631,0,0,0-2.67,2.584V8.795a2.631,2.631,0,0,0,2.67,2.584H4.3A3.771,3.771,0,0,1,7.37,12.935a.506.506,0,0,1,.095.546A.533.533,0,0,1,7,13.8a.539.539,0,0,1-.5-.273,2.694,2.694,0,0,0-2.19-1.11H3.735A3.684,3.684,0,0,1,0,8.795V3.622A3.684,3.684,0,0,1,3.735,0h8.53A3.685,3.685,0,0,1,16,3.622V8.795a3.685,3.685,0,0,1-3.735,3.622h-.571a2.689,2.689,0,0,0-2.309,1.294l-.46.776a1.054,1.054,0,0,1-.91.514ZM7.466,9.565V6.278a.537.537,0,0,1,1.066,0V9.57a.533.533,0,0,1-1.066,0Zm0-4.911V4.14a.537.537,0,0,1,1.066,0v.519a.533.533,0,0,1-1.066-.005Z"
                        fill="#9aa5b1" />
                    </svg>

                    <span>  {{ 'config.Unselected time slots indicate that the meeting is not available for booking'
                        | translate }} </span>
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
                      <path id="info-icon"
                        d="M8,15a.518.518,0,0,1,0-1.033l.455-.77a3.77,3.77,0,0,1,3.24-1.818h.571A2.631,2.631,0,0,0,14.93,8.795V3.622a2.63,2.63,0,0,0-2.665-2.584H3.735a2.631,2.631,0,0,0-2.67,2.584V8.795a2.631,2.631,0,0,0,2.67,2.584H4.3A3.771,3.771,0,0,1,7.37,12.935a.506.506,0,0,1,.095.546A.533.533,0,0,1,7,13.8a.539.539,0,0,1-.5-.273,2.694,2.694,0,0,0-2.19-1.11H3.735A3.684,3.684,0,0,1,0,8.795V3.622A3.684,3.684,0,0,1,3.735,0h8.53A3.685,3.685,0,0,1,16,3.622V8.795a3.685,3.685,0,0,1-3.735,3.622h-.571a2.689,2.689,0,0,0-2.309,1.294l-.46.776a1.054,1.054,0,0,1-.91.514ZM7.466,9.565V6.278a.537.537,0,0,1,1.066,0V9.57a.533.533,0,0,1-1.066,0Zm0-4.911V4.14a.537.537,0,0,1,1.066,0v.519a.533.533,0,0,1-1.066-.005Z"
                        fill="#9aa5b1" />
                    </svg>

                    <span> {{ 'config.Time slots will be applied every month and in the years ahead' | translate
                        }} </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="btns mt-3">
              <button class="primary-btn transparent-btn" mat-raised-button color="primary" (click)="resetForm();$event.preventDefault();"
                [disabled]="loading"> {{ 'meetings.cancel' | translate
                }}
              </button>
              <button class="primary-btn" mat-raised-button color="primary" [class.loading]="loading"
                [disabled]="loading" (click)="save()"> {{ 'config.Save & Close' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
