<div class="calnder-header">
  <div class="row">
    <div class="col-md-8 col-12">
      <div class="calender-nav">
        <h2>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h2>
        <div class="btn-group">
          <div
            class="prev-btn"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
              <path id="Next" d="M606.434,253.04l-6.406-5.6a.346.346,0,0,1-.022-.534l.022-.019,6.406-5.6a.477.477,0,0,1,.611-.019l.021.019.687.6a.346.346,0,0,1,.021.535l-.021.018-5.4,4.725,5.4,4.726a.346.346,0,0,1,.02.535l-.02.018-.687.6a.478.478,0,0,1-.612.017l-.02-.017Z" transform="translate(-599.891 -241.16)" fill="#71a2bc"/>
            </svg>
          </div>
          <div
            class="next-btn"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
              <path id="Next" d="M601.348,253.04l6.406-5.6a.346.346,0,0,0,.022-.534l-.022-.019-6.406-5.6a.477.477,0,0,0-.611-.019l-.021.019-.687.6a.346.346,0,0,0-.021.535l.021.018,5.4,4.725-5.4,4.726a.346.346,0,0,0-.02.535l.02.018.687.6a.478.478,0,0,0,.612.017l.02-.017Z" transform="translate(-599.891 -241.16)" fill="#71a2bc"/>
            </svg>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-4 col-12">
      <div class="btn-viewing">
        <div
          (click)="viewChange.emit(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          {{ 'meetings.Month' | translate }}
        </div>
        <div
          (click)="viewChange.emit(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          {{ 'meetings.Week' | translate }}
        </div>
        <div
          (click)="viewChange.emit(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          {{ 'meetings.Day' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
