import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListTopics } from '../../models/helpcenter/ListTopics';
import { GeneralService } from '../../services/general.service';

declare let $: any;
@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styles: []
})
export class HelpCenterComponent implements OnInit {

  searchText = '';
  listTopics: ListTopics[] = new Array();
  isloaded = false;

  constructor(
    private router: Router,
    private service: GeneralService
  ) { }

  ngOnInit() {
    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus') ) {
      $('body').addClass('contactUs-page');

    } else {
      $('body').removeClass('contactUs-page');
    }

    this.getListTopics();
  }

  getListTopics() {
    this.isloaded = true;
    this.service.getListTopics().subscribe(
      data => {
        this.isloaded = false;
        this.listTopics = data as ListTopics[];
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

  getSearch(text = null) {
    this.router.navigate(['/searchresult'], { queryParams: { searchText: text } });
  }

  getRoute(article){
    this.service.getRoute.next(article.title);
  }
}
