import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-having-trouble',
  templateUrl: './having-trouble.component.html',
  styles: []
})
export class HavingTroubleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
