import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { OwlModule } from 'ngx-owl-carousel';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DemoMaterialModule } from 'src/app/helpers/material-modules';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';

import { DndDirective } from 'src/app/helpers/dnd.directive';
import { SanitizeHtmlPipePipe } from 'src/app/helpers/sanitize-html-pipe.pipe';
import { OnlynumberDirective } from 'src/app/helpers/onlynumber.directive';
import { ShortNamePipe } from 'src/app/helpers/shortName.pipe';


import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { PricingComponent } from './pricing/pricing.component';
import { ImproveComponent } from './improve/improve.component';
import { HavingTroubleComponent } from './having-trouble/having-trouble.component';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    DndDirective,
    ShortNamePipe,
    OnlynumberDirective,
    SanitizeHtmlPipePipe,

    PricingComponent,
    ImproveComponent,
    HavingTroubleComponent,
    BreadcrumbComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DemoMaterialModule,
    RouterModule,
    RecaptchaModule,
    NgxMaterialTimepickerModule,
    InfiniteScrollModule,
    TranslateModule.forChild({})
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,

    OwlModule,
    DemoMaterialModule,

    RecaptchaModule,
    InfiniteScrollModule,
    TranslateModule,

    DndDirective,
    ShortNamePipe,
    OnlynumberDirective,
    SanitizeHtmlPipePipe,
    NgxMaterialTimepickerModule,

    PricingComponent,
    ImproveComponent,
    HavingTroubleComponent,
    BreadcrumbComponent
  ],
  providers: [
    DatePipe,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleSiteKey,
        useValue: localStorage.getItem('language') || 'en'
      } as RecaptchaSettings,
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } }
  ]
})

export class SharedModule { }
