import { Component, OnInit, Input } from '@angular/core';
import { MeetingInfo } from 'src/app/models/DemoRequest/MeetingInfo';
import { BookademoService } from 'src/app/services/bookademo.service';

@Component({
  selector: 'app-book-items',
  templateUrl: './book-items.component.html',
  styles: [
  ]
})
export class BookItemsComponent implements OnInit {

  @Input() showConfirmForm;

  meetingInfo: MeetingInfo = new MeetingInfo();

  meetingDuration: any = null;

  constructor( private bookademoService: BookademoService) { }

  ngOnInit(): void {
    // this.getBookingDataHeader();
    this.bookademoService.meetingInfo.subscribe(data => {
      this.meetingInfo = data;
    });
    this.bookademoService.meetingDuration.subscribe(data => {
      this.meetingDuration = data;
    });
  }



  getBookingDataHeader() {
    this.bookademoService.getBookingDataHeader().subscribe(
      data => {
        this.meetingDuration = data.meetingDuration;
        this.bookademoService.meetingDuration.next(this.meetingDuration);
      }, err => {
        console.error(err);
      });
  }

}
