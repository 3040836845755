
 <div class="container">
  <div class="row">
    <div class="col-12">
      <div class="header-content">
        <div class="img-container">
           <img src="./assets/images/hero/laptop_hero_area.png" alt="" srcset="">
        </div>
        <div class="content">

          <div class="animate__animated animate__fadeIn animate__delay-0.8s" >
            <!-- كل ما تحتاجه -->

            <div class="h1-title">   {{ 'header.Everythingto' | translate }}</div>
            <h2 class="h2-title"> {{ 'header.organize' | translate }}</h2>
            <p> {{ 'header.Connectis' | translate }}</p>
            <ul  class="btns-group">
              <li class="primary-btn">
                <a [routerLink]="['/helpcenter/article', 2]">{{ 'general.GetStarted' | translate }}</a>
              </li>
              <li class="border-btn">
                <a [routerLink]="['/bookdemo']"> {{ 'general.BookDemo' | translate }} </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
