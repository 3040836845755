import { Component, OnInit } from '@angular/core';
import { LoginModel } from 'src/app/models/auth/login-model';

import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginObject: LoginModel = new LoginModel();

  loading = false;
  rememberMe = false;
  hidePassword = true;
  formSubmitted = false;

  returnUrl: string;


  language = localStorage.getItem('language') || 'en';
  isArabic = false;

  errorMsg = null;

  captcha = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
  ) {
    if (this.route.snapshot.routeConfig.path === 'logout') {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
      return;
    }
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/admin']);
    }

    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(this.language);

    if (this.language === 'en') { this.isArabic = false; }


  }

  ngOnInit(): void {
    // get return url from route parameters or default to 'dashboard'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/admin';

    this.buildForm();
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      userName: new FormControl('', [
        Validators.required
      ]),
      password: ['', Validators.required],
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  login() {
    this.formSubmitted = true;
    if (this.loginForm.valid && this.captcha) {
      this.loading = true;
      this.loginObject.userName = this.loginForm.controls.userName.value;
      this.loginObject.password = this.loginForm.controls.password.value;
      this.authenticationService.login(this.loginObject.userName, this.loginObject.password, this.rememberMe)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            this.router.navigate([this.returnUrl]);
          },
          error => {
            this.loading = false;
            this.errorMsg = error;
            // Swal.fire({
            //   title: this.translate.instant('swalMsg.Failed'),
            //   text: this.translate.instant('swalMsg.Failed'),
            //   icon: 'error',
            //   confirmButtonText: this.translate.instant('swalMsg.ok'),
            // });

          });
    } else {
      console.log('Sorry!');
      return;
    }
  }

  public resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
    return;
  }

}
