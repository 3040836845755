import { RequestsListStatusEnum } from "../enum/RequestsListStatusEnum";
import { RequestsTimingStatesEnum } from "../enum/RequestsTimingStatesEnum";

export class ListDataFilter {
    pageSize: number;
    pageNumber: number;
    searchText: string;
    timingStatus: RequestsTimingStatesEnum;
    sortingStatus: RequestsListStatusEnum;
    filter = {} as {
      countryId: number;
      companyEmployeesCapacityFrom: any;
      companyEmployeesCapacityTo: any;
      meetingDateFrom: any;
      meetingDateTo: any;
      requestDateFrom: any;
      requestDateTo: any;
    };
}
