
<div class="d-flex">
  <div *ngFor="let day of days; let i = index" [hidden]="i >0">
      <ul class="time-slots"  *ngIf="day.times.length">
        <li *ngFor="let time of day.times" > {{time.timeValue}}</li>
      </ul>
  </div>
  <section class="checkbox-container" *ngFor="let day of days">

    <div class="slots-header">
      <mat-checkbox class="example-margin"
                    [checked]="day.allComplete"
                    [indeterminate]="someComplete(day)"
                    (change)="setAll($event.checked, day)">
        {{day.name}}
      </mat-checkbox>
    </div>
    <div class="items"  *ngIf="day.times.length">
      <ul>
        <li *ngFor="let time of day.times; let i = index" >
          <mat-checkbox [(ngModel)]="time.completed"
                        (ngModelChange)="updateAllComplete(day)">
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </section>

</div>

