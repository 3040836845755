<div class="book-demo-page page-content topic-details">

  <div class="content page-header">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1> {{ 'bookademo.Book a Demo' | translate }}</h1>
        </div>
        <div class="col-lg-8 col-12 m-auto">
          <p>  {{ 'bookademo.areinterested' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-10 col-12 m-auto">
        <div class="book-demo">
            <div class="row m-0" [hidden]="showscheduled">
              <div class="col-lg-4 col-12 p-0">
                 <app-book-items [showConfirmForm]="showConfirmForm"></app-book-items>
              </div>
              <div class="col-lg-8 col-12 p-0">
                  <div class="pick-date" [hidden]="showConfirmForm">
                    <h2> {{ 'bookademo.Pick a Date' | translate }}</h2>
                    <div class="pick-date-content">
                      <div class="calnder-content">
                        <div *ngIf='holidayLoading' class="dataLoading" style="height: 300px;">
                          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                        </div>
                        <ng-container *ngIf="!holidayLoading">
                          <div class="calnder-view" *ngIf="holidayList.length">
                            <mat-card class="demo-inline-calendar-card">
                              <mat-calendar [(selected)]="selected" (selectedChange)="GetTimeSlots($event)"  [headerComponent]="exampleHeader" [dateFilter]="holidayDateFilter"></mat-calendar>
                            </mat-card>
                            <p *ngIf="false"> {{ 'bookademo.Selected date' | translate }}: {{selected}}</p>
                          </div>
                        </ng-container>


                        <div class="zones-item">
                          <mat-form-field appearance="none" class="form-control" [class.loading-field]="isZoneloaded"  class="custom-loading">
                            <mat-label *ngIf="meetingInfo.gmt == null">  {{ 'bookademo.Select Time Zone' | translate }}</mat-label>
                              <mat-select  [(ngModel)]="meetingInfo.gmt"  [disabled]="isZoneloaded">
                                  <mat-option [value]="timeZone" *ngFor="let timeZone of timeZones">
                                      <span>{{timeZone.name}}</span>
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                          <p class="has-error" *ngIf="isRequired && meetingInfo.gmt == null" style=" margin-top: -21px; padding-left: 20px;">
                             {{ 'bookademo.please select time zone' | translate }}
                          </p>
                        </div>
                      </div>
                      <div class="time-splot-times" [hidden]="selected == null">

                        <div class="time-splots">
                          <div class="selected-date">
                            <span>
                              {{selected | date}}
                            </span>
                          </div>

                          <div *ngIf='isSlotsloaded' class="dataLoading">
                              <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                          </div>
                          <ng-container *ngIf='!isSlotsloaded' >
                            <ul class="splots" *ngIf="times.length">
                              <li *ngFor="let time of times; let i = index">
                                <div  [ngClass]="{'time-confirm': activeIndex == i}">
                                  <div class="time" (click)="(activeIndex == i) ? activeIndex = -1 : activeIndex = i">
                                   {{'0000-00-00T' + time.time  | date:'h:mm aa'}}
                                  </div>
                                  <div class="confirm" [hidden]="activeIndex != i" (click)="emitMeetingInfo(time);">
                                     {{ 'bookademo.Confirm' | translate }}
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div  *ngIf="!times.length">
                              <div class="data-empty">
                                 {{ 'bookademo.no time slots in the selected date' | translate }}
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="confirm-form" [hidden]="!showConfirmForm">
                    <div class="title">
                      <h2> {{ 'bookademo.Fill out this form to confirm the meeting' | translate }}</h2>
                      <span  (click)="showConfirmForm = false;"> {{ 'bookademo.Edit Date or Time' | translate }}</span>
                    </div>
                    <app-confirm-form (showscheduled)="showscheduled = $event"> </app-confirm-form>
                  </div>

              </div>

            </div>
            <div class="row">
              <div class="col-12" [hidden]="!showscheduled">
                <div class="scheduled-container">
                    <app-scheduled></app-scheduled>
                    <div class="back-to text-center">
                      <a class="primary-btn" mat-raised-button color="primary" (click)="backTohome()">
                        <span>{{ 'bookademo.OK, Back to Homepage' | translate }}</span>
                      </a>
                    </div>
                </div>

              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

</div>
