import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeetingInfo } from '../models/DemoRequest/MeetingInfo';
import { BehaviorSubject } from 'rxjs';
import { Config } from '../models/config';
@Injectable({
  providedIn: 'root'
})
export class BookademoService {

  meetingInfo = new BehaviorSubject<MeetingInfo>(null);
  meetingDuration = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  getBookingDataHeader(): any {
    return this.http.get(`${Config.bookdemoURL}/BookADemo/BookingDataHeader`);
  }

  GetTimeSlots(date): any{
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetTimeSlots?date=` + date);
  }

  SendDemoRequest(obj): any{
    return this.http.post(`${Config.bookdemoURL}/BookADemo/SendDemoRequest`, obj);
  }

  GetHolidaysDaysDates(){
    return this.http.get(`${Config.bookdemoURL}/BookADemo/GetHolidaysDaysDates`);
  }
}
