import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListArticles } from '../../../models/helpcenter/ListArticles';
import { GeneralService } from '../../../services/general.service';
declare let $: any;
@Component({
  selector: 'app-inside-category',
  templateUrl: './inside-category.component.html',
  styles: []
})
export class InsideCategoryComponent implements OnInit {


  searchText = '';
  topicId: number;
  isloaded = false;

  listArticles: ListArticles[] = new Array();
  currentRoute = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: GeneralService
    ) { }

  ngOnInit() {
    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus') ) {
      $('body').addClass('contactUs-page');

    } else {
      $('body').removeClass('contactUs-page');

    }
    this.activatedRoute.paramMap.subscribe(params => {
      this.topicId = Number(params.get('id'));
      this.getListArticles(this.topicId);
    });

    this.service.getRoute.subscribe(data => {
      if(data){
        this.currentRoute = data;
        // this.currentRoute = this.currentRoute.split('/').pop(); // 2020
        // console.log(this.currentRoute);
      }
    });
  }

  getSearch(text = null) {
    this.router.navigate(['/searchresult'], { queryParams: {searchText: text, Id: this.topicId}});
  }

  getListArticles(id){
    this.isloaded = true;
    this.service.getListArticles(id).subscribe(
      data => {
        this.isloaded = false;
        this.listArticles = data as ListArticles[];
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }



}
