import { weekDayTimes } from "./weekDayTimes";

export class ConfigObj{
  timeZoneLookupId: number;
  monthCode: number;
  from: any;
  to: any;
  meetingDuration: number;
  breakBetweenMeetings: number;
  weekDayTimes: weekDayTimes[] = new Array();
}
