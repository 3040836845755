import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
registerLocaleData(localeAr);

import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

import { DemoMaterialModule } from './helpers/material-modules';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
// import { AppDateAdapter, APP_DATE_FORMATS } from './helpers/format-datepicker';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderContentComponent } from './home-page/header-content/header-content.component';
import { InnovativeCompaniesComponent } from './home-page/innovative-companies/innovative-companies.component';
import { FeaturesComponent } from './home-page/features/features.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ClientsSayComponent } from './home-page/clients-say/clients-say.component';
import { PricingPageComponent } from './innerPages/pricing-page/pricing-page.component';

import { SharedModule } from 'src/app/partial/shared.module';

import { OwlModule } from 'ngx-owl-carousel';
import { TestimonialsComponent } from './home-page/clients-say/testimonials/testimonials.component';
import { HelpCenterComponent } from './innerPages/help-center/help-center.component';
import { InsideCategoryComponent } from './innerPages/help-center/inside-category/inside-category.component';
import { SearchResultComponent } from './innerPages/search-result/search-result.component';
import { TermandconditionComponent } from './innerPages/termandcondition/termandcondition.component';
import { ContactUsComponent } from './innerPages/contact-us/contact-us.component';
import { TopicDetailsComponent } from './innerPages/help-center/inside-category/topic-details/topic-details.component';
import { PrivacyComponent } from './innerPages/privacy/privacy.component';
import { DetailsComponent } from './home-page/features/details/details.component';
import { CompareplansComponent } from './innerPages/compareplans/compareplans.component';
import { DateAdapter, CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BookDemoComponent } from './innerPages/book-demo/book-demo.component';
import { BookItemsComponent } from './innerPages/book-demo/book-items/book-items.component';
import { ConfirmFormComponent } from './innerPages/book-demo/confirm-form/confirm-form.component';
import { ScheduledComponent } from './innerPages/book-demo/scheduled/scheduled.component';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { HeaderComponent } from './layout/basic-layout/header/header.component';
import { FooterComponent } from './layout/basic-layout/footer/footer.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { AdminHeaderComponent } from './layout/admin-layout/admin-header/admin-header.component';
import { AdminSidbarComponent } from './layout/admin-layout/admin-sidbar/admin-sidbar.component';
import { DemoSettingComponent } from './demo-setting/demo-setting.component';
import { CalnderViewComponent } from './demo-setting/calnder-view/calnder-view.component';
import { CalenderViewHeaderComponent } from './demo-setting/calnder-view/calender-view-header/calender-view-header.component';
import { ConfigTimeSlotsComponent } from './demo-setting/config-time-slots/config-time-slots.component';
import { SlotsConfigComponent } from './demo-setting/config-time-slots/slots-config/slots-config.component';
import { HolidaysComponent } from './demo-setting/holidays/holidays.component';
import { AddHolidayComponent } from './demo-setting/holidays/add-holiday/add-holiday.component';
import { MeetingDetailsComponent } from './demo-setting/meeting-details/meeting-details.component';
import { RescheduleComponent } from './demo-setting/meeting-details/reschedule/reschedule.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';

@NgModule({
  declarations: [
    AppComponent,
    BasicLayoutComponent,
    HeaderComponent,
    HeaderContentComponent,
    InnovativeCompaniesComponent,
    FeaturesComponent,
    HomePageComponent,
    ClientsSayComponent,
    FooterComponent,
    PricingPageComponent,
    TestimonialsComponent,
    HelpCenterComponent,
    InsideCategoryComponent,
    SearchResultComponent,
    TermandconditionComponent,
    ContactUsComponent,
    TopicDetailsComponent,
    PrivacyComponent,
    DetailsComponent,
    CompareplansComponent,
    BookDemoComponent,
    BookItemsComponent,
    ConfirmFormComponent,
    ScheduledComponent,
    AdminLayoutComponent,
    AdminHeaderComponent,
    AdminSidbarComponent,
    DemoSettingComponent,
    CalnderViewComponent,
    CalenderViewHeaderComponent,
    ConfigTimeSlotsComponent,
    SlotsConfigComponent,
    HolidaysComponent,
    AddHolidayComponent,
    MeetingDetailsComponent,
    RescheduleComponent,
    AuthComponent,
    LoginComponent,
    EmptyLayoutComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
  ],
  imports: [
    BrowserModule,
    OwlModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    OwlModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaterialTimepickerModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    // { provide: DateAdapter, useClass: AppDateAdapter },
    // { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || 'en' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TestimonialsComponent,
    RescheduleComponent,
    AddHolidayComponent
  ],
})
export class AppModule { }
