export class Detalis{
  id: number;
  videoPath: string;
  iconSvg: string;
  name: string;
  berif: string;
  content: string;
  showOrder: boolean;
  isActive: boolean;
  redirectUrl: string;
}
