import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input} from '@angular/core';
import {
  CalendarEvent,
  CalendarViewPeriod,
  CalendarMonthViewBeforeRenderEvent,
  CalendarWeekViewBeforeRenderEvent,
  CalendarDayViewBeforeRenderEvent,
  CalendarView,
} from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MeetingsService } from 'src/app/services/meetings.service';



@Component({
  selector: 'app-calnder-view',
  templateUrl: './calnder-view.component.html',
  styles: [
  ]
})
export class CalnderViewComponent implements OnInit {


  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  events: CalendarEvent[] = [
    {
      start: new Date(),
      title: 'An event',
    },
  ];

  activeDayIsOpen: boolean;

  period: CalendarViewPeriod;

  dataLoading = false;
  detailsLoading = false;

  meetings: any;
  demoRequests : any[] = new Array();

  detailsdemoRequestId: any;

  showDetails = false;

  currentType: number;

  constructor(private router: Router,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              private meetingsService: MeetingsService) { }

  ngOnInit() {
    this.meetingsService.tabClicked.subscribe(data => {
      if(data){
        this.currentType = data;
        this.getDemoRequestsWithDate(data);
      }
    });
  }

  beforeViewRender(
    event:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ) {
    this.period = event.period;
    this.cdr.detectChanges();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  getDemoRequestsWithDate(timingStatus) {
    this.meetings = [];
    this.demoRequests = [];
    this.dataLoading = true;

    this.meetingsService.getDemoRequestsWithDate(timingStatus).subscribe(
      result => {

        this.dataLoading = false;

        this.meetings = result;

        this.meetings.forEach(meeting => {
          meeting.demoRequests.map(request => {
            request.start = new Date(meeting.date);
            request.title = request.companyName;
          });

          this.demoRequests = this.demoRequests.concat(meeting.demoRequests);

        });

        this.events = this.demoRequests
      }, err => {
        console.error(err);
      });
  }

  eventClicked({ event }) {
    this.showDetails = true;
    this.detailsdemoRequestId = event.id;
  }

}
