import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styles: [
  ]
})
export class ForgetPasswordComponent implements OnInit {

  forgetPassForm: FormGroup;
  loading = false;
  confirmToggle = false;
  formSubmitted = false;

  errorMsg = null;

  language = localStorage.getItem('language') || 'en';
  isArabic = false;



  constructor(
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private formBuilder: FormBuilder
    ) {
      translate.addLangs(['en', 'ar']);
      translate.setDefaultLang('en');
      translate.use(this.language);

      if (this.language === 'en') { this.isArabic = false; }
     }

  ngOnInit() {
    this.buildForm();

  }

  buildForm(){
    this.forgetPassForm = this.formBuilder.group({
      userName: ['', [
        Validators.required,
      ]]
    });
  }


  forgetPassword() {
    this.formSubmitted = true;
    if (this.forgetPassForm.valid) {
      this.loading = true;
      this.authenticationService.forgetPassword(this.forgetPassForm.controls.userName.value).subscribe(
        data => {
           this.confirmToggle = true;
           this.loading = false;
        },
        error => {
          this.confirmToggle = false;
          this.loading = false;
          this.errorMsg = error.ErrorDTO[0];
        });
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.forgetPassForm.controls[controlName].hasError(errorName);
  }

  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
    return;
  }

}
