<div mat-dialog-title class="mat-modal__head">
  <div class="modal-header">
    <h5 class="modal-title" *ngIf="data && !data.isEditMood">
      {{ 'holiday.Add Holiday' | translate }}
    </h5>
    <h5 class="modal-title" *ngIf="data && data.isEditMood">
      {{ 'holiday.Edit Holiday' | translate }}
    </h5>
    <button type="button" class="close" mat-dialog-close aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-modal__content">
  <div class="modal-body add-holiday">
    <form [formGroup]="formGroup">
      <div class="fields-group">
        <mat-form-field appearance="standard"  class="form-group">
          <mat-label> {{ 'holiday.Holiday Name' | translate }}</mat-label>
          <input matInput formControlName="name" maxlength="250">
          <mat-error *ngIf="hasError(formGroup, 'name', 'required')"> {{ 'bookademo.Required' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group">

        <h3 class="group-title">{{ 'holiday.Consistency every year?' | translate }}</h3>
        <div *ngIf='consistenciesLoading' class="dataLoading">
          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        <ng-container *ngIf="!consistenciesLoading">
          <div class="form-group">
            <div class="radio-selector">
              <mat-radio-group formControlName="isConsistent" class="row">
                <mat-radio-button class="col-4" *ngFor="let holiday of holidaysConsistencies" [value]="holiday.id">
                  {{holiday.name}}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </ng-container>


      </div>
      <div class="fields-group">
        <h3 class="group-title">{{ 'holiday.Date' | translate }}</h3>
        <mat-slide-toggle formControlName="severalDays" class="form-group reverse-slide mb-4">
          <i>{{ 'holiday.Several days off (range)?' | translate }}</i>
        </mat-slide-toggle>
        <div class="row">
          <div class="col-6">
            <mat-form-field   appearance="standard" class="form-group">
              <mat-label>{{ 'holiday.Start Date' | translate }}</mat-label>
              <input matInput [min]='minDate' formControlName="startDate" [matDatepicker]="startDatePicker"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="startDatePicker">
              </mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="hasError(formGroup, 'startDate', 'required')">
                {{ 'bookademo.Required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field  appearance="standard"  class="form-group" [hidden]="!formGroup.controls.severalDays.value">
              <mat-label>{{ 'holiday.End date' | translate }}</mat-label>
              <input matInput  [min]='formGroup.controls.startDate.value' formControlName="endDate" [matDatepicker]="endDate" readonly
                [required]='formGroup.controls.severalDays.value'>
              <mat-datepicker-toggle matSuffix [for]="endDate">
              </mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
              <mat-error *ngIf="hasError(formGroup, 'endDate', 'required')">
                {{ 'bookademo.Required' | translate }}
              </mat-error>
              <mat-error *ngIf="hasError(formGroup, 'endDate', 'matDatepickerMin')">
                   {{ 'holiday.please enter End Date greater than Start Date ' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>


      </div>

      <div class="btn-container d-flex justify-content-end with-cancel">
        <button type="reset" class="close closebtn" aria-label="Close" (click)="cancel()">
            <span aria-hidden="true">{{ 'general.Cancel' | translate }}</span>
        </button>
        <button mat-raised-button color="primary" class="btn-lg w-100" [class.loading]="loading" [disabled]="loading"
          (click)="save()">
          <span *ngIf="data && !data.isEditMood">{{ 'holiday.Add Holiday' | translate }}</span>
          <span *ngIf="data && data.isEditMood">{{ 'holiday.Edit Holiday' | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
