<div class="page-content search-result">
  <div class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-12">
          <ul class="breadcrumb">
            <li>
              <a  [routerLink]="['/helpcenter']">{{ 'helpcenter.HelpCenter' | translate }}</a>
            </li>
            <li>
              <a class="active"> {{ 'searchResult.Search results' | translate }}</a>
            </li>
          </ul>
          <div class="search"  [class.loading-field]="searchloading">
            <input type="search" name="search" [placeholder]="'helpcenter.Searchtopics' | translate" [(ngModel)]='searchText' (keydown.enter)="getSearch(searchText)">
          </div>
          <div class="hint" *ngIf=" searchResult && searchResult.categoriesCount && searchResult.categoriesCount > 0">{{searchResult.categoriesCount}} results for
            <span *ngIf="searchText">"{{searchText}}"</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-result">
    <div class="container">
      <div class="custom-vertical-tab">
        <div class="dataLoading h-100 d-flex" *ngIf='searchloading'>
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        <ng-container *ngIf="!searchloading">
          <!-- {{searchResult | json}} -->
          <div class="no-data" *ngIf="searchResult && searchResult.counts && searchResult.counts.length == 0">
                <div class="image">
                  <img src="./assets/images/no-results-found.png">
                </div>
                <h2> {{ 'searchResult.Sorry' | translate }}</h2>
                <p> {{ 'searchResult.WeFind' | translate }} </p>
          </div>

          <div *ngIf="searchResult && searchResult.counts && searchResult.counts.length > 0">

            <div class="row">
              <div class="col-md-3 col-12">

                <div class="tabs-item" *ngIf="searchResult && searchResult.counts && searchResult.counts.length > 0">
                  <div class="title">
                    {{ 'searchResult.By Category' | translate }}
                  </div>
                  <div class="one-tab"
                      *ngFor="let count of searchResult.counts; let i = index" (click)="getDetails(searchText, count.topicId, true, null); activeIndex = i" [ngClass]="{'active': activeIndex == i}">
                    <div>
                      {{count.topicTitle}}
                      <span class="count"> ({{count.count}})</span>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-9 col-12">

                <div class="tab-content">
                  <div class="one-tab-content">
                    <div class="one-item" *ngFor="let item of searchResult.pageData">
                      <h3>{{item.articleTitle}}</h3>
                      <ul class="breadcrumb">
                        <li>
                          <a  [routerLink]="['/helpcenter']"> {{ 'helpcenter.HelpCenter' | translate }}</a>
                        </li>
                        <li *ngIf="item.topicTitle">
                          <a> {{item.topicTitle}} </a>
                        </li>
                        <li *ngIf="item.articleTitle">
                          <a> {{item.articleTitle}} </a>
                        </li>
                      </ul>
                      <p>
                       {{item.searchResult}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="pagination-container">
                  <div class="page-filter" *ngIf="pageCount > 1">
                    <span>{{ 'general.page' | translate }}</span><input type="text" (change)="updatePage($event)"
                      [value]="manualPage" />/{{ pageCount }}
                  </div>
                  <mat-paginator #paginator *ngIf="pageCount > 1" [length]="searchResult.totalCount"
                    [pageSize]="filterModel.pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="getDetails(searchText, topicId, true, $event)"
                    [pageIndex]="filterModel.pageNumber" showFirstLastButtons></mat-paginator>
                </div>
              </div>
            </div>
          </div>

        </ng-container>

      </div>
    </div>
  </div>
  <app-having-trouble></app-having-trouble>

</div>
