import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchResult } from '../../models/search/SearchResult';
import { SearchService } from '../../services/search.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { SearchData } from '../../models/search/SearchData';
import { ListDataFilter } from 'src/app/models/search/ListDataFilter';
import { GeneralService } from 'src/app/services/general.service';
declare let $: any;
@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styles: []
})
export class SearchResultComponent implements OnInit {

  pageIndex = 0;
  searchText = '';
  searchloading = false;
  searchResult: SearchResult = new SearchResult();

  activeIndex = 0;
  topicId = null;
  isClicked = false;

  filterModel: ListDataFilter = new ListDataFilter();

  pageCount = 0;
  manualPage = 0;

  constructor(
    private router: Router,
    private service: SearchService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private gService: GeneralService
  ) { }

  ngOnInit() {
    this.gService.isOpened.next(false);
    if (this.router.url.includes('contactus') ) {
      $('body').addClass('contactUs-page');

    } else {
      $('body').removeClass('contactUs-page');
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.searchText && !params.Id) {
        this.searchText = params.searchText;
        this.getSearch(this.searchText);
      }

      if (params.searchText && params.Id) {
        this.searchText = params.searchText;
        this.getDetails(this.searchText,  Number(params.Id), false, null);
      }
    });
  }


  getSearch(text = null) {
    this.searchloading = true;

    if (!text) {
      this.searchloading = false;
      this.searchResult = new SearchResult();
    } else {
      this.service.Search(text).subscribe(
        result => {
          this.searchloading = false;
          this.searchResult = result as SearchResult;
          this.searchResult.counts.unshift({
            count: this.searchResult.categoriesCount,
            topicId: null,
            topicTitle:  this.translate.instant('searchResult.Allcategories')
          });
        }, err => {
          this.searchloading = false;
          console.error(err);
          Swal.fire({
            title: this.translate.instant('general.Error'),
            text: this.translate.instant('general.tryAgain'),
            icon: 'error',
            confirmButtonText: this.translate.instant('general.ok')
          });
      });
    }

  }

  getDetails(text = null, topicId = null, isClicked, event = null) {
    this.topicId = topicId;
    this.searchText = text;
    this.isClicked = isClicked;

    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
      this.filterModel.pageSize = event.pageSize;
    } else {
      this.filterModel.pageNumber = 0;
      this.filterModel.pageSize = 10;
    }

    this.service.Search(text, topicId).subscribe(
      result => {
        this.searchResult.pageData = [];
        if(!isClicked){
          this.searchResult = result as SearchResult;
        }else{
          this.searchResult.pageData = result.pageData as SearchData[];
        }
        this.pageCount = Math.ceil(result.totalCount / result.pageSize);
        this.manualPage = Number(result.pageIndex) + 1;
      }, err => {
        console.error(err);
        Swal.fire({
          title: this.translate.instant('general.Error'),
          text: this.translate.instant('general.tryAgain'),
          icon: 'error',
          confirmButtonText: this.translate.instant('general.ok')
        });
      });
  }


  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getDetails(this.searchText,  this.topicId, this.isClicked, null);
    }
  }


}
