export class DemoRequestObj {
  fullName: string;
  email: string;
  countryKeyLookupId: any;
  phoneNumber: any;
  guestEmails: string;
  countryLookupId: any;
  companyName: string;
  companySizeLookupId: any;
  meetingDate: any;
  weekDayTimeId: any;
  timeZoneLookupId: number;
}
