import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { LookupsService } from 'src/app/services/lookups.service';
import { MeetingsService } from 'src/app/services/meetings.service';

@Component({
  selector: 'app-slots-config',
  templateUrl: './slots-config.component.html',
  styles: [
  ]
})
export class SlotsConfigComponent implements OnInit {



  // times = [
  //   '10:00',
  //   '11:00',
  //   '12:00',
  //   '01:00',
  //   '02:00',
  //   '03:00',
  //   '04:00',
  // ]

  // days = [
  //   {
  //     name: 'sun',
  //     allComplete: false,
  //     dayId: 0,
  //     times: [
  //       {
  //         timeId: 1,
  //         timeValue: '10:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 2,
  //         timeValue: '11:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 3,
  //         timeValue: '12:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 4,
  //         timeValue: '01:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 5,
  //         timeValue: '02:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 6,
  //         timeValue: '03:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 7,
  //         timeValue: '04:00',
  //         completed: false,
  //       }
  //     ]
  //   },
  //   {
  //     name: 'mon',
  //     allComplete: false,
  //     dayId: 1,
  //     times: [
  //       {
  //         timeId: 1,
  //         timeValue: '10:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 2,
  //         timeValue: '11:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 3,
  //         timeValue: '12:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 4,
  //         timeValue: '01:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 5,
  //         timeValue: '02:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 6,
  //         timeValue: '03:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 7,
  //         timeValue: '04:00',
  //         completed: false,
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Tue',
  //     allComplete: false,
  //     dayId: 2,
  //     times: [
  //       {
  //         timeId: 1,
  //         timeValue: '10:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 2,
  //         timeValue: '11:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 3,
  //         timeValue: '12:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 4,
  //         timeValue: '01:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 5,
  //         timeValue: '02:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 6,
  //         timeValue: '03:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 7,
  //         timeValue: '04:00',
  //         completed: false,
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Wed',
  //     allComplete: false,
  //     dayId: 3,
  //     times: [
  //       {
  //         timeId: 1,
  //         timeValue: '10:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 2,
  //         timeValue: '11:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 3,
  //         timeValue: '12:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 4,
  //         timeValue: '01:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 5,
  //         timeValue: '02:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 6,
  //         timeValue: '03:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 7,
  //         timeValue: '04:00',
  //         completed: false,
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Thu',
  //     allComplete: false,
  //     dayId: 4,
  //     times: [
  //       {
  //         timeId: 1,
  //         timeValue: '10:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 2,
  //         timeValue: '11:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 3,
  //         timeValue: '12:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 4,
  //         timeValue: '01:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 5,
  //         timeValue: '02:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 6,
  //         timeValue: '03:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 7,
  //         timeValue: '04:00',
  //         completed: false,
  //       }
  //     ]
  //   },
  //   {
  //     name: 'fri',
  //     allComplete: false,
  //     dayId: 5,
  //     times: [
  //       {
  //         timeId: 1,
  //         timeValue: '10:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 2,
  //         timeValue: '11:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 3,
  //         timeValue: '12:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 4,
  //         timeValue: '01:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 5,
  //         timeValue: '02:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 6,
  //         timeValue: '03:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 7,
  //         timeValue: '04:00',
  //         completed: false,
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Sat',
  //     allComplete: false,
  //     dayId: 6,
  //     times: [
  //       {
  //         timeId: 1,
  //         timeValue: '10:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 2,
  //         timeValue: '11:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 3,
  //         timeValue: '12:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 4,
  //         timeValue: '01:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 5,
  //         timeValue: '02:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 6,
  //         timeValue: '03:00',
  //         completed: false,
  //       },
  //       {
  //         timeId: 7,
  //         timeValue: '04:00',
  //         completed: false,
  //       }
  //     ]
  //   }
  // ]

  days = [];
  times = [];

  loadingdays = false;
  weekDays: Lookup[] = new Array();


  constructor(private lookupsService: LookupsService,
    private translate: TranslateService,
    private meetingsService: MeetingsService) { }

  ngOnInit(): void {
    this.meetingsService.timeSlots.subscribe(data => {
      this.times = [];
      if(data){
        this.times = data;
        this.GetWeekDays();
      }
    });
  }

  GetWeekDays(){
    this.loadingdays = true;
    this.weekDays = [];
    this.days = [];
    this.lookupsService.GetWeekDays().subscribe(
      data => {
        this.loadingdays = false;
        this.weekDays = data as Lookup[];
        this.weekDays.forEach((element, index) => {
          this.days.push({
            name: element.name,
            allComplete: false,
            id: element.id,
            times:  JSON.parse(JSON.stringify(this.times))
          });
        });
        this.meetingsService.weekDayTimes.next(this.days);

      }, err => {
        this.loadingdays = false;
        console.error(err);
      });
  }


  updateAllComplete(day) {
    day.allComplete = day.times != null && day.times.every(t => t.completed);
    this.meetingsService.weekDayTimes.next(this.days);
  }

  someComplete(day): boolean {
    if (day.times == null) {
      return false;
    }
    return day.times.filter(t => t.completed).length > 0 && !day.allComplete;
  }

  setAll(completed: boolean, day) {
    day.allComplete = completed;
    if (day.times == null) {
      return;
    }
    day.times.forEach(t => (t.completed = completed));
    this.meetingsService.weekDayTimes.next(this.days);
  }


}
