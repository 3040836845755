import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    public translate: TranslateService,
    // private appConfig: AppConfigService
  ) {
    const lang = localStorage.getItem('language') || 'en';
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    translate.use(lang);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  // async ngOnInit(): Promise<void> {
  //   await this.appConfig.load();
  // }
}
