<div class="page-content topic-details">


    <div>
      <div class="page-header">
        <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        <ng-container *ngIf="!isloaded">
          <app-breadcrumb></app-breadcrumb>
          <h1>{{ article.title }} </h1>
          <p> {{ article.berif }}</p>
        </ng-container>

      </div>


        <div class="content">
          <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div>
          <ng-container *ngIf="!isloaded">
            <div class="container">
              <div *ngIf="false">
                <div class="first-step">
                  <div class="first-content">
                    <div class="row justify-content-center">
                      <div class="col-lg-8 col-12">
                        <div class="step-title"> {{ 'TopicDetails.createraccount' | translate }} </div>
                        <p class="step-desc">
                          01.
                          {{ 'TopicDetails.goto' | translate }}
                          <a [href]="env.systemUrl" target="_blank">connect.com </a>
                          {{ 'TopicDetails.press' | translate }}
                          <a href="[pakageUrl]"  target="_blank">{{ 'general.StartFree' | translate }}</a>
                          {{ 'TopicDetails.rightcorner' | translate }}
                        </p>
                      </div>
                    </div>
                    <div class="row  h-100 justify-content-center">
                      <div class="col-lg-10 col-12">
                        <div class="img-container">
                          <div class="row  h-100 align-items-center justify-content-center">
                            <div class="col-lg-4 col-md-8 col-12">
                              <img src="./assets/images/Personal Information.png">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="second-content">
                    <div class="row justify-content-center">
                      <div class="col-lg-8 col-12">
                        <p>

                          {{ 'TopicDetails.freely' | translate }}
                        </p>
                        <p>
                          {{ 'TopicDetails.automatically' | translate }}
                        </p>
                        <div class="listing">
                          <div class="title">  {{ 'TopicDetails.article' | translate }}</div>
                          <ul class="included-listing">
                            <li class="one-item">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Point" transform="translate(-405 -766)">
                                  <rect id="rec" width="20" height="20" rx="5" transform="translate(405 766)" fill="#edf5f9" />
                                  <g id="check" transform="translate(410 772.5)">
                                    <path id="check-2" data-name="check"
                                      d="M14.749,7.117a.731.731,0,0,0-1.01,0l-5.3,5.109L6.21,10.075a.747.747,0,0,0-1.028.017.681.681,0,0,0,.018.99l2.732,2.63a.731.731,0,0,0,1.01,0l5.806-5.588a.668.668,0,0,0,0-1.007Z"
                                      transform="translate(-4.978 -6.914)" fill="#0f8ccf" />
                                  </g>
                                </g>
                              </svg>
                              <span>{{ 'general.LogIn' | translate }}</span>
                            </li>
                            <li class="one-item">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Point" transform="translate(-405 -766)">
                                  <rect id="rec" width="20" height="20" rx="5" transform="translate(405 766)" fill="#edf5f9" />
                                  <g id="check" transform="translate(410 772.5)">
                                    <path id="check-2" data-name="check"
                                      d="M14.749,7.117a.731.731,0,0,0-1.01,0l-5.3,5.109L6.21,10.075a.747.747,0,0,0-1.028.017.681.681,0,0,0,.018.99l2.732,2.63a.731.731,0,0,0,1.01,0l5.806-5.588a.668.668,0,0,0,0-1.007Z"
                                      transform="translate(-4.978 -6.914)" fill="#0f8ccf" />
                                  </g>
                                </g>
                              </svg>
                              <span>{{ 'TopicDetails.expiredaccount' | translate }}</span>
                            </li>
                            <li class="one-item">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Point" transform="translate(-405 -766)">
                                  <rect id="rec" width="20" height="20" rx="5" transform="translate(405 766)" fill="#edf5f9" />
                                  <g id="check" transform="translate(410 772.5)">
                                    <path id="check-2" data-name="check"
                                      d="M14.749,7.117a.731.731,0,0,0-1.01,0l-5.3,5.109L6.21,10.075a.747.747,0,0,0-1.028.017.681.681,0,0,0,.018.99l2.732,2.63a.731.731,0,0,0,1.01,0l5.806-5.588a.668.668,0,0,0,0-1.007Z"
                                      transform="translate(-4.978 -6.914)" fill="#0f8ccf" />
                                  </g>
                                </g>
                              </svg>
                              <span>{{ 'TopicDetails.Resetpassword' | translate }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="second-step">
                  <div class="first-step">
                    <div class="first-content">
                      <div class="row justify-content-center">
                        <div class="col-lg-8 col-12">
                          <p class="step-desc">
                            02.
                            {{ 'TopicDetails.goto' | translate }}
                            <a href="http://">
                              {{ 'TopicDetails.youremail' | translate }}
                            </a>
                            {{ 'TopicDetails.toverify' | translate }}
                          </p>
                        </div>
                      </div>
                      <div class="row h-100 justify-content-center">
                        <div class="col-lg-10 col-12">
                          <div class="img-container">
                            <div class="row  h-100 align-items-center justify-content-center">
                              <div class="col-lg-4 col-md-8 col-12">
                                <img src="./assets/images/A verification link sent.png">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="second-content">
                      <div class="row justify-content-center">
                        <div class="col-lg-8 col-12">
                          <p>
                            {{ 'TopicDetails.followingvideo' | translate }}
                          </p>
                          <div class="img-container h-auto mh-auto">
                            <img src="./assets/images/msgg.png">
                          </div>
                          <i>{{ 'TopicDetails.Connecttour' | translate }}</i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [innerHTML]="article.content | sanitizeHtml" *ngIf="article && article.content"></div>
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10 col-12">
                  <div class="feedback-container">
                    <h3>{{ 'TopicDetails.topicusefull' | translate }}</h3>
                    <div class="feedback">
                      <ul class="feedback">
                        <li class="angry" (click)="currentRate = 0" [class.active]='currentRate === 0'>
                          <div>
                            <svg class="eye left">
                              <use xlink:href="#eye" />
                            </svg>
                            <svg class="eye right">
                              <use xlink:href="#eye" />
                            </svg>
                            <svg class="mouth">
                              <use xlink:href="#mouth" />
                            </svg>
                          </div>
                        </li>
                        <li class="sad" (click)="currentRate = 1" [class.active]='currentRate === 1'>
                          <div>
                            <svg class="eye left">
                              <use xlink:href="#eye" />
                            </svg>
                            <svg class="eye right">
                              <use xlink:href="#eye" />
                            </svg>
                            <svg class="mouth">
                              <use xlink:href="#mouth" />
                            </svg>
                          </div>
                        </li>
                        <li class="ok" (click)="currentRate = 2" [class.active]='currentRate === 2'>
                          <div></div>
                        </li>
                        <li class="good" (click)="currentRate = 3" [class.active]='currentRate === 3'>
                          <div>
                            <svg class="eye left">
                              <use xlink:href="#eye" />
                            </svg>
                            <svg class="eye right">
                              <use xlink:href="#eye" />
                            </svg>
                            <svg class="mouth">
                              <use xlink:href="#mouth" />
                            </svg>
                          </div>
                        </li>
                        <li class="happy" (click)="currentRate = 4" [class.active]='currentRate === 4'>
                          <div>
                            <svg class="eye left">
                              <use xlink:href="#eye" />
                            </svg>
                            <svg class="eye right">
                              <use xlink:href="#eye" />
                            </svg>
                          </div>
                        </li>
                      </ul>
                      <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                          <path
                            d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1">
                          </path>
                        </symbol>
                        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                          <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                        </symbol>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

    </div>


</div>
