import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {
  }

  Search(searchText = null, TopicId = null, pageIndex = 0, PageSize = 2): any {
    let str = '';
    if (searchText != null) { str += '?searchText=' + searchText; }
    if (TopicId != null) { str += '&TopicId=' + TopicId; }
    if (pageIndex != null) { str += '&pageIndex=' + pageIndex; }
    if (PageSize != null) { str += '&PageSize=' + PageSize; }
    return this.http.get(`${environment.apiUrl}/HelpCenter/Search` + str);
  }
}
