<div class="header">
  <div class="app-sidebar__toggle">
    <a class="open-toggle" (click)="sidebarTrigger.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
        <g id="minimize-sidebar-icon" transform="translate(-151.298 -256.531)">
          <path id="Path_13106" data-name="Path 13106"
            d="M160.295,256.531a.911.911,0,1,0,0,1.822h10.072a.911.911,0,1,0,0-1.822Z" fill="#F87F0F" />
          <path id="Path_13107" data-name="Path 13107"
            d="M160.292,271.531h10.076a.907.907,0,1,0,0-1.813H160.295a.907.907,0,1,0,0,1.813Z" fill="#F87F0F" />
          <path id="Path_13108" data-name="Path 13108"
            d="M151.315,264.264c.057.1.078.148.1.194l.058.14,3.66,3.9a.928.928,0,0,0,.768.4h.008a.914.914,0,0,0,.506-.151l.045-.03a.91.91,0,0,0,.214-1.228l-2.736-2.546h16.426a.911.911,0,1,0,0-1.822H153.951l2.734-2.546a.9.9,0,0,0-.257-1.259.946.946,0,0,0-1.284.252l-3.7,3.955c-.055.135-.076.185-.091.236l-.048.118.007.079-.018.085Z"
            fill="#F87F0F" />
        </g>
      </svg>

    </a>
  </div>
  <div class="logo-mobile">
  </div>
  <div class="header-app-btns d-flex align-items-center justify-content-between">
    <a (click)="changeLanguage()" class="lang-btn">
      <img src="./assets/images/language-icon.svg" alt="">
      <span>{{ 'general.language' | translate }}</span>
    </a>
    <div class="header-part2 d-flex align-items-center ml-auto">
      <div class="notification-btn">
        <span>
          <span class="notification-count">13</span>
          <img class="swing desktop" src="./assets/images/notifications-icon.png">
          <img class="swing mop" src="./assets/images/notifications-icon.png">
        </span>
        <div class="notification-panel" [ngClass]="{active: notificationMenu}">
          <div class="notification-header">
            <h3>{{ 'header.Notification' | translate }}</h3>
          </div>
          <div *ngIf='notifLoading' class="dataLoading">
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div>
          <div [hidden]='notifLoading'>
            <div class="no-data text-center px-2 py-5" *ngIf="!notificationsList.length">
              {{ 'header.You have no notifications' | translate }}!
            </div>
            <div class="notification-list" *ngIf="notificationsList.length" infiniteScroll [scrollWindow]="false"
              [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount" (scrolled)="getNextPage()">
              <div *ngFor="let notif of notificationsList" [ngClass]="{new: !notif.isSeen}"
                (click)="notificationMenu = false;refreshAllDetails()">
                <ng-container [ngSwitch]="notif.notificationType">
                  <a *ngSwitchCase="1" [routerLink]="['/admin/braindeathcase/details', notif.objectId]">
                    <p class="title">{{notif.text}}</p>
                    <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- {{currentUser | json}} -->
      <div class="userAccount" id="userAccountDrop" (click)="userMenu = !userMenu; notificationMenu = false;">
        <div class="profile-image">
          <img *ngIf="currentUser.userImage" [src]="currentUser.userImage"
            (error)="currentUser.userImage = ''" />
          <span *ngIf="!currentUser.userImage">{{ initName(currentUser.FullName) | uppercase }}</span>
        </div>
        <div class="user-name">
          <span class="mr-2"> {{ 'header.Hello' | translate }}, <b>{{currentUser.FullName}}</b>
          </span>
          <svg *ngIf="!userMenu" width="8" height="5" viewBox="0 0 8 5">
            <path id="arrow-down" d="M-2.313-6.729.75-3.627l3.063-3.1.937.949-4,4.051-4-4.051Z"
              transform="translate(3.25 6.729)" fill="#999" />
          </svg>
          <svg *ngIf="userMenu" width="8" height="5" viewBox="0 0 8 5">
            <path id="arrow-down" d="M-2.313-1.729.75-4.83l3.063,3.1.937-.949-4-4.051-4,4.051Z"
              transform="translate(3.25 6.729)" fill="#999" />
          </svg>
        </div>
        <div class="custom-dropdown-menu" [ngClass]='{"active": userMenu}'>
          <ul>
            <li><a (click)="logout()">
                <svg width="17.001" height="17.006" viewBox="0 0 17.001 17.006">
                  <path id="logout-icon"
                    d="M6223.19,12103h-13.4a1.815,1.815,0,0,1-1.8-1.808v-13.383a1.815,1.815,0,0,1,1.811-1.808h13.4a1.817,1.817,0,0,1,1.792,1.808v4.146l-1.659-1.559v-2.74h-13.687v13.575h13.687v-2.625l1.659-1.461v4.165a1.766,1.766,0,0,1-.529,1.267,1.69,1.69,0,0,1-1.123.43C6223.3,12103.006,6223.243,12103,6223.19,12103Zm-4.514-5.039,2.691-2.688h-9.562v-1.54h9.562l-2.691-2.691,1.167-1.169,4.52,4.629-4.52,4.629Z"
                    transform="translate(-6208 -12086)" fill="#999" />
                </svg>
                {{ 'header.SignOut' | translate }}
              </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="popOverlay" *ngIf="userMenu || notificationMenu"></div>
