<!-- <link href="https://unpkg.com/bootstrap-css-only@4.4.1/css/bootstrap.min.css" rel="stylesheet"> -->
<link href="https://unpkg.com/@fortawesome/fontawesome-free@5.13.0/css/all.css" rel="stylesheet">
<link href="https://unpkg.com/angular-calendar@0.28.20/css/angular-calendar.css" rel="stylesheet">
<link href="https://unpkg.com/flatpickr@4.6.3/dist/flatpickr.css" rel="stylesheet">

<div *ngIf='dataLoading' class="dataLoading" style="height: 300px;">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>
<ng-container *ngIf="!dataLoading">
  <div class="calender-container">

    <app-calender-view-header [(view)]="view" [(viewDate)]="viewDate">
    </app-calender-view-header>
    <div class="box-calendar">
      <div class="alert alert-warning" *ngIf="period?.events.length === 0">
        {{ 'meetings.noupcomingmeetingsthis' | translate }}
        <!-- {{ view }} -->
        <span *ngIf="view == 'month'">
          {{ 'meetings.Month' | translate }}
        </span>
        <span *ngIf="view == 'week'">
          {{ 'meetings.Week' | translate }}
        </span>
        <span *ngIf="view == 'day'">
          {{ 'meetings.Day' | translate }}
        </span>
        <!-- {{ (taskId == -1 ?  'tasks.createTask' : 'tasks.saveTask' ) | translate}} -->
      </div>

      <div [ngSwitch]="view" class="calendar-table">
        <mwl-calendar-month-view
        *ngSwitchCase="'month'"
        [@.disabled]="true"
        [viewDate]="viewDate"
        [events]="events"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="eventClicked($event)"
        (beforeViewRender)="beforeViewRender($event)"></mwl-calendar-month-view>

        <mwl-calendar-week-view
        *ngSwitchCase="'week'"
        [viewDate]="viewDate"
        [events]="events"
        (eventClicked)="eventClicked($event)"
        (beforeViewRender)="beforeViewRender($event)"></mwl-calendar-week-view>

        <mwl-calendar-day-view
        *ngSwitchCase="'day'"
        [viewDate]="viewDate"
        [events]="events"
        (eventClicked)="eventClicked($event)"
        (beforeViewRender)="beforeViewRender($event)"></mwl-calendar-day-view>

      </div>
    </div>
  </div>

</ng-container>




<app-meeting-details
    *ngIf="showDetails"
    [detailsdemoRequestId]='detailsdemoRequestId'
    (closeDetails)='showDetails = false; this.getDemoRequestsWithDate(currentType)'></app-meeting-details>
