import { Component, OnInit } from '@angular/core';
import { Features } from 'src/app/models/Features/Features';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styles: []
})
export class FeaturesComponent implements OnInit {

  features: Features[] = new Array();
  isloaded = false;

  constructor(private service: GeneralService) { }

  ngOnInit() {
    this.getFeatures();
  }


  getFeatures(){
    this.isloaded = true;
    this.service.getFeatures().subscribe(
      data => {
        this.isloaded = false;
        this.features = data as Features[];
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

}
