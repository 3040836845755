import { Component, OnInit } from '@angular/core';
import { Packages } from 'src/app/models/package/Packages';
import { GeneralService } from 'src/app/services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterModel } from 'src/app/models/package/FilterModel';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styles: []
})
export class PricingComponent implements OnInit {

  packages: Packages = new Packages();
  isloaded = false;
  billingTypeId = 0;

  // table
  pageCount = 0;
  manualPage = 0;

  filterModel: FilterModel = new FilterModel();

  env = environment;

  constructor(
    private service: GeneralService,
    private translate: TranslateService) { }


  // is free true price = zero
  // is free false price = month or yearly with value -> get started
  // is free false price = month or yearly without value -> contactus
  ngOnInit() {
    this.filterModel.pageSize = 4;
    this.getPackages(null);
  }

  getPackages(index){
    this.isloaded = true;
    if (index) {
      this.filterModel.pageNumber = index;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.service.getPackages(this.filterModel).subscribe(
      data => {
        this.isloaded = false;
        this.packages = data as Packages;
        this.pageCount = Math.ceil(data.totalCount / data.pageSize);
        this.manualPage = Number(data.pageIndex) + 1;
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getPackages(null);
    }
  }
}
