
<div class="innovative-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title-container">
          <h2>
            <span> {{ 'header.Trusted' | translate }}</span>
            {{ 'header.nnovativecompanies' | translate }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
      </div>
      <ng-container *ngIf="!isloaded">
        <div class="col-lg-2 col-md-4 col-6" *ngFor="let item of trusedBy">
          <!-- [matTooltip]="item.title"  [alt]="item.title"  -->
          <a class="innovative-item clickable"  [href]="item.redirectUrl" target="_blank" *ngIf="item.redirectUrl.includes('http')">
            <img [src]="item.imagePath">
          </a>
          <!-- [matTooltip]="item.title" [alt]="item.title"  -->
          <a class="innovative-item notClicable" *ngIf="!item.redirectUrl.includes('http')">
            <img [src]="item.imagePath" >
          </a>
        </div>
      </ng-container>

    </div>
  </div>
</div>
