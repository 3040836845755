

<div class="compare-container">
  <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloadedCategories && isloadedPackages'>
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf="!isloadedCategories && !isloadedPackages">
    <div class="title-container" *ngIf="ComparePackages.result && ComparePackages.result.pageData && ComparePackages.result.pageData.length">
      <h2> {{ 'Pricing.Compare' | translate }}
        <span> {{ 'Pricing.Our Plans' | translate }}</span>
      </h2>
    </div>
    <div class="compare-plans">
      <div class="container">
          <div class="cutom-tabs">
            <mat-tab-group>
              <mat-tab [label]="'Pricing.MonthlyBilling' | translate"  (click)="billingTypeId = 0" *ngIf="ComparePackages.result && ComparePackages.result.pageData && ComparePackages.result.pageData.length">
                <div class="over-flow">
                  <div class="compare-content">
                    <div class="one-row">
                      <div class="row">
                        <div class="col-lg-4 col-md-6 col-6">
                          <div class="title">{{ 'Pricing.Plans' | translate }}</div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-6" *ngFor="let package of ComparePackages.result.pageData; index as i">
                          <div class="value" *ngIf="package.isFree">{{ 'Pricing.Free' | translate }}</div>
                          <div class="value" *ngIf="!package.isFree">{{package.name}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="one-row">
                      <div class="row">
                        <div class="col-lg-4 col-md-6 col-6">
                          <div class="title">{{ 'Pricing.Pricing' | translate }}</div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-6" *ngFor="let package of ComparePackages.result.pageData; index as i">
                          <div class="price">
                            <span class="value" *ngIf="(package.currency && package.currency.sympol) && (package.isFree || package.priceMonthly)">
                              {{package.currency.sympol}}
                            </span>
                            <span class="value" *ngIf="package.isFree">
                              0
                            </span>
                            <span class="value" *ngIf="!package.isFree && package.priceMonthly">
                                <span *ngIf="package.priceMonthly">{{package.priceMonthly}}</span>
                            </span>
                            <span class="currancy" *ngIf="package.isFree || package.priceMonthly">{{ 'Pricing./mo' | translate }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="collape-item" *ngFor="let item of categories; let i = index"
                    [class.activeItem]="ActiveIndex == i">
                    <div class="one-item" *ngIf="i == 0">
                      <div class="panel-title" (click)="(ActiveIndex == i) ? ActiveIndex = -1 : ActiveIndex = i">
                        <span>
                          {{item.name}}
                        </span>
                        <div class="icons">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" class="expand">
                            <path id="angle-down"
                              d="M16.5,10.517,12.417,14.59a.944.944,0,0,1-1.35,0L7.035,10.517a.967.967,0,0,1,0-1.364.944.944,0,0,1,1.341,0l3.366,3.4,3.366-3.4a.946.946,0,0,1,1.341,0,.967.967,0,0,1,.048,1.364Z"
                              transform="translate(-6.754 -8.874)" fill="#7a8393" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" class="collapse">
                            <path id="angle-up"
                              d="M16.5,13.231,12.417,9.158a.944.944,0,0,0-1.35,0L7.035,13.231a.967.967,0,0,0,0,1.364.944.944,0,0,0,1.341,0l3.366-3.4,3.366,3.4a.946.946,0,0,0,1.341,0,.967.967,0,0,0,.048-1.364Z"
                              transform="translate(-6.754 -8.874)" fill="#7a8393" />
                          </svg>
                        </div>

                      </div>
                      <div class="body" [hidden]="ActiveIndex != i">
                        <div class="no-data" *ngIf="item.features && item.features.length == 0">
                          <div class="image">
                            <img src="./assets/images/no-results-found.png">
                          </div>
                          <p> {{ 'searchResult.noData' | translate }} </p>
                        </div>
                        <div *ngIf="item.features.length">
                          <div class="one-row-item" *ngFor="let f of item.features">
                            <div class="row">
                              <div class="col-lg-4 col-md-6 col-6">
                                <div class="row-title">
                                  {{f.packageFeatureName}}
                                </div>
                              </div>
                              <div class="col-lg-2 col-md-4 col-6"
                                *ngFor="let v of ComparePackages.result.pageData; index as i">
                                <div class="row-value">
                                  <span *ngIf="f.value[i] != 'string' && f.value[i]">{{f.value[i]}}</span>
                                  <span *ngIf="f.value[i] != 'string' && !f.value[i]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.998" viewBox="0 0 12 11.998">
                                      <path id="times" d="M13.4,11.994l4.3-4.286A1,1,0,1,0,16.28,6.29l-4.286,4.3L7.708,6.29A1,1,0,0,0,6.29,7.708l4.3,4.286L6.29,16.28A1,1,0,1,0,7.708,17.7l4.286-4.3,4.286,4.3A1,1,0,1,0,17.7,16.28Z" transform="translate(-5.994 -5.996)" fill="#0f8ccf"/>
                                    </svg>

                                  </span>
                                  <span *ngIf="f.value[i] == 'string'">
                                    <svg xmlns="http://www.w3.org/2000/svg" id="check" width="14" height="10" viewBox="0 0 14 10">
                                      <path id="check-2" data-name="check"
                                        d="M18.657,7.2a1.009,1.009,0,0,0-1.415,0L9.821,14.5,6.7,11.43a1.031,1.031,0,0,0-1.439.024.987.987,0,0,0,.025,1.414l3.825,3.757a1.009,1.009,0,0,0,1.415,0l8.129-7.983a.966.966,0,0,0,0-1.438Z"
                                        transform="translate(-4.978 -6.914)" fill="#0f8ccf" />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab [label]="'Pricing.yearlyBilling' | translate"  (click)="billingTypeId = 1" *ngIf="ComparePackages.result && ComparePackages.result.pageData && ComparePackages.result.pageData.length">
                <div class="over-flow">
                  <div class="compare-content">
                    <div class="one-row">
                      <div class="row">
                        <div class="col-lg-4 col-md-6 col-6">
                          <div class="title">{{ 'Pricing.Plans' | translate }}</div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-6" *ngFor="let package of ComparePackages.result.pageData; index as i">
                          <div class="value" *ngIf="package.isFree">{{ 'Pricing.Free' | translate }}</div>
                          <div class="value" *ngIf="!package.isFree">{{package.name}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="one-row">
                      <div class="row">
                        <div class="col-lg-4 col-md-6 col-6">
                          <div class="title">{{ 'Pricing.Pricing' | translate }}</div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-6" *ngFor="let package of ComparePackages.result.pageData; index as i">
                          <div class="price">
                            <span class="value" *ngIf="(package.currency && package.currency.sympol) && (package.isFree || package.priceYearly)">
                              {{package.currency.sympol}}
                            </span>
                            <span class="value" *ngIf="package.isFree">
                              0
                            </span>
                            <span class="value" *ngIf="!package.isFree && package.priceYearly">
                                <span *ngIf="package.priceYearly">{{package.priceYearly}}</span>
                            </span>
                            <span class="currancy" *ngIf="package.isFree || package.priceYearly">{{ 'Pricing./yr' | translate }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="collape-item" *ngFor="let item of categories; let i = index"
                    [class.activeItem]="ActiveIndex == i">
                    <div class="one-item"  *ngIf="i == 0">
                      <div class="panel-title" (click)="(ActiveIndex == i) ? ActiveIndex = -1 : ActiveIndex = i">
                        <span>
                          {{item.name}}
                        </span>
                        <div class="icons">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" class="expand">
                            <path id="angle-down"
                              d="M16.5,10.517,12.417,14.59a.944.944,0,0,1-1.35,0L7.035,10.517a.967.967,0,0,1,0-1.364.944.944,0,0,1,1.341,0l3.366,3.4,3.366-3.4a.946.946,0,0,1,1.341,0,.967.967,0,0,1,.048,1.364Z"
                              transform="translate(-6.754 -8.874)" fill="#7a8393" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" class="collapse">
                            <path id="angle-up"
                              d="M16.5,13.231,12.417,9.158a.944.944,0,0,0-1.35,0L7.035,13.231a.967.967,0,0,0,0,1.364.944.944,0,0,0,1.341,0l3.366-3.4,3.366,3.4a.946.946,0,0,0,1.341,0,.967.967,0,0,0,.048-1.364Z"
                              transform="translate(-6.754 -8.874)" fill="#7a8393" />
                          </svg>
                        </div>

                      </div>
                      <div class="body" [hidden]="ActiveIndex != i">
                        <div class="no-data" *ngIf="item.features && item.features.length == 0">
                          <div class="image">
                            <img src="./assets/images/no-results-found.png">
                          </div>
                          <p> {{ 'searchResult.noData' | translate }} </p>
                        </div>
                        <div *ngIf="item.features.length">
                          <div class="one-row-item" *ngFor="let f of item.features">
                            <div class="row">
                              <div class="col-lg-4 col-md-6 col-6">
                                <div class="row-title">
                                  {{f.packageFeatureName}}
                                </div>
                              </div>
                              <div class="col-lg-2 col-md-4 col-6"
                                *ngFor="let v of ComparePackages.result.pageData; index as i">
                                <div class="row-value">
                                  <span *ngIf="f.value[i] != 'string'">{{f.value[i]}}</span>
                                  <span *ngIf="f.value[i] == 'string'">
                                    <svg xmlns="http://www.w3.org/2000/svg" id="check" width="14" height="10" viewBox="0 0 14 10">
                                      <path id="check-2" data-name="check"
                                        d="M18.657,7.2a1.009,1.009,0,0,0-1.415,0L9.821,14.5,6.7,11.43a1.031,1.031,0,0,0-1.439.024.987.987,0,0,0,.025,1.414l3.825,3.757a1.009,1.009,0,0,0,1.415,0l8.129-7.983a.966.966,0,0,0,0-1.438Z"
                                        transform="translate(-4.978 -6.914)" fill="#0f8ccf" />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
      </div>
    </div>
  </ng-container>
</div>
