<form [formGroup]="formGroup" (ngSubmit)='save()' #form="ngForm">
  <mat-form-field appearance="outline" class="form-group">
    <mat-label> {{ 'bookademo.Fullname' | translate }}</mat-label>
    <input matInput formControlName="fullName" maxlength="250">
    <mat-error *ngIf="hasError('fullName', 'required')"> {{ 'bookademo.Required' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-group">
    <mat-label> {{ 'bookademo.Email' | translate }}</mat-label>
    <input matInput formControlName="email" maxlength="50">
    <mat-error *ngIf="hasError('email', 'required')"> {{ 'bookademo.Required' | translate }}</mat-error>
    <mat-error *ngIf="hasError('email', 'pattern')">{{ 'bookademo.notvalid' | translate }}</mat-error>
  </mat-form-field>
  <div class="contactUs">
    <div class="countryKey">
      <mat-form-field appearance="none" class="form-group">
        <mat-select formControlName="countryKey" (selectionChange)="getPhoneExtentions($event.value);">
          <mat-select-trigger>
            <svg width="22" height="21.998" viewBox="0 0 22 21.998" [innerHTML]="selected.flagSVG | sanitizeHtml" *ngIf="selected && selected.flagSVG"></svg>
            <!-- {{ selected.countryName }} -->
          </mat-select-trigger>
          <mat-option [value]="key.id" *ngFor="let key of countryKeys">
            <svg width="22" height="21.998" viewBox="0 0 22 21.998" [innerHTML]="key.flagSVG | sanitizeHtml" *ngIf="key && key.flagSVG"></svg>
            <!-- <span *ngIf="key.countryName">{{key.countryName}}</span> -->
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('Country', 'required')">{{ 'bookademo.Required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="phoneExtension">
      <mat-form-field appearance="none" class="form-group">
        <mat-select formControlName="phoneExtension" disabled>
          <mat-option [value]="key.id" *ngFor="let key of countryKeys">
            <span>{{key.phoneExtension}}</span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('Country', 'required')">{{ 'bookademo.Required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="phoneNumber">
      <mat-form-field appearance="outline" class="form-group">
        <mat-label> {{ 'general.phoneNumber' | translate }}</mat-label>
        <!-- appOnlynumber -->
        <input matInput formControlName="phoneNumber"  minlength="5" maxlength="20" appOnlynumber>
        <mat-error *ngIf="hasError('phoneNumber', 'required')"> {{ 'bookademo.Required' | translate }}</mat-error>
        <!-- <mat-error *ngIf="hasError('phoneNumber', 'minlength')"> {{ 'general.vaildPhone' | translate }}</mat-error> -->
          <mat-error *ngIf="hasError('phoneNumber', 'pattern')">
              {{  'general.vaildPhone' | translate }}
          </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!-- {{this.hasError('guestEmails', 'notValid')}} -->
  <mat-form-field appearance="outline" class="form-group">
    <mat-label> {{ 'bookademo.Guest Email(s)' | translate }}</mat-label>
    <textarea matInput formControlName="guestEmails" (keyup)="validEmali()"></textarea>
    <!-- <mat-error *ngIf="hasError('guestEmails', 'required')">
        {{ 'bookademo.Required' | translate }}
      </mat-error> -->
    <!-- <mat-error *ngIf="formGroup.controls.guestEmails.invalid && formGroup.controls.guestEmails.value != ''">
        notvalid
      </mat-error> -->
    <mat-error *ngIf="hasError('guestEmails', 'notValid')"> {{ 'bookademo.notvalid' | translate }}</mat-error>
    <mat-error *ngIf="hasError('guestEmails', 'maxlength')"> {{ 'bookademo.maximum 5 guests' | translate }}</mat-error>

  </mat-form-field>

  <p class="hint-msg"> {{ 'bookademo.Optional & maximum 5 guests' | translate }}</p>
  <mat-form-field appearance="outline" class="form-group" [class.loading-field]="isCountriesloaded">
    <mat-label> {{ 'bookademo.Country' | translate }}</mat-label>
    <mat-select formControlName="Country" [disabled]="isCountriesloaded">
      <mat-option [value]="country.id" *ngFor="let country of countries">
        <span>{{country.name}}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="hasError('Country', 'required')">{{ 'bookademo.Required' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-group">
    <mat-label> {{ 'bookademo.Company Name' | translate }}</mat-label>
    <input matInput formControlName="CompanyName" maxlength="250">
    <mat-error *ngIf="hasError('CompanyName', 'required')"> {{ 'bookademo.Required' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-group" [class.loading-field]="isCompanySizesloaded">
    <mat-label> {{ 'bookademo.Company Size (No. of Employees)' | translate }}</mat-label>
    <mat-select formControlName="CompanySize" [disabled]="isCompanySizesloaded">
      <mat-option [value]="companySize.id" *ngFor="let companySize of companySizes">
        <span>{{companySize.numOfEmployees}}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="hasError('CompanySize', 'required')">{{ 'bookademo.Required' | translate }}</mat-error>
  </mat-form-field>
  <div class="btn-container">
    <button class="primary-btn w-100" mat-raised-button color="primary" [class.loading]='loading' [disabled]='loading'>
      {{ 'bookademo.Send Request' | translate }}</button>
  </div>
</form>
