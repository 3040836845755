import { Component, OnInit } from '@angular/core';
import { TabsData } from '../models/demoSetting/TabsData';
import { TranslateService } from '@ngx-translate/core';
import { ListData } from 'src/app/models/demoSetting/meetingList/ListData';
import { ListDataFilter } from 'src/app/models/demoSetting/meetingList/ListDataFilter';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { LookupsService } from 'src/app/services/lookups.service';
import { MeetingsService } from 'src/app/services/meetings.service';
import { RequestsTimingStatesEnum } from '../models/demoSetting/enum/RequestsTimingStatesEnum';
import { RequestsListStatusEnum } from '../models/demoSetting/enum/RequestsListStatusEnum';

@Component({
  selector: 'app-demo-setting',
  templateUrl: './demo-setting.component.html',
  styles: [
  ]
})
export class DemoSettingComponent implements OnInit {

  showCalenderView = true;
  dataLoading = false;

  budge: string;
  currentType = 1;
  currentCount: number
  activeTab = false;
  tabsData: TabsData[] = new Array();

  listLoading = false;
  showFilter = false;
  showSearch = false;

  countries: Lookup[] = [];

  sortItems = [
    { name: this.translate.instant('meetings.PendingConfirmation'), value: RequestsListStatusEnum.PendingConfirmation },
    { name: this.translate.instant('meetings.UpComing'), value: RequestsListStatusEnum.UpComing },
    { name: this.translate.instant('meetings.PastAndCompleted'), value: RequestsListStatusEnum.PastAndCompleted },
  ];

  // sortItems = [
  //   { name: 'Pending Confirmation', value: RequestsListStatusEnum.PendingConfirmation },
  //   { name: 'UpComing', value: RequestsListStatusEnum.UpComing },
  //   { name: 'Past / Completed', value: RequestsListStatusEnum.PastAndCompleted },
  // ];

  sortValue = -1;


  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: ListData = new ListData();

  filterModel: ListDataFilter = new ListDataFilter();

  showDetails = false;
  detailsdemoRequestId: number = null;

  constructor(
    private meetingsService: MeetingsService,
    private translate: TranslateService,
    private service: MeetingsService,
    private LookupsService: LookupsService) { }

  ngOnInit(): void {
    this.meetingsService.tabClicked.next(this.currentType);
    this.filterModel.timingStatus = this.currentType;
    this.filterModel.pageSize = 10;
    this.DemoRequestsTimingCounts();
    this.getCountries();
  }

  getSelectedMeetings(item) {
    if(item.status == RequestsTimingStatesEnum.All){
      this.currentType = RequestsTimingStatesEnum.All;
    }else if(item.status == RequestsTimingStatesEnum.New){
      this.currentType = RequestsTimingStatesEnum.New;
    }else if(item.status == RequestsTimingStatesEnum.UpComing){
      this.currentType = RequestsTimingStatesEnum.UpComing;
    }else{
      this.currentType = RequestsTimingStatesEnum.Past;
    }

    this.currentCount = item.count;
    this.filterModel.timingStatus = this.currentType;
    // calnder
    if(this.showCalenderView){
      this.meetingsService.tabClicked.next(this.currentType);
    }
    if(!this.showCalenderView){
      this.getAll(null);
    }
  }

  getData(){
    if(this.showCalenderView){
      this.meetingsService.tabClicked.next(this.currentType);
    }
    if(!this.showCalenderView){
      this.getAll(null);
    }
  }


  DemoRequestsTimingCounts() {
    this.dataLoading = true;
    this.meetingsService.DemoRequestsTimingCounts().subscribe(
      result => {
        this.dataLoading = false;
        this.tabsData = result;
        this.currentCount = this.tabsData[0].count;
      }, err => {
        this.dataLoading = false;
        console.error(err);
      });
  }


  getCountries() {
    this.LookupsService.getCountries().subscribe(
      data => {
        this.countries = data as [];
      }, err => {
        console.error(err);
      });
  }


  getAll(event) {
    this.listLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
      this.filterModel.pageSize = event.pageSize;
    } else {
      this.filterModel.pageNumber = 0;
      this.filterModel.pageSize = 10;
    }

    this.service.DemoRequestsPagedList(this.filterModel).subscribe(
      data => {
        this.listLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.listLoading = false;
      });
  }


  sortRecords(selected) {
    this.filterModel.sortingStatus = Number(selected.target.value);
    this.getAll(null);
  }


  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }

  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }

  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }



  makeFilter() {
    if(this.filterModel.filter.meetingDateFrom) { this.filterModel.filter.meetingDateFrom = new Date(`${this.filterModel.filter.meetingDateFrom} UTC`);}
    if(this.filterModel.filter.meetingDateTo) {this.filterModel.filter.meetingDateTo = new Date(`${this.filterModel.filter.meetingDateTo} UTC`)}
    if(this.filterModel.filter.requestDateFrom) {this.filterModel.filter.requestDateFrom = new Date(`${this.filterModel.filter.requestDateFrom} UTC`)}
    if(this.filterModel.filter.requestDateTo) {this.filterModel.filter.requestDateTo = new Date(`${this.filterModel.filter.requestDateTo} UTC`)}

    this.getAll(null);
  }

  ClearAll() {
    this.filterModel.filter = {} as {
      countryId: null,
      companyEmployeesCapacityFrom: null,
      companyEmployeesCapacityTo: null,
      meetingDateFrom: null,
      meetingDateTo: null,
      requestDateFrom: null,
      requestDateTo: null,
    };
    this.getAll(null);
  }


  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

}
