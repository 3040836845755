<div class="page-content terms-container">
  <div class="page-header">
    <h1> {{ 'TermsConditions.TermsConditions' | translate }}</h1>
  </div>
  <div class="terms-content">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="vertical-links">
            <div class="title">
              {{ 'TermsConditions.Tablecontent' | translate }}
            </div>
            <ul>
              <li>
                <a (click)="scroll(acknowledgments)" (click)="currentRate = 0" [class.active]='currentRate === 0'>  {{ 'TermsConditions.acknowledgments' | translate }}</a>
              </li>
              <li>
                <a (click)="scroll(account)"  (click)="currentRate = 1" [class.active]='currentRate === 1'> {{ 'TermsConditions.Youraccount' | translate }}</a>
              </li>
              <li>
                <a (click)="scroll(Freetrial)"  (click)="currentRate = 2" [class.active]='currentRate === 2'>{{ 'TermsConditions.Freetrial' | translate }}</a>
              </li>
              <li>
                <a (click)="scroll(services)"  (click)="currentRate = 3" [class.active]='currentRate === 3'> {{ 'TermsConditions.Permittedservices' | translate }}</a>
              </li>
              <li>
                <a (click)="scroll(Confidentiality)"  (click)="currentRate = 4" [class.active]='currentRate === 4'>{{ 'TermsConditions.protection' | translate }}</a>
              </li>
              <li>
                <a (click)="scroll(terms)"  (click)="currentRate = 5" [class.active]='currentRate === 5'>  {{ 'TermsConditions.Generalterms' | translate }}</a>
              </li>
            </ul>
          </div>

        </div>
        <div class="col-md-8 col-12">
          <ul class="describe">
            <li #acknowledgments>
              <h2> {{ 'TermsConditions.acknowledgments' | translate }}</h2>
              <p>
                {{ 'TermsConditions.Fromtime' | translate }}
              </p>
              <p>
                {{ 'TermsConditions.Weneed' | translate }}
              </p>
            </li>
            <li #account>
              <h2> {{ 'TermsConditions.Youraccount' | translate }}</h2>
              <p>
                {{ 'TermsConditions.orderto' | translate }}
              </p>
              <p>
                {{ 'TermsConditions.alwaysProvide' | translate }}
              </p>
              <p>
                {{ 'TermsConditions.disclose' | translate }}
              </p>
              <p>
                {{ 'TermsConditions.responsible' | translate }}
              </p>
            </li>
            <li #Freetrial>
              <h2>{{ 'TermsConditions.Freetrial' | translate }}</h2>
              <p> {{ 'TermsConditions.alreadypaid' | translate }}</p>
            </li>
            <li #services>
              <h2>{{ 'TermsConditions.Permittedservices' | translate }}</h2>
              <p>{{ 'TermsConditions.Access to Service' | translate }}</p>
            </li>
            <li #Confidentiality>
              <h2>{{ 'TermsConditions.protection' | translate }}</h2>
              <p>
                {{ 'TermsConditions.Mazedesign' | translate }}
              </p>
              <p>
                {{ 'TermsConditions.SupportMazedesign' | translate }}
              </p>
            </li>
            <li #terms>
              <h2>{{ 'TermsConditions.Generalterms' | translate }}</h2>
              <p>
                {{ 'TermsConditions.Transferringright' | translate }}
              </p>
              <p>
                {{ 'TermsConditions.Failure' | translate }}
              </p>
              <p>{{ 'TermsConditions.Norights' | translate }}</p>
              <p>
                {{ 'TermsConditions.Severability' | translate }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>
