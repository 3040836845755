import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-calender-view-header',
  templateUrl: './calender-view-header.component.html',
  styles: [
  ]
})
export class CalenderViewHeaderComponent implements OnInit {

  @Input() view: CalendarView;

  @Input() viewDate: Date;

  @Input() locale = localStorage.getItem('language') || 'en';

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;
  constructor() { }

  ngOnInit() {
  }

}
