import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUserValue;
        const language = localStorage.getItem('language') || 'en';
        if (currentUser && currentUser.access_token) {
            request = request.clone({
                setHeaders: {
                    'accept-language': language,
                    lang: language,
                    Authorization: `Bearer ${currentUser.access_token}`
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    'accept-language': language,
                    lang: language
                }
            });
        }
        this.dateReplacer(request.body);
        return next.handle(request);
    }
    dateReplacer(obj) {
        if (obj) {
            for (const k in obj) {
                if (obj.hasOwnProperty(k)) {
                    if (obj[k] instanceof Object) {
                        obj[k] = this.dateReplacer(obj[k]);
                    }
                    if (obj[k] instanceof Date) {
                        obj[k] = new Date(`${obj[k]} UTC`);
                    }
                }
            }
            return obj;
        }
    }
}
