import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidator {
  static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
    let crtValue = control.value;
    if (crtValue) { crtValue = crtValue.trim(); }
    if (!crtValue) { return { required: true }; }
    return null;
  }
}
