<div class="improve-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
          <h2>{{ 'helpcenter.trouble' | translate }}</h2>
          <h2>{{ 'helpcenter.lookingFor' | translate }} </h2>
          <ul  class="btns-group d-flex justify-content-center">
            <li class="primary-btn">
              <a [routerLink]="['/contactus']"> {{ 'general.ContactUs' | translate }}</a>
            </li>
          </ul>
      </div>
    </div>
  </div>
</div>
