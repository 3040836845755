import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styles: [
  ]
})
export class AdminLayoutComponent implements OnInit {
  toggleSide = false;

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  menuItems: any;

  mainRoute = {
    url : null,
    label: null
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root));
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string= '', breadcrumbs: any = []): [] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[AdminLayoutComponent.ROUTE_DATA_BREADCRUMB];
      let title = '';
      if (child.snapshot.data.title) {
        title = child.snapshot.data.title;
      }
      if (label) {
        if (!isNullOrUndefined(label)) {
          breadcrumbs.push({label, url, title});
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  saveMainRoute(ismain, item){
    if(ismain){
      this.mainRoute.url = item.url;
      this.mainRoute.label = item.label;
    }
  }

}
