

<div class="pricing-container">
  <div class="dataLoading h-100 d-flex align-items-center justify-content-center mt-5" *ngIf='isloaded'>
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf="!isloaded">
    <div  *ngIf="packages.pageData && packages.pageData.length">
      <div class="title-container">
        <h3> {{ 'Pricing.Pricing' | translate }}</h3>
        <h2>{{ 'Pricing.Pricingfit' | translate }}
          <span>{{ 'Pricing.needs' | translate }}</span>
        </h2>
        <p>{{ 'Pricing.Whether' | translate }}</p>
      </div>
      <div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="cutom-tabs">
                <mat-tab-group>
                  <mat-tab [label]="'Pricing.MonthlyBilling' | translate" (click)="billingTypeId = 0">
                    <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
                      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                    </div>
                    <ng-container *ngIf="!isloaded">
                      <div class="row" *ngIf="packages.pageData && packages.pageData.length">
                        <div class="col-lg-3 col-md-6 col-12" *ngFor="let package of packages.pageData">
                          <div class="price-item">
                            <h2 *ngIf="package.isFree">{{ 'Pricing.Free' | translate }}</h2>
                            <h2 *ngIf="!package.isFree">{{ package.name }}</h2>
                            <div class="text-content" *ngIf="package.brief">
                              <p>{{ (package.brief.length>102)? (package.brief | slice:0:102) : (package.brief) }}</p>
                            </div>
                            <div class="text-content" *ngIf="!package.brief">
                              <p style="visibility: hidden;" class="mb-0"> -- </p>
                            </div>
                            <div class="price">
                              <span class="value" *ngIf="package.isFree">
                                0
                              </span>
                              <span class="value" *ngIf="!package.isFree && package.priceMonthly">
                                {{package.priceMonthly}}
                              </span>
                              <span class="value"
                                *ngIf="(package.currency && package.currency.sympol) && (package.isFree || package.priceMonthly)">
                                {{package.currency.sympol}}
                              </span>
                              <a *ngIf="!package.isFree && !package.priceMonthly" [routerLink]="['/contactus']"
                                class="text">{{ 'Pricing.Customservices' | translate }}</a>
                              <span *ngIf="package.isFree || package.priceMonthly">{{ 'Pricing./mo' | translate }}</span>
                            </div>
                            <ul class="btns-group">
                              <li class="border-btn" *ngIf="!package.isBusiness">
                                  <a href="{{env.systemUrl}}/register?packageCode={{package.packageCode}}&packagepriceid={{package.monthlyPackagePriceId}}"
                                  target="_blank">{{ 'Pricing.GetStarted' | translate }} </a>

                              </li>
                              <li class="border-btn" *ngIf="package.isBusiness">
                                <a [routerLink]="['/contactus']">{{ 'general.ContactUs' | translate }} </a>
                              </li>
                            </ul>
                            <div class="listing" *ngIf="package.features && package.features.length">
                              <h3>{{ 'Pricing.Whatincluded' | translate }}</h3>
                              <ul>
                                <li *ngFor="let feature of package.features">
                                  <img src="./assets/images/icons/check.png">

                                  <span>{{ feature.packageFeatureName }}</span>
                                  <span> : {{ feature.value }}</span>
                                </li>
                              </ul>
                            </div>
                            <ul class="btns-group">
                              <li class="border-btn" *ngIf="!package.isBusiness">
                                  <a href="{{env.systemUrl}}/register?packageCode={{package.packageCode}}&packagepriceid={{package.monthlyPackagePriceId}}"
                                  target="_blank">{{ 'Pricing.GetStarted' | translate }} </a>
                              </li>
                              <li class="border-btn" *ngIf="package.isBusiness">
                                <a [routerLink]="['/contactus']">{{ 'general.ContactUs' | translate }} </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="packages.pageData && !packages.pageData.length">
                        <div class="col-12">
                          <div class="no-data">
                            <div class="image">
                              <img src="./assets/images/no-results-found.png">
                            </div>
                            <p> {{ 'searchResult.no found package' | translate }} </p>
                          </div>
                        </div>
                      </div>
                      <div class="pagination-container" hidden>
                        <div class="page-filter" *ngIf="pageCount > 1">
                          <span>{{ 'general.page' | translate }}</span><input type="text" (change)="updatePage($event)"
                            [value]="manualPage" />/{{ pageCount }}
                        </div>
                        <mat-paginator #paginator *ngIf="pageCount > 1" [length]="packages.totalCount"
                          [pageSize]="filterModel.pageSize" [pageSizeOptions]="[5, 10, 20]"
                          (page)="getPackages($event.pageIndex)" [pageIndex]="filterModel.pageNumber" showFirstLastButtons>
                        </mat-paginator>
                      </div>
                    </ng-container>

                  </mat-tab>
                  <mat-tab [label]="'Pricing.yearlyBilling' | translate" (click)="billingTypeId = 1">
                    <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
                      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                    </div>
                    <ng-container *ngIf="!isloaded">
                      <div class="row" *ngIf="packages.pageData && packages.pageData.length">
                        <div class="col-lg-3 col-md-6 col-12" *ngFor="let package of packages.pageData">
                          <div class="price-item">
                            <h2 *ngIf="package.isFree">{{ 'Pricing.Free' | translate }}</h2>
                            <h2 *ngIf="!package.isFree">{{ package.name }}</h2>
                            <div class="text-content" *ngIf="package.brief">
                              <p>{{ (package.brief.length>102)? (package.brief | slice:0:102) : (package.brief) }}</p>
                            </div>
                            <div class="text-content" *ngIf="!package.brief">
                              <p style="visibility: hidden;" class="mb-0"> -- </p>
                            </div>
                            <div class="price">
                              <span class="value" *ngIf="package.isFree">
                                0
                              </span>
                              <span class="value" *ngIf="!package.isFree && package.priceYearly">
                                {{package.priceYearly}}
                              </span>
                              <span class="value"
                                *ngIf="(package.currency && package.currency.sympol) && (package.isFree || package.priceYearly)">
                                {{package.currency.sympol}}
                              </span>
                              <a *ngIf="!package.isFree && !package.priceYearly" [routerLink]="['/contactus']"
                                class="text">{{ 'Pricing.Customservices' | translate }}</a>
                              <span *ngIf="package.isFree || package.priceYearly">{{ 'Pricing./yr' | translate }}</span>
                            </div>
                            <ul class="btns-group">
                              <li class="border-btn" *ngIf="!package.isBusiness">
                                  <a href="{{env.systemUrl}}/register?packageCode={{package.packageCode}}&packagepriceid={{package.yearlyPackagePriceId}}"
                                  target="_blank">{{ 'Pricing.GetStarted' | translate }} </a>
                              </li>
                              <li class="border-btn" *ngIf="package.isBusiness">
                                <a [routerLink]="['/contactus']">{{ 'general.ContactUs' | translate }} </a>
                              </li>
                            </ul>
                            <div class="listing" *ngIf="package.features && package.features.length">
                              <h3>{{ 'Pricing.Whatincluded' | translate }}</h3>
                              <ul>
                                <li *ngFor="let feature of package.features">
                                  <img src="./assets/images/icons/check.png">
                                  <span>{{ feature.packageFeatureName }}</span>
                                  <span> : {{ feature.value }}</span>
                                </li>
                              </ul>
                            </div>
                            <ul class="btns-group">
                              <li class="border-btn" *ngIf="!package.isBusiness">
                                  <a href="{{env.systemUrl}}/register?packageCode={{package.packageCode}}&packagepriceid={{package.yearlyPackagePriceId}}"
                                  target="_blank">{{ 'Pricing.GetStarted' | translate }} </a>
                              </li>
                              <li class="border-btn" *ngIf="package.isBusiness">
                                <a [routerLink]="['/contactus']">{{ 'general.ContactUs' | translate }} </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="packages.pageData && !packages.pageData.length">
                        <div class="col-12">
                          <div class="no-data">
                            <div class="image">
                              <img src="./assets/images/no-results-found.png">
                            </div>
                            <p> {{ 'searchResult.no found package' | translate }} </p>
                          </div>
                        </div>
                      </div>
                      <div class="pagination-container" hidden>
                        <div class="page-filter" *ngIf="pageCount > 1">
                          <span>{{ 'general.page' | translate }}</span><input type="text" (change)="updatePage($event)"
                            [value]="manualPage" />/{{ pageCount }}
                        </div>
                        <mat-paginator #paginator *ngIf="pageCount > 1" [length]="packages.totalCount"
                          [pageSize]="filterModel.pageSize" [pageSizeOptions]="[5, 10, 20]"
                          (page)="getPackages($event.pageIndex)" [pageIndex]="filterModel.pageNumber" showFirstLastButtons>
                        </mat-paginator>
                      </div>
                    </ng-container>
                  </mat-tab>
                </mat-tab-group>
                <!-- -->
                <ul class="btns-group compare-btn  d-flex align-items-center justify-content-center mt-4">
                  <li class="secondory-btn">
                    <a  [routerLink]="['/pricing', {pageSec: 'section2'}]">{{ 'Pricing.ComparePlans' | translate }} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


</div>


