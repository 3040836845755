<div class="clientSay-ccontainer">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-4 col-12">
        <div class="clientsay-content">
          <div class="tag">{{ 'clientsay.Testimonials' | translate }}</div>
          <h2>{{ 'clientsay.WhatOurClients' | translate }}</h2>
          <h3> {{ 'clientsay.AboutUs' | translate }}</h3>
          <p *ngIf="clientsCount != null">+ {{clientsCount}} {{ 'clientsay.dealingwithus' | translate }}</p>
        </div>
        <div class="custom-nav owl-nav">
        </div>
      </div>
      <div class="col-xl-8 col-12 h-100">
        <div class="dataLoading h-100 d-flex" *ngIf='isloaded'>
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        <ng-container *ngIf="!isloaded">
          <!-- w-100 -->
          <div class="carousel-container" *ngIf="clients && clients.length > 0">
            <owl-carousel [options]="SlideOptions" [carouselClasses]="['owl-theme', 'sliding']">
              <div class="item h-100" (click)="openDetails(client);activeIndex = i" *ngFor="let client of clients; let i = index" [ngClass]="{'isactive': activeIndex == i}" >
                <div class="image">
                  <img [src]='client.logoPath'>
                </div>
                <h2>{{client.name}}</h2>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                  <path id="quote"
                    d="M-8516.148-457a1.134,1.134,0,0,1-1.163-1.1v-5.617a21.514,21.514,0,0,1,.753-6.422,9.716,9.716,0,0,1,2.829-4.208,14.113,14.113,0,0,1,4.163-2.544,1.188,1.188,0,0,1,1.51.531l.946,1.866a1.067,1.067,0,0,1-.577,1.464,7.338,7.338,0,0,0-3.158,2.2,7.419,7.419,0,0,0-1.355,4.447h3.659a1.133,1.133,0,0,1,1.163,1.1v7.191a1.134,1.134,0,0,1-1.163,1.1Zm-13.688,0a1.134,1.134,0,0,1-1.164-1.1v-5.617a21.874,21.874,0,0,1,.773-6.441,9.531,9.531,0,0,1,2.808-4.189,13.448,13.448,0,0,1,4.181-2.562,1.188,1.188,0,0,1,1.512.531l.947,1.884a1.067,1.067,0,0,1-.575,1.464,7.366,7.366,0,0,0-3.161,2.2,7.416,7.416,0,0,0-1.354,4.447h3.639a1.135,1.135,0,0,1,1.165,1.1v7.191a1.135,1.135,0,0,1-1.165,1.1Z"
                    transform="translate(8531.001 477)" fill="#0f8ccf" />
                </svg>
                <p>  {{ (client.description.length>189)? (client.description | slice:0:189)+'..':(client.description) }}</p>
              </div>
            </owl-carousel>
          </div>
        </ng-container>


      </div>
    </div>
  </div>

</div>
