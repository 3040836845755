import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { LookupsService } from 'src/app/services/lookups.service';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { Holiday } from 'src/app/models/demoSetting/holiday/Holiday';
import { HolidaysService } from 'src/app/services/holidays.service';
export interface DialogData {
  id: number;
  isEditMood: boolean;
}


@Component({
  selector: 'app-add-holiday',
  templateUrl: './add-holiday.component.html',
  styles: [
  ]
})
export class AddHolidayComponent implements OnInit {

  loading = false;

  dataLoading = false;
  consistenciesLoading = false;
  holidaysConsistencies: Lookup[] = new Array();
  formGroup: FormGroup;

  today = new Date();
  minDate: Date;

  holidayObj: Holiday = new Holiday();

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddHolidayComponent>,
    private service: HolidaysService,
    private LookupsService: LookupsService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.GetHolidaysConsistencies();
    if (this.data.isEditMood && this.data.id != null) {
      this.GetHolidayDetailsById(this.data.id);
    }
  }

  GetHolidayDetailsById(id) {
    this.dataLoading = true;
    this.service.GetHolidayDetailsById(id).subscribe(
      data => {
        this.dataLoading = false;
        this.holidayObj = data as Holiday;
        this.bindData();
      }, err => {
        this.dataLoading = false;
        console.error(err);
      });
  }

  bindData() {
    this.formGroup.controls.name.setValue(this.holidayObj.name);
    this.formGroup.controls.isConsistent.setValue(this.holidayObj.holidaysConsistencyLookupId);
    this.formGroup.controls.severalDays.setValue(this.holidayObj.severalDaysOff);
    this.formGroup.controls.startDate.setValue(new Date(this.holidayObj.startDate));
    if(this.holidayObj.endDate && this.holidayObj.severalDaysOff){
      this.formGroup.controls.endDate.setValue(new Date(this.holidayObj.endDate));
    }
  }

  GetHolidaysConsistencies() {
    this.consistenciesLoading = true;
    this.LookupsService.GetHolidaysConsistencies().subscribe(
      data => {
        this.consistenciesLoading = false;
        this.holidaysConsistencies = data as [];
        if (this.holidaysConsistencies.length) {
          this.formGroup.controls.isConsistent.setValue(this.holidaysConsistencies[0].id);
        }
      }, err => {
        this.consistenciesLoading = false;
        console.error(err);
      });
  }

  // Form
  buildForm() {
    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      isConsistent: [null],
      severalDays: [false],
      startDate: [null, [Validators.required]],
      endDate: [null],
    });
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }


  save() {
    if (this.formGroup.valid) {
      this.loading = true;
      this.holidayObj.name = this.formGroup.controls.name.value;
      this.holidayObj.holidaysConsistencyLookupId = this.formGroup.controls.isConsistent.value;
      this.holidayObj.startDate = new Date(`${this.formGroup.controls.startDate.value} UTC`);
      this.holidayObj.severalDaysOff = this.formGroup.controls.severalDays.value;
      this.holidayObj.endDate = (this.formGroup.controls.endDate.value && this.formGroup.controls.severalDays.value) ? new Date(`${this.formGroup.controls.endDate.value} UTC`) : null;

      this.service.saveHoliday(this.holidayObj, this.data.isEditMood).subscribe(
        data => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('swalMsg.success'),
            text: (!this.data.isEditMood)? this.translate.instant('swalMsg.addedsuccessfully') : this.translate.instant('swalMsg.updatedsuccessfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          });
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('swalMsg.Failed'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          });
        });
    }
  }

  cancel(){
    this.buildForm();
    this.dialogRef.close(false);
  }

}
