<div class="page">
  <div class="page-filters">
    <span class="text grey">
      {{ 'holiday.Set holidays when scheduling meetings isnt possible' | translate }}
    </span>
    <div class="filters-container">
      <div class="create-item" *ngIf="!dataLoading">
        <a class="customBtn big-size" mat-raised-button color="primary" (click)="open(false, null)">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="add-member-icon" transform="translate(-630 -488)">
              <g id="circle" transform="translate(630 488)" fill="rgba(249,249,249,0)" stroke="#fff" stroke-width="1.5" stroke-dasharray="2 2" opacity="0.9">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.25" fill="none"/>
              </g>
              <path id="XMLID_468_" d="M186.354,206.75h-7.035a1.192,1.192,0,1,0,0,2.37h7.035a1.192,1.192,0,1,0,0-2.37Z" transform="translate(458.121 291.022)" fill="#fff" opacity="0.9"/>
              <path id="XMLID_468_2" data-name="XMLID_468_" d="M8.354,0H1.319A1.259,1.259,0,0,0,0,1.189,1.259,1.259,0,0,0,1.319,2.377H8.354A1.259,1.259,0,0,0,9.672,1.189,1.259,1.259,0,0,0,8.354,0Z" transform="translate(642.146 494.122) rotate(90)" fill="#fff" opacity="0.9"/>
            </g>
          </svg>

          <span>{{ 'holiday.Add Holiday' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <ng-container *ngIf="!dataLoading">
      <div *ngIf="recordsData.pageDate && recordsData.pageDate.length; else dataEmpty">
        <div class="table-responsive" *ngIf="recordsData.pageDate.length; else dataEmpty">
          <table class="table table-primary">
            <thead>
              <tr>
                <th width="45%">{{ 'holiday.Holiday Name' | translate }}</th>
                <th width="20%">{{ 'holiday.Consistency?' | translate }}</th>
                <th width="20%">{{ 'holiday.Date' | translate }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of recordsData.pageDate; index as i">
                <td>
                  <div class="name-with-image">
                    <div class="name">
                      <h6>{{record.name}}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="name-with-image">
                    <div class="name">
                      <h6>{{record.holidaysConsistency}}</h6>
                    </div>
                  </div>
                </td>
                <td>
                 {{record.startDate | date: 'fullDate'}}
                  <br>
                  {{record.endDate | date: 'fullDate'}}
                </td>
                <td>
                  <div class="btn-actions">
                    <a mat-icon-button [matTooltip]="'general.Delete' | translate" (click)="delete(record.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                        <path id="delete-icon"
                          d="M3.329,20a1.853,1.853,0,0,1-1.844-1.785L.976,7.394a.2.2,0,0,1,.192-.207H14.832a.194.194,0,0,1,.192.207l-.505,10.821A1.859,1.859,0,0,1,12.674,20ZM10.464,8.749v8.128a.615.615,0,1,0,1.231,0V8.749a.615.615,0,1,0-1.231,0Zm-3.079,0v8.128a.615.615,0,1,0,1.23,0V8.749a.615.615,0,1,0-1.23,0Zm-3.075,0v8.128a.615.615,0,1,0,1.23,0V8.749a.615.615,0,1,0-1.23,0ZM.618,5.939A.621.621,0,0,1,0,5.312V4.064A1.551,1.551,0,0,1,1.54,2.5H4.923V1.875A1.864,1.864,0,0,1,6.771,0H9.233a1.86,1.86,0,0,1,1.844,1.875V2.5H14.46A1.551,1.551,0,0,1,16,4.064V5.312a.617.617,0,0,1-.613.627ZM6.154,1.875V2.5H9.846V1.875a.616.616,0,0,0-.613-.621H6.771A.619.619,0,0,0,6.154,1.875Z"
                          fill="#616e7c" />
                      </svg>
                    </a>
                    <a mat-icon-button [matTooltip]="'general.Edit' | translate" (click)="open(true, record.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.004" viewBox="0 0 16 16.004">
                        <path id="edit-icon"
                          d="M5.82,14l-3.63-3.63,8.78-8.78,3.63,3.63ZM1.851,11.011l3.33,3.33L.188,16Zm13.865-6.9-.63.63L11.451,1.1l.63-.63a1.624,1.624,0,0,1,2.3,0l1.338,1.338a1.633,1.633,0,0,1,0,2.3Zm0,0"
                          transform="translate(-0.188 0.001)" fill="#616e7c" />
                      </svg>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-container mt-2">
          <mat-paginator #paginator *ngIf="pageCount > 1" [length]="recordsData.totalCount" [pageSize]="filterModel.pageSize"
            [pageSizeOptions]="[5, 10, 20]" (page)="getAll($event)" [pageIndex]="filterModel.pageNumber"
            showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </ng-container>
    <ng-template #dataEmpty>
      <div class="data-empty">
        <img src="./assets/images/no-results-found.png" />
        <p>{{ 'general.NoDataFound' | translate }}</p>
      </div>
    </ng-template>

  </div>

</div>
