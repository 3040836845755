import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { LookupsService } from 'src/app/services/lookups.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: [
  ]
})
export class ChangePasswordComponent implements OnInit {

  loading = false;
  code: string;
  userId: string;

  language = localStorage.getItem('language') || 'en';
  isArabic = false;

  msgObj = {msg: '', type: ''};
  resetPasswordForm: FormGroup;
  hidePassword = true;
  hideConfPassword = true;

  constructor(
    private lookupsService: LookupsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    public translate: TranslateService) {
      translate.addLangs(['en', 'ar']);
      translate.setDefaultLang('en');
      translate.use(this.language);

      if (this.language === 'en') { this.isArabic = false; }
     }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userid');
      this.code = params.get('code');
    });
    this.buildForm();
  }

  buildForm(){
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#&._-])[A-Za-z\d$@$!%*?#&].{7,}$')
      ]],
      cPassword: ['', Validators.required]
    }, {validator: this.validateMatch });
  }

  validateMatch(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('cPassword').value;
    if (pass !== confirmPass) {
      group.get('cPassword').setErrors({ notMatch: true });
    } else {
      group.get('cPassword').setErrors(null);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.resetPasswordForm.controls[controlName].hasError(errorName);
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      const newPassword = this.resetPasswordForm.controls.password.value;
      this.authenticationService.resetPassword(newPassword, this.userId, this.code).subscribe(
        data => {
          this.loading = false;
          this.msgObj.msg = this.translate.instant('auth.Password-updated-successfully');
          this.msgObj.type = 'success';
          Swal.fire({
            title: this.translate.instant('swalMsg.success'),
            text: this.translate.instant('auth.Password-updated-successfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          }).then((result) => {
            this.router.navigate(['/logout']);
          });
        },
        error => {
          this.loading = false;
          this.msgObj.type = 'danger';
          if (error.code === -1) {
            this.msgObj.msg = this.translate.instant('auth.OldPasswordIncorrect');
          } else {
            this.msgObj.msg = this.translate.instant('forms.Try-again');
          }
        });
    }
  }


  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
    return;
  }

}
