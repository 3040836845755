import { Component, OnInit, Input } from '@angular/core';
import { Social } from 'src/app/models/lookups/Social';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  isloaded = false;
  socialList: Social[] = new Array();

  currentYear = new Date().getFullYear();

  constructor(private service: GeneralService) { }

  ngOnInit() {
    this.GetSocialMedia();
  }


  GetSocialMedia() {
    this.isloaded = true;
    this.service.GetSocialMedia().subscribe(
      data => {
        this.isloaded = false;
        this.socialList = data as Social[];
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

}
