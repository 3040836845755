<aside class="sidebarMenu" [ngClass]="{'open-sidebar': toggleSide}">
  <div class="sidebar-content">
    <a [routerLink]="['/admin']" class="logo">
      <img class="desktop-logo" src="./assets/images/logo/logo-white.svg" alt="">
      <img class="mop-logo" src="./assets/images/logo/collapse-logo.svg" alt="">
    </a>
    <div class="userAccount m-0">
      <div class="profile-image">
        <img *ngIf="currentUser.userImage" [src]="currentUser.userImage"
          (error)="currentUser.userImage = ''" />
        <span *ngIf="!currentUser.userImage">{{ initName(currentUser.FullName) | uppercase }}</span>
      </div>
      <div class="user-name">
        <span class="mr-2">{{ 'header.Hello' | translate }}, <b>{{currentUser.FullName}}</b></span>
      </div>
    </div>
    <div class="menu-items">
      <ul class="nav-items">
        <li>
          <a (click)="activeMenu ? activeMenu = '' : activeMenu = 'admin';" [class.active]="activeMenu == 'admin' || isActiveMenu('admin')">
            <svg xmlns="http://www.w3.org/2000/svg" width="25.998" height="25.502" viewBox="0 0 25.998 25.502">
              <g id="demo-meetings-icon" transform="translate(-1626.5 -227)">
                <path id="Path_20342" data-name="Path 20342" d="M95.359,272.176a2.18,2.18,0,1,0-2.18,2.176A2.18,2.18,0,0,0,95.359,272.176Zm-2.18.725a.725.725,0,1,1,.727-.725A.727.727,0,0,1,93.18,272.9Z" transform="translate(1540.507 -29.93)" fill="#fff" opacity="0.8"/>
                <path id="Path_20343" data-name="Path 20343" d="M215.359,272.176a2.18,2.18,0,1,0-2.18,2.176A2.18,2.18,0,0,0,215.359,272.176Zm-2.18.725a.725.725,0,1,1,.727-.725A.727.727,0,0,1,213.18,272.9Z" transform="translate(1426.319 -29.93)" fill="#fff" opacity="0.8"/>
                <path id="Path_20344" data-name="Path 20344" d="M335.359,272.176a2.18,2.18,0,1,0-2.18,2.176A2.18,2.18,0,0,0,335.359,272.176Zm-2.18.725a.725.725,0,1,1,.727-.725A.727.727,0,0,1,333.18,272.9Z" transform="translate(1312.132 -29.93)" fill="#fff" opacity="0.8"/>
                <path id="Union_29" data-name="Union 29" d="M0,7.547V1.45H2.906V0H22.092V1.45H25v6.1ZM22.092,5.9h1.255V3.1H22.092ZM4.558,5.9H20.441V1.648H4.558Zm-2.906,0H2.906V3.1H1.651Z" transform="translate(1627 244.455)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0.5"/>
                <path id="Union_28" data-name="Union 28" d="M0,11.9V0H19.189V11.9ZM12.649,3.05A3.054,3.054,0,0,1,10.91,5.8h.138a3.782,3.782,0,0,1,3.782,3.775v.577h2.609V1.748H12.356A3.041,3.041,0,0,1,12.649,3.05ZM6.111,9.577v.577h6.968V9.577a2.032,2.032,0,0,0-2.031-2.028H10.47V9H8.719V7.549H8.141A2.032,2.032,0,0,0,6.111,9.577Zm-4.36.577H4.36V9.577A3.782,3.782,0,0,1,8.141,5.8H8.28A3.042,3.042,0,0,1,6.834,1.748H1.751ZM8.29,3.05a1.3,1.3,0,1,0,1.305-1.3A1.305,1.305,0,0,0,8.29,3.05Z" transform="translate(1629.904 227)" fill="#fff"/>
              </g>
            </svg>
            <span>
                 {{ 'menu.Manage Booking a Demo' | translate }}
              <small>  {{ 'menu.Requested through the website' | translate }}</small>
            </span>
            <mat-icon matSuffix class="ml-auto">expand_more</mat-icon>
          </a>
          <ul>
            <li>
              <a  [routerLink]="['/admin/meetings']" [routerLinkActive]="['active']">
                <span> {{ 'menu.All Meetings' | translate }}</span>
              </a>
              <a  [routerLink]="['/admin/configtimeslots']" [routerLinkActive]="['active']">
                <span>   {{ 'menu.Configure Time Slots' | translate }}</span>
              </a>
              <a  [routerLink]="['/admin/holidays']" [routerLinkActive]="['active']">
                <span>   {{ 'menu.Set Holidays' | translate }}
                  <small>  {{ 'menu.When Meetings are Unavailable' | translate }}</small>
                </span>

              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="btm-menuItems">
        <li class="signout-mopbile">

        </li>
        <li class="signout-mopbile">
          <a (click)="logout()">
            <svg width="17.001" height="17.006" viewBox="0 0 17.001 17.006">
              <path id="sign-out-icon"
                d="M15.19.008H1.8A1.769,1.769,0,0,0,.532.539,1.784,1.784,0,0,0,0,1.815V15.2a1.815,1.815,0,0,0,1.811,1.808h13.4A1.817,1.817,0,0,0,17,15.2V11.052L15.342,12.61v2.74H1.656V1.775H15.342V4.4L17,5.861V1.7A1.766,1.766,0,0,0,16.472.43,1.69,1.69,0,0,0,15.35,0C15.3,0,15.244,0,15.19.008ZM10.677,5.047l2.691,2.688H3.806v1.54h9.562l-2.691,2.691,1.167,1.169,4.52-4.629-4.52-4.629Z"
                transform="translate(17.001 17.006) rotate(180)" fill="#999" />
            </svg>
            <span>  {{ 'header.SignOut' | translate }}</span>
          </a>
        </li>
        <li>
          <span>
            {{ 'footer.copy-right' | translate }}

          </span>
        </li>
      </ul>
    </div>

  </div>
  <div class="close-icon" *ngIf="toggleSide" (click)="sidebarTrigger.emit()">
    <svg width="17" height="17" viewBox="0 0 17 17">
      <path id="close-menu-btn"
        d="M967.057,548.363l-6.847-6.849-6.847,6.849a.969.969,0,0,1-1.369-1.371l6.849-6.845-6.849-6.849a.967.967,0,0,1,1.369-1.366l6.847,6.847,6.847-6.847a.968.968,0,0,1,1.369,0,.957.957,0,0,1,.011,1.354l-.011.012-6.849,6.849,6.849,6.845a.968.968,0,0,1,0,1.369l0,0a.965.965,0,0,1-.678.286A.978.978,0,0,1,967.057,548.363Z"
        transform="translate(-951.712 -531.648)" fill="#f0f0f0" />
    </svg>
  </div>
</aside>
