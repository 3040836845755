<div class="features-container">
  <div class="title-container">
    <h3> {{ 'features.Features' | translate }}</h3>
    <h2>{{ 'features.Support' | translate }}
      <span>{{ 'features.teamwork' | translate }}</span>
    </h2>
    <p>{{ 'features.Donworry' | translate }}</p>
  </div>
  <div class="features-items">
    <div class="container" *ngIf="false">
      <div class="row justify-content-between">
        <div class="col-lg-5 col-12 ">
            <div class="tag">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
                <path id="messaging" d="M14.1,39.986H2.129A2.106,2.106,0,0,0,0,42.063v6.663A2.106,2.106,0,0,0,2.129,50.8H3.566l-.32,2.06a.7.7,0,0,0,.7.8c.251,0-.125.157,5.685-2.856H14.1a2.106,2.106,0,0,0,2.129-2.077V42.063A2.106,2.106,0,0,0,14.1,39.986Zm-9.98,6.62a1.212,1.212,0,1,1,1.242-1.211A1.228,1.228,0,0,1,4.125,46.606Zm3.992,0a1.212,1.212,0,1,1,1.242-1.211A1.228,1.228,0,0,1,8.117,46.606Zm3.992,0a1.212,1.212,0,1,1,1.242-1.211A1.228,1.228,0,0,1,12.109,46.606ZM22,46.389v6.663a2.1,2.1,0,0,1-2.129,2.077H18.434l.32,2.059a.7.7,0,0,1-.7.8c-.252,0,.114.151-5.685-2.856H7.9A2.152,2.152,0,0,1,6.5,54.621.683.683,0,0,1,6.607,53.5c3.212-1.841,3.04-1.785,3.307-1.785h4.618a2.471,2.471,0,0,0,2.5-2.44V45a.7.7,0,0,1,.71-.692h2.128A2.1,2.1,0,0,1,22,46.389Z" transform="translate(0 -39.986)" fill="#084d73"/>
              </svg>
              <span>{{ 'header.Messaging' | translate }}</span>
            </div>
            <div class="content">
              <h3>
                eConnect facilitates discussion via PCs, laptops, and smart phones.
              </h3>
              <p>
                According to statistics, WhatsApp is the most informal way for individuals and groups to communicate right now. So, we have made it possible to connect team members of the entities in a simple and user-friendly manner, similar to WhatsApp.
              </p>
            </div>
            <a  [routerLink]="['/messaging']" class="see-more"  >
              <span> {{ 'features.LearnMore' | translate }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11">
                <path id="angle-right" d="M15.548,11.512,10.8,7.065a1.175,1.175,0,0,0-1.591,0,1,1,0,0,0,0,1.479l3.967,3.714L9.205,15.971a1,1,0,0,0,0,1.479,1.193,1.193,0,0,0,1.591,0L15.548,13a1,1,0,0,0,0-1.49Z" transform="translate(-8.879 -6.754)" fill="#f87f0f"/>
              </svg>
            </a>
        </div>
        <div class="col-lg-6 col-12">
          <div class="img-container">
            <img src="./assets/images/video.png" alt="" srcset="">
          </div>
        </div>
      </div>
      <div class="row justify-content-between flex-row-reverse">
        <div class="col-lg-5 col-12 ">
            <div class="tag">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="18" viewBox="0 0 16.998 18">
                <path id="tasks" d="M14848.516,16860.814a4.242,4.242,0,1,1,4.225,4.184A4.211,4.211,0,0,1,14848.516,16860.814Zm2.887-.754a.528.528,0,0,0-.237.709l.567,1.123a.541.541,0,0,0,.415.289c.021,0,.042,0,.063,0a.549.549,0,0,0,.406-.189l1.418-1.689a.519.519,0,0,0-.067-.742.539.539,0,0,0-.749.068l-.9,1.068-.2-.406a.537.537,0,0,0-.478-.291A.521.521,0,0,0,14851.4,16860.061Zm-9.807,3.775a1.589,1.589,0,0,1-1.6-1.584v-13.67a1.591,1.591,0,0,1,1.6-1.584h6.853a2.692,2.692,0,0,1,1.9.787c0,.006,0,.006,0,.006l2.159,2.213a2.6,2.6,0,0,1,.762,1.844v3.756a4.354,4.354,0,0,0-.533-.025,5.268,5.268,0,0,0-5.287,5.236,5.189,5.189,0,0,0,.97,3.021Zm.677-5.588a.525.525,0,0,0,.529.523h2.84a.525.525,0,1,0,0-1.051h-2.84A.527.527,0,0,0,14842.272,16858.248Zm5.677-2.812a.531.531,0,0,0,.533.527h.566a.527.527,0,1,0,0-1.055h-.566A.533.533,0,0,0,14847.949,16855.436Zm-5.677,0a.527.527,0,0,0,.529.527h2.84a.527.527,0,1,0,0-1.055h-2.84A.53.53,0,0,0,14842.272,16855.436Zm5.677-2.812a.531.531,0,0,0,.533.527h.566a.528.528,0,1,0,0-1.057h-.566A.535.535,0,0,0,14847.949,16852.623Zm-5.677,0a.527.527,0,0,0,.529.527h2.84a.528.528,0,1,0,0-1.057h-2.84A.532.532,0,0,0,14842.272,16852.623Z" transform="translate(-14840 -16846.998)" fill="#084d73"/>
              </svg>
              <span> {{ 'header.Tasks' | translate }}</span>
            </div>
            <div class="content">
              <h3>
                Determining the size and quality of employees achievement in various team groups
              </h3>
              <p>
                Distribute tasks based on each staff member's abilities. A task management system is required for this. This allows knowledge of the volume of work and each employee's capability during work implementation, clarifying the size and quality of achievement.
              </p>
            </div>
            <a [routerLink]="['/tasks']" class="see-more"  >
              <span>  {{ 'features.LearnMore' | translate }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11">
                <path id="angle-right" d="M15.548,11.512,10.8,7.065a1.175,1.175,0,0,0-1.591,0,1,1,0,0,0,0,1.479l3.967,3.714L9.205,15.971a1,1,0,0,0,0,1.479,1.193,1.193,0,0,0,1.591,0L15.548,13a1,1,0,0,0,0-1.49Z" transform="translate(-8.879 -6.754)" fill="#f87f0f"/>
              </svg>
            </a>
        </div>
        <div class="col-lg-6 col-12">
          <div class="img-container">
            <img src="./assets/images/tasks.png" alt="" srcset="">
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-lg-5 col-12">
            <div class="tag">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.998" viewBox="0 0 18 17.998">
                <path id="meetings" d="M14852.165,16865v-1.09a2.109,2.109,0,1,1,4.219,0v1.09Zm-5.273,0v-1.09a2.109,2.109,0,1,1,4.218,0v1.09Zm-5.274,0v-1.09a2.11,2.11,0,0,1,4.22,0v1.09Zm15.625-2.145a3.159,3.159,0,0,0-1.333-1.643,2.611,2.611,0,0,0,1-2.053,5.9,5.9,0,0,1-7.909-2.633,2.637,2.637,0,0,1-5.273,0,2.64,2.64,0,0,0-2.637,2.633,2.605,2.605,0,0,0,1,2.053,3.167,3.167,0,0,0-1.335,1.643H14840l1.089-8.437h.526v-1.055a2.11,2.11,0,0,1,4.22,0v1.055h1.055v-1.055a2.109,2.109,0,1,1,4.218,0v1.055h1.056v-1.055a2.109,2.109,0,1,1,4.219,0v1.055h.526l1.092,8.438Zm-4.55-3.7a1.582,1.582,0,1,1,1.581,1.586A1.581,1.581,0,0,1,14852.692,16859.158Zm-5.274,0a1.582,1.582,0,1,1,1.583,1.586A1.584,1.584,0,0,1,14847.418,16859.158Zm-5.271,0a1.582,1.582,0,1,1,1.581,1.586A1.581,1.581,0,0,1,14842.146,16859.158Zm10.546-10.545a1.582,1.582,0,1,1,3.164,0,1.582,1.582,0,1,1-3.164,0Zm-5.274,0a1.582,1.582,0,1,1,3.164,0,1.582,1.582,0,1,1-3.164,0Zm-5.271,0a1.582,1.582,0,1,1,3.164,0,1.582,1.582,0,1,1-3.164,0Z" transform="translate(-14840.002 -16847)" fill="#084d73"/>
              </svg>
              <span> {{ 'features.Meetings' | translate }}</span>
            </div>
            <div class="content">
              <h3>
                Organizing meetings in advance by creating a meeting agenda
              </h3>
              <p>
                We're focusing on providing the ten tools recommended by successful leaders for meeting success in terms of planning meetings in advance by preparing the meeting agenda and allowing opinion              ns and discussion on each item on the meeting agenda at any time before the meeting begins.
              </p>
            </div>
            <a [routerLink]="['/meetings']" class="see-more" >
              <span>  {{ 'features.LearnMore' | translate }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11">
                <path id="angle-right" d="M15.548,11.512,10.8,7.065a1.175,1.175,0,0,0-1.591,0,1,1,0,0,0,0,1.479l3.967,3.714L9.205,15.971a1,1,0,0,0,0,1.479,1.193,1.193,0,0,0,1.591,0L15.548,13a1,1,0,0,0,0-1.49Z" transform="translate(-8.879 -6.754)" fill="#f87f0f"/>
              </svg>
            </a>
        </div>
        <div class="col-lg-6 col-12">
          <div class="img-container">
            <img src="./assets/images/meeting.png" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center">
      <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
      </div>
    </div>
    <ng-container *ngIf="!isloaded">
      <div class="container">
        <div class="row justify-content-between" *ngFor="let feature of features; let i = index"
          [ngClass]="{'flex-row-reverse': i == 1}">
          <div class="col-lg-5 col-12 ">
            <div class="tag">
              <span  [innerHTML]="feature.iconSvg | sanitizeHtml">
              </span>
              <span>{{feature.name}}</span>
            </div>
            <div  [innerHTML]="feature.berif | sanitizeHtml"></div>
            <a [routerLink]="[feature.redirectUrl]" [queryParams]="{ Id: feature.id}" class="see-more">
              <span> {{ 'features.LearnMore' | translate }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11">
                <path id="angle-right"
                  d="M15.548,11.512,10.8,7.065a1.175,1.175,0,0,0-1.591,0,1,1,0,0,0,0,1.479l3.967,3.714L9.205,15.971a1,1,0,0,0,0,1.479,1.193,1.193,0,0,0,1.591,0L15.548,13a1,1,0,0,0,0-1.49Z"
                  transform="translate(-8.879 -6.754)" fill="#f87f0f" />
              </svg>
            </a>
          </div>
          <div class="col-lg-6 col-12">
            <div class="img-container">
              <img [src]="feature.videoPath" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
