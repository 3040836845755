import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient) {
  }

  GetInquiryPurpose(): any {
    return this.http.get(`${environment.apiUrl}/Lookup/GetInquiryPurpose`);
  }
  save(obj): any {
    return this.http.post(`${environment.apiUrl}/Request/AddContactUs`, obj);
  }

  getContactUs(key): any{
    return this.http.get(`${environment.apiUrl}/SiteContent/GetByKey?key=` + key);
  }

}
