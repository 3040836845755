<div mat-dialog-title class="mat-modal__head">
  <div class="modal-header">
    <h5 class="modal-title">
      <span>
        {{ 'meetings.Reschedule' | translate }}
      </span>
    </h5>
    <button type="button" class="close" mat-dialog-close aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-modal__content reschedule-modal">
  <div class="modal-body">
    <div class="book-demo">
      <div class="pick-date">
        <div class="pick-date-content">
          <div class="calnder-content">
            <div *ngIf='holidayLoading' class="dataLoading" style="height: 300px;">
              <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>
            <ng-container *ngIf="!holidayLoading">
              <div class="calnder-view" *ngIf="holidayList.length">
                <mat-card class="demo-inline-calendar-card">
                  <mat-calendar [(selected)]="selected" (selectedChange)="GetTimeSlots($event)"
                    [headerComponent]="exampleHeader" [dateFilter]="holidayDateFilter"></mat-calendar>
                </mat-card>
                <p *ngIf="false"> {{ 'bookademo.Selected date' | translate }}: {{selected}}</p>
              </div>
            </ng-container>
            <div class="zones-item" hidden>
              <mat-form-field appearance="none" class="form-control" [class.loading-field]="isZoneloaded"  class="custom-loading">
                <mat-label *ngIf="gmt == null">  {{ 'bookademo.Select Time Zone' | translate }}</mat-label>
                  <mat-select  [(ngModel)]="gmt"  [disabled]="isZoneloaded"  required>
                      <mat-option [value]="timeZone" *ngFor="let timeZone of timeZones">
                          <span>{{timeZone.name}}</span>
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <div class="has-error" *ngIf="isRequired && gmt == null">
                 {{ 'bookademo.please select time zone' | translate }}
              </div>
            </div>
          </div>
          <div class="time-splot-times" [hidden]="selected == null">

            <div class="time-splots">
              <div class="selected-date">
                <span>
                  {{selected | date}}
                </span>
              </div>

              <div *ngIf='isSlotsloaded' class="dataLoading">
                  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
              </div>
              <ng-container *ngIf='!isSlotsloaded' >
                <ul class="splots" *ngIf="times.length">
                  <li *ngFor="let time of times; let i = index">
                    <div  [ngClass]="{'time-confirm': activeIndex == i}">
                      <div class="time" (click)="(activeIndex == i) ? activeIndex = -1 : activeIndex = i">
                       {{'0000-00-00T' + time.time  | date:'h:mm aa'}}
                      </div>
                      <div class="confirm" [hidden]="activeIndex != i" (click)="emitMeetingInfo(time);">
                         {{ 'bookademo.Confirm' | translate }}
                      </div>
                    </div>
                  </li>
                </ul>
                <div  *ngIf="!times.length">
                  <div class="data-empty">
                     {{ 'bookademo.no time slots in the selected date' | translate }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
