import { Component, OnInit, ViewChild } from '@angular/core';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { LookupsService } from 'src/app/services/lookups.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { MeetingsService } from 'src/app/services/meetings.service';
import { ConfigObj } from 'src/app/models/demoSetting/configuration/ConfigObj';


@Component({
  selector: 'app-config-time-slots',
  templateUrl: './config-time-slots.component.html',
  styles: [
  ]
})
export class ConfigTimeSlotsComponent implements OnInit {

  @ViewChild('form') private form: NgForm;

  language = localStorage.getItem('language') || 'en';

  isZoneloaded = false;
  timeZones: Lookup[] = new Array();
  ismonthsloaded = false;
  Months: Lookup[] = new Array();


  formGroup: FormGroup;
  isSubmitted = false;
  loading = false;

  today = new Date();
  time = this.today.getHours() + ':' + this.today.getMinutes();

  validWorkDay = false;
  workDay = null;

  customDurationMin: number;
  showCustomDuration = false;
  activeDuration = 1;
  currentDuration = 15;
  meetingDurations = [
    {
      id: 1,
      value: '15'
    },
    {
      id: 2,
      value: '30'
    },
    {
      id: 3,
      value: '45'
    },
    {
      id: 4,
      value: '60'
    }
    ,
    {
      id: 5,
      value: 'custom'
    }
  ];


  customBreakMin: number;
  showCustomBreak = false;
  activeBreak = 1;
  currentBreak = 1;
  MeetingBreak = [
    {
      id: 1,
      value: 'none'
    },
    {
      id: 2,
      value: '5'
    },
    {
      id: 3,
      value: '10'
    },
    {
      id: 4,
      value: '15'
    }
    ,
    {
      id: 5,
      value: 'custom'
    }
  ];

  validSlots = false;
  timeSlots = [];
  days = [];

  configObj: ConfigObj = new ConfigObj();

  isConfirm = false;

  constructor(private lookupsService: LookupsService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private meetingsService: MeetingsService) { }

  ngOnInit(): void {
    this.meetingsService.weekDayTimes.subscribe(data => {
      this.days = [];
      if (data) {
        this.days = data;
      }

    });

    this.GetTimeZones();
    this.GetMonths();
    this.buildForm();
  }


  GetMonths() {
    this.ismonthsloaded = true;
    this.lookupsService.GetMonths().subscribe(
      data => {
        this.ismonthsloaded = false;
        this.Months = data as Lookup[];
      }, err => {
        this.ismonthsloaded = false;
        console.error(err);
      });
  }

  GetTimeZones() {
    this.isZoneloaded = true;
    this.lookupsService.GetTimeZones().subscribe(
      data => {
        this.isZoneloaded = false;
        this.timeZones = data as Lookup[];
      }, err => {
        this.isZoneloaded = false;
        console.error(err);
      });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      timeZone: [null, [
        Validators.required,
      ]],
      months: [null, [
        Validators.required,
      ]],
      startTime: [null, Validators.required],
      endTime: [null, Validators.required],
    });
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  setMeetingDuration(meetingDuration = null, index = null, isCustom = false) {
    if (!isCustom) {
      if (index == 4) {
        this.showCustomDuration = !this.showCustomDuration;
      } else {
        this.showCustomDuration = false;
      }
      this.currentDuration = meetingDuration.id;
      const durValue = this.meetingDurations.find(item => item.id == this.currentDuration);
      this.currentDuration = Number(durValue.value);
    } else {
      this.currentDuration = this.customDurationMin;
    }

    this.calcTimeSlots();
  }

  setMeetingBreak(breakTime = null, index = null, isCustom = false) {
    if (!isCustom) {
      if (index == 4) {
        this.showCustomBreak = !this.showCustomBreak;
      } else {
        this.showCustomBreak = false;
      }
      this.currentBreak = breakTime.id;
      const BreakValue = this.MeetingBreak.find(item => item.id == this.currentBreak);
      if (BreakValue && BreakValue.id != 1) {
        this.currentBreak = Number(BreakValue.value);
      } else {
        this.currentBreak = 0;
      }
    } else {
      this.currentBreak = this.customBreakMin;
    }

    this.calcTimeSlots();
  }

  calculateWorkDayHours() {
    if (this.formGroup.controls.startTime.value != null && this.formGroup.controls.endTime.value != null) {
      var startTime = moment(this.formGroup.controls.startTime.value, 'HH:mm:ss a');
      var endTime = moment(this.formGroup.controls.endTime.value, 'HH:mm:ss a');

      const msToTime = (ms) => ({
        hours: Math.trunc(ms / 3600000),
        minutes: Math.trunc((ms / 3600000 - Math.trunc(ms / 3600000)) * 60) + ((ms / 3600000 - Math.trunc(ms / 3600000)) * 60 % 1 != 0 ? 1 : 0)
      })

      this.workDay = msToTime(Math.abs(Number(endTime) - Number(startTime)));

      this.calcTimeSlots();
    }
  }

  calcTimeSlots() {
    this.validWorkDay = false;
    let meetingTime = null;
    if (this.currentDuration != null && this.currentBreak != null && this.currentBreak != 1) {
      meetingTime = Number(this.currentDuration) + Number(this.currentBreak);
    } else {
      meetingTime = (this.currentDuration != null) ? this.currentDuration : this.currentBreak;
    }
    if (meetingTime != null && this.workDay != null && (this.workDay.hours > 0 || this.workDay.minutes > 0)) {
      this.validWorkDay = true;
      this.timeSlots = [];
      const workDayMins = (this.workDay.hours * 60) + this.workDay.minutes;
      const meetingCount = Math.floor(workDayMins / meetingTime);
      var startTime = moment(this.formGroup.controls.startTime.value, 'HH:mm:ss a');
      this.timeSlots.push({
        timeId: 0,
        timeValue: startTime.format('hh:mm A'),
        completed: false
      })
      for (let index = 1; index < meetingCount; index++) {
        let nextTime = startTime.add(meetingTime, 'minutes').format('hh:mm A');
        this.timeSlots.push({
          timeId: index,
          timeValue: nextTime,
          completed: false
        })
      }
      this.timeSlots.forEach(element => {
        // console.log(element.timeValue.substring(0, element.timeValue.indexOf(' ')));
        // console.log(element.timeValue.substring(element.timeValue.indexOf(' ') + 1));
        let str = element.timeValue.substring(element.timeValue.indexOf(' ') + 1);
        if (this.language !== 'en') {
          if(str == 'AM'){
            str = 'ص'
          }else{
            str = 'م'
          }
          element.timeValue = element.timeValue.substring(0, element.timeValue.indexOf(' ')) + " " + str;
        }
      });
      this.meetingsService.timeSlots.next(this.timeSlots);
    } else {
      this.validWorkDay = false;
    }
  }
  calcStTimeMin() {
    var startTime = moment(this.formGroup.controls.startTime.value, 'HH:mm:ss a');
    const msToTime = (ms) => ({
      hours: Math.trunc(ms / 3600000),
      minutes: Math.trunc((ms / 3600000 - Math.trunc(ms / 3600000)) * 60) + ((ms / 3600000 - Math.trunc(ms / 3600000)) * 60 % 1 != 0 ? 1 : 0)
    })

    let StTime = msToTime(Math.abs(Number(startTime)));
    return (StTime.hours * 60) + StTime.minutes;
  }

  save() {
    this.isSubmitted = true;
    this.validSlots = false;
    if (this.days.length) {
      let found;
      this.days.forEach(day => {
        found = day.times.find(t => (t.completed == true));
        if (found) {
          this.validSlots = true;
          return;
        }
      });
    }

    if (this.formGroup.valid && this.validSlots) {
      this.loading = true;
      this.configObj.weekDayTimes = [];
      this.days.forEach((day, index) => {
        // const found = day.times.find(item => item.completed);
        // if(found){}
        this.configObj.weekDayTimes.push({
          weekDayCode: day.id,
          allComplete: day.allComplete,
          insertDayTimeConfigs: []
        })
        day.times.forEach(time => {
          if (time.completed) {
            this.configObj.weekDayTimes[index].insertDayTimeConfigs.push({
              time: time.timeValue,
              completed: time.completed
            })
          }
        });
      });

      this.configObj.timeZoneLookupId = this.formGroup.controls.timeZone.value;
      this.configObj.monthCode = this.formGroup.controls.months.value;
      this.configObj.from = this.formGroup.controls.startTime.value;
      this.configObj.to = this.formGroup.controls.endTime.value;
      this.configObj.meetingDuration = Number(this.currentDuration);
      this.configObj.breakBetweenMeetings = Number(this.currentBreak);

      this.meetingsService.AddTimeSlotsConfig(this.configObj).subscribe(
        data => {
          if (data) {
            Swal.fire({
              title: this.translate.instant('swalMsg.success'),
              text: '',
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            }).then(() => {
              this.loading = false;
              this.isSubmitted = false;
              window.location.reload();
            });
          }
        },
        error => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('swalMsg.Failed'),
            text: this.translate.instant('swalMsg.Failed'),
            icon: 'error',
            confirmButtonText: this.translate.instant('swalMsg.ok'),
          });
        });
    }
  }

  confirmChange() {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.no')
    }).then((result) => {
      if (result.value) {
        this.isConfirm = true;
        //  this.meetingsService.confirmChange().subscribe(
        //    data => {
        //      if (data) {
        //        Swal.fire({
        //          title: this.translate.instant('swalMsg.success'),
        //          text: '',
        //          icon: 'success',
        //          confirmButtonText: this.translate.instant('swalMsg.ok')
        //        }).then(() => {
        //         window.location.reload();
        //        });
        //      }
        //    },
        //    error => {
        //      Swal.fire({
        //        title: this.translate.instant('swalMsg.Failed'),
        //        text: error.title,
        //        icon: 'error',
        //        confirmButtonText: this.translate.instant('swalMsg.ok'),
        //      });
        //  });
      } else {
        this.isConfirm = false;
      }
    });
  }

  resetForm() {
    this.form.resetForm();

    this.loading = false;
    this.isSubmitted = false;
    this.validWorkDay = false;
    this.validSlots = false;
    this.showCustomDuration = false;
    this.showCustomBreak = false;

    this.workDay = null;
    this.customDurationMin = null;
    this.customBreakMin = null;

    this.activeDuration = 1;
    this.currentDuration = 15;
    this.activeBreak = 1;
    this.currentBreak = 1;

    this.timeSlots = [];
    this.days = [];


  }

}
