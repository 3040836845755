<div class="login-Section row m-0">
  <div class="in-left Section-Form col-md-6 col-sm-12 p-md-0">
    <div class="container p-0 h-100">
      <div class="row flex-md-row-reverse h-100">
        <div class="col-xl-6 offset-xl-6 col-md-9 offset-md-3 p-md-0 col-md-9 offset-md-3 p-md-0">
          <div class="customPadding h-100 d-flex flex-column">
            <div class="logoImage">
              <img src="./assets/images/logo/logo.svg" alt="">
            </div>
            <div class="mt-auto mb-auto pt-4 pb-4">
              <div [hidden]="confirmToggle" class="Slogan-Text">
                <p class="welcomeSlogan">{{ 'login.welcome' | translate }}</p>
              </div>
              <div [hidden]="confirmToggle">
                <form [formGroup]="forgetPassForm" (ngSubmit)='forgetPassword()'>
                  <mat-form-field class="form-group" appearance="standard">
                    <mat-label>{{ 'fields.userName' | translate }}</mat-label>
                    <input matInput [placeholder]="'fields.userName' | translate"  formControlName="userName" maxlength="50" required>
                    <mat-error *ngIf="hasError('userName', 'required')">{{ 'general.Required' | translate }}</mat-error>
                  </mat-form-field>

                  <div class="form-group alert alert-danger" *ngIf="errorMsg && errorMsg.ErrorCode == -1">
                    {{ 'login.InCorrect' | translate }}
                  </div>
                  <div class="form-group alert alert-danger" *ngIf="errorMsg && errorMsg.ErrorCode == -2">
                    {{ 'general.tryAgain' | translate }}
                  </div>
                  <div class="formBtn">
                    <button type="submit" class="btn customBtn fullwidthBtn" [class.disabled]="!forgetPassForm.valid"
                      [ngClass]="{ 'loading': loading}">{{ 'general.Send' | translate }}</button>
                  </div>
                </form>
                <div class="Sign-sologan" style="margin-top: 40px;">
                  <span>{{ 'login.haveNotAccount' | translate }}</span>
                  <a [routerLink]="['/login']"> {{ 'general.signIn' | translate }} </a>
                </div>
              </div>
              <div [hidden]="!confirmToggle" class="confirmMsg verifySection">
                <div class="verify-title">
                  <div class="circle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11">
                      <path id="check-mark"
                        d="M150.632,169.284a1.33,1.33,0,0,1,0,1.786l-7.891,8.474a1.12,1.12,0,0,1-1.663,0l-3.756-4.034a1.329,1.329,0,0,1,0-1.786,1.12,1.12,0,0,1,1.663,0l2.925,3.141,7.059-7.581a1.12,1.12,0,0,1,1.663,0Z"
                        transform="translate(-136.976 -168.914)" fill="#fff" />
                    </svg>
                  </div>
                  <div class="title-text">
                    <span>{{ 'general.YouAreAlmost' | translate }}</span>
                    <p>
                      {{ 'general.helpYourPassword' | translate }}
                    </p>

                  </div>
                </div>
                <div class="formBtn">
                  <button [routerLink]="['/login']" class="btn customBtn fullwidthBtn"> {{ 'general.Done' | translate
                    }}</button>
                </div>
              </div>

            </div>
            <div class="copyRight">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                <path id="copyright-icon"
                  d="M-7.575-5.307a1.852,1.852,0,0,1,.2-.58,1.143,1.143,0,0,1,.366-.4,1.054,1.054,0,0,1,.6-.153.944.944,0,0,1,.427.092,1.222,1.222,0,0,1,.32.229,1.151,1.151,0,0,1,.229.336.972.972,0,0,1,.092.412h1.16a2.061,2.061,0,0,0-.168-.839,2,2,0,0,0-.458-.656,2.032,2.032,0,0,0-.7-.427,2.573,2.573,0,0,0-.9-.153,2.532,2.532,0,0,0-1.114.229,2.214,2.214,0,0,0-.778.61,2.614,2.614,0,0,0-.458.885,3.61,3.61,0,0,0-.153,1.053v.183a4.156,4.156,0,0,0,.137,1.068,2.16,2.16,0,0,0,.458.885,2.291,2.291,0,0,0,.793.58,2.686,2.686,0,0,0,1.114.214,2.6,2.6,0,0,0,.854-.137,2.167,2.167,0,0,0,.7-.4,1.831,1.831,0,0,0,.473-.61,2.089,2.089,0,0,0,.2-.748h-1.16a.813.813,0,0,1-.092.366.9.9,0,0,1-.244.305,1.081,1.081,0,0,1-.336.183,1.247,1.247,0,0,1-.4.061,1.145,1.145,0,0,1-.6-.137.989.989,0,0,1-.366-.4,2.052,2.052,0,0,1-.2-.58,3.5,3.5,0,0,1-.061-.656v-.183A3.262,3.262,0,0,1-7.575-5.307Zm1.251-5.768a6.461,6.461,0,0,0-4.608,1.892,6.461,6.461,0,0,0-1.892,4.608A6.461,6.461,0,0,0-10.932.034,6.461,6.461,0,0,0-6.324,1.926,6.461,6.461,0,0,0-1.716.034,6.461,6.461,0,0,0,.176-4.574,6.461,6.461,0,0,0-1.716-9.182,6.461,6.461,0,0,0-6.324-11.074Zm0,11.718A5.209,5.209,0,0,1-10-.9a5.209,5.209,0,0,1-1.541-3.677A5.209,5.209,0,0,1-10-8.251,5.209,5.209,0,0,1-6.324-9.793,5.209,5.209,0,0,1-2.647-8.251,5.209,5.209,0,0,1-1.106-4.574,5.209,5.209,0,0,1-2.647-.9,5.209,5.209,0,0,1-6.324.644Z"
                  transform="translate(12.824 11.074)" fill="#333" />
              </svg>
              <span>{{ 'general.copyRightYear' | translate }}</span>
              <span class="bold-font">{{ 'general.copyRight' | translate }}</span>
              <span>{{ 'general.allRight' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fadeInRight Section-Image col-6 d-none d-md-block p-md-0">
    <div class="row m-0">
      <div class="col-8">
        <div class="homeBtn-container">
          <a href="#" class="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.001" viewBox="0 0 13 13.001">
              <path id="home-icon"
                d="M12.651,5.654h0L7.346.35a1.2,1.2,0,0,0-1.693,0l-5.3,5.3-.005.005a1.2,1.2,0,0,0,.8,2.039l.037,0h.211v3.9a1.4,1.4,0,0,0,1.4,1.4H4.868a.381.381,0,0,0,.381-.381V9.56a.64.64,0,0,1,.639-.639H7.112a.64.64,0,0,1,.639.639v3.06A.381.381,0,0,0,8.132,13h2.075a1.4,1.4,0,0,0,1.4-1.4V7.7h.2a1.2,1.2,0,0,0,.847-2.043Zm0,0"
                transform="translate(0 0.001)" fill="#fff" opacity="0.6" />
            </svg>
            <span>{{ 'general.home' | translate }}</span>
          </a>
          <a (click)="changeLanguage()" class="btn lang-btn">

            {{ 'general.language' | translate }}
          </a>
        </div>

      </div>
    </div>
  </div>

</div>
