<div class="dashBoard main-wrapper">

  <app-admin-sidbar class="side-menu" [toggleSide]="toggleSide" (sidebarTrigger)="toggleSide = false"></app-admin-sidbar>

  <div class="main-container container-fluid p-0">
    <app-admin-header (sidebarTrigger)="toggleSide = true"></app-admin-header>

    <div class="container-fluid">
      <!-- Breadcrumb -->
      <div class="breadcrumb-container">

        <ul class="breadcrumb-items">
          <li *ngFor="let item of menuItems; index as i; first as isFirst; last as isLast">
            <div class="title" *ngIf="(i == 1)? saveMainRoute(true, item) : saveMainRoute(false, item)" style="visibility: hidden;"></div>
            <a [ngClass]='{"active": !isLast}' [routerLink]="item.url">
              <!-- {{item.label}} -->
              {{ 'routes.' + item.label | translate }} <span *ngIf="item.title">( {{item.title}} )</span>
            </a>
            <span class="icon-split" *ngIf='!isLast'>
              <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8">
                <path id="breadcrumb-arrow" d="M252.914,255.644l3.1,3.063-3.1,3.063.949.937,4.051-4-4.051-4Z" transform="translate(-252.914 -254.707)" fill="#aaa"/>
              </svg>
            </span>
          </li>
        </ul>
      </div>
      <!-- End Breadcrumb -->


      <div class="main-content">
        <div id="content">
          <router-outlet></router-outlet>
        </div>

      </div>
    </div>
  </div>
</div>
