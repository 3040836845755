import { Component, OnInit } from '@angular/core';
import { TrusedBy } from 'src/app/models/homepage/TrusedBy';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-innovative-companies',
  templateUrl: './innovative-companies.component.html',
  styles: []
})
export class InnovativeCompaniesComponent implements OnInit {


  trusedBy: TrusedBy[] = new Array();
  isloaded = false;

  constructor(private service: GeneralService) { }

  ngOnInit() {
    this.getTrustedBy​();
  }

  getTrustedBy​(){
    this.isloaded = true;
    this.service.getTrustedBy​().subscribe(
      data => {
        this.isloaded = false;
        this.trusedBy = data as TrusedBy[];
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

}
