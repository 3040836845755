import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Detalis } from 'src/app/models/Features/Detalis';
import { GeneralService } from 'src/app/services/general.service';

declare let $: any;
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styles: [
  ]
})
export class DetailsComponent implements OnInit {

  detalis: Detalis = new Detalis();
  isloaded = false;
  Id: number;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: GeneralService
    ) { }

  ngOnInit() {
    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus') ) {
      $("body").addClass("contactUs-page");

    }else{
      $("body").removeClass("contactUs-page");
    }

    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.Id = Number(params.get('id'));
        this.getDetails(this.Id);
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.Id) {
        this.getDetails(params.Id);
      }
    });
  }

  getDetails(id){
    this.isloaded = true;
    this.service.getDetails(id).subscribe(
      data => {
        this.isloaded = false;
        this.detalis = data as Detalis;
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

}
