import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {

  constructor(private http: HttpClient) {
  }

  HolidaysPagedList(filterObj) : any{
    return this.http.post(`${Config.bookdemoURL}/Holiday/HolidaysPagedList`, filterObj);
  }

  GetHolidayDetailsById(Id) : any{
    return this.http.get(`${Config.bookdemoURL}/Holiday/GetHolidayDetailsById?Id=` + Id);
  }


  saveHoliday(obj, isEdit = false) : any{
    if(isEdit){
      return this.http.post(`${Config.bookdemoURL}/Holiday/EditHoliday`, obj);
    }else{
      return this.http.post(`${Config.bookdemoURL}/Holiday/AddHoliday`, obj);
    }
  }
  delete(id): any{
    return this.http.put(`${Config.bookdemoURL}/Holiday/DeleteHoliday?Id=` + id, '');
  }
}
